import dayjs from "dayjs"
import { EmployeeCardModal } from "../../Objekte/types/types"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { ReactComponent as ARCHIVE } from "../../../assets/ArchiveICON.svg"
import { ReactComponent as UNARCHIVE } from "../../../assets/UnArchiveICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as CALENDAR } from "../../../assets/CalendarICON.svg"
import BasePopover from "../../../components/elements/BasePopover"

export default function EmployeeCard(props: EmployeeCardModal) {
    console.log(props.item)
    return (
        <div
            className={`bg-white cursor-pointer flex flex-col w-full max-h-64 h-fit rounded-default shadow-md p-4 relative border ${
                props.item.archived && "opacity-60 text-gray-400"
            }`}>
            <h3 className="font-semibold text-lg 2xl:text-xl">
                {props.item.first_name} {props.item.last_name}
            </h3>
            <div className="border-b w-full my-2" />
            <p className="text-sm">Geburtstag: {dayjs(props.item.geb).format("DD.MM.YYYY")}</p>
            <p className="text-sm">Position: {props.item.position}</p>
            {/* NOTE currently disabled bc no function in prod */}
            {/* <p className="text-sm">Objekte in Betreuung: {item.objects?.length ?? 0} </p> */}
            <p className="text-sm">
                Niederlassung:{" "}
                {Array.isArray(props.item.kostenstelle) &&
                    props.item.kostenstelle.map(
                        (item, index) => typeof item !== "string" && item.name + (index + 1 !== props.item.kostenstelle.length ? ", " : "")
                    )}
            </p>

            <div className="flex flex-row justify-end gap-2 grow items-center z-20">
                <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                    <p title="Kalender anzeigen" onClick={props.navigateToCalendar}>
                        <CALENDAR className="w-6 h-6 cursor-pointer" />
                    </p>
                </RenderWhenAuthorized>
                <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write, Roles.mitarbeiter_delete]}>
                    <p title="Archivieren">
                        <BasePopover label={props.item.archived ? "Archivieren Rückgängig machen?" : "Wirklich archivieren?"} okButtonClick={props.archiveFunc}>
                            {props.item.archived ? <UNARCHIVE className="w-6 h-6 cursor-pointer" /> : <ARCHIVE className="w-6 h-6 cursor-pointer" />}
                        </BasePopover>
                    </p>
                    <p title="Löschen">
                        <BasePopover label={"Wirklich Löschen?"} text="Der Mitarbeiter und alle zugehörigen Daten werden gelöscht." okButtonClick={props.deleteFunc}>
                            <DELETE className="w-6 h-6 cursor-pointer" />
                        </BasePopover>
                    </p>
                </RenderWhenAuthorized>
            </div>
            <div onClick={props.setEmployee} className="w-full h-full absolute top-0 left-0 cursor-pointer" />
        </div>
    )
}
