import { useQuery, useQueryClient } from "@tanstack/react-query"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import { useParams } from "react-router-dom"
import { getSingleSessionById } from "../api/apiCalls"
import { useContext, useEffect, useState } from "react"
import { CoordinateType, getSingleTaskType, sessionGetDataType } from "../types"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import dayjs from "dayjs"
import { ReactComponent as CHECK } from "../../../assets/ApprovaICON.svg"
import { ReactComponent as PROBLEM } from "../../../assets/EscalationRequiredICON.svg"
import { ReactComponent as KAMERA } from "../../../assets/Camera.svg"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import { ModalContext } from "../../../components/contexts/ModalContext"
import ScanQrCodeModal from "../components/ScanQrCodeModal"
import { useCheckInOrOutToSessionViaQrCodeMutation, useMarkTaskAsCompleteMutation, useTaskExecutionFailedMutation } from "../api/services"
import { HashLink } from "react-router-hash-link"
import { getLocationFromDevice, shutDownCamera } from "../../../services/functions"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import AddNoteModal from "../components/AddNoteModal"
import AddImagesModal from "../components/AddImagesModal"

export default function SessionDetailPage() {
    const { id } = useParams()
    // Session get single
    const queryClient = useQueryClient()
    const queryKeySingleSession = "Session" + id
    const {
        error: singleSessionError,
        isLoading: singleSessionIsLoading,
        data: singleSessionData,
    } = useQuery({
        queryKey: [queryKeySingleSession],
        queryFn: () => getSingleSessionById(id ?? ""),
        enabled: !!id,
    })
    const [displayedSession, setdisplayedSession] = useState<sessionGetDataType>()
    useEffect(() => setdisplayedSession(singleSessionData), [singleSessionData])

    const modalCon = useContext(ModalContext)
    const [selectedTask, setselectedTask] = useState<getSingleTaskType>()
    const [taskIsOnCompletion, settaskIsOnCompletion] = useState<boolean>()
    const handleTaskNote = (e: getSingleTaskType, complete: boolean) => {
        settaskIsOnCompletion(complete)
        setselectedTask(e)
        modalCon?.trigger(0)
    }

    // handleTask - COMPLETE / EXECUTION FAILED
    const { mutate: completeTask } = useMarkTaskAsCompleteMutation(queryClient, queryKeySingleSession)
    const { mutate: taskExecutionFailed } = useTaskExecutionFailedMutation(queryClient, queryKeySingleSession)
    const handleTask = (taskId: string, e?: { note: string; session: string }) => {
        console.log("Check")
        if (e && !taskIsOnCompletion) {
            taskExecutionFailed({
                id: taskId,
                note: e ?? { note: "", session: "" },
            })
            settaskIsOnCompletion(undefined)
        }
        if (taskIsOnCompletion) {
            completeTask({
                id: taskId,
                note: e ?? undefined,
            })
            settaskIsOnCompletion(undefined)
        }
    }

    // CHECK IN VIA QR CODE
    const { mutate: checkInOrOutViaQr } = useCheckInOrOutToSessionViaQrCodeMutation(queryClient, queryKeySingleSession, () => modalCon?.trigger(1))

    // Scan QR Code
    // NOTE maybe add a loader or smth
    const [startScanner, setstartScanner] = useState<boolean>(false)
    const [noPermissionMessage, setnoPermissionMessage] = useState<string>()
    const handleScan = async (data: any) => {
        if (data) {
            setstartScanner(false)
            shutDownCamera()
            try {
                checkInOrOutViaQr({
                    session: id ?? "",
                    qrcode: data.text,
                    indicator: displayedSession?.checkInStatus === "checked-in" ? "out" : "in",
                    coordinates: (await getLocationFromDevice()) as unknown as CoordinateType,
                })
            } catch (e) {
                console.log(e)
                setnoPermissionMessage(
                    "Es scheint als hätte die App nicht die Berechtigung, um den Standort abzufragen. Bitte erteilen Sie den Zugriff und versuchen Sie es erneut."
                )
            }
        }
    }

    return (
        <>
            {singleSessionError && <ErrorPage />}
            {singleSessionIsLoading && <Loading />}
            {displayedSession && (
                <BasePage>
                    <BaseHeadBanner title={displayedSession?.title} />
                    {displayedSession.status !== "completed" && (
                        <div
                            className={`mt-2 py-3 px-4 rounded-default uppercase font-semibold text-center text-xs sm:text-sm ${
                                displayedSession.checkInStatus === "checked-in" ? "bg-green-300 hover:none" : "bg-gray-400 hover:none border:none"
                            }`}>
                            {displayedSession.checkInStatus === "checked-in" ? "Termin aktiv" : "Nicht Aktiv"}
                        </div>
                    )}
                    <div className="text-left text-sm font-semibold my-2 flex flex-col bg-white rounded-default shadow-lg p-4 w-full">
                        <p className="">Am: {dayjs(displayedSession.startAt).format("DD.MM.YYYY")}</p>
                        <p className="">
                            Von: {dayjs(displayedSession.startAt).format("HH:mm")} Uhr - {dayjs(displayedSession.endAt).format("HH:mm")} Uhr
                        </p>
                        <p>Hinweise: {displayedSession.description ? displayedSession.description : "Keine"} </p>
                    </div>
                    {/* LOCATION AND TIME INFORMATION */}
                    <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                        <div className="text-left text-sm font-semibold mb-2 flex flex-col bg-white rounded-default shadow-lg p-4 w-full">
                            <div className="flex">
                                <p className="w-24 pr-4">Check In:</p>
                                <div>
                                    <p>
                                        {displayedSession.checkInAt
                                            ? dayjs(displayedSession.checkInAt).format("HH:mm") +
                                              " Uhr, am " +
                                              dayjs(displayedSession.checkInAt).format("DD.MM.YYYY")
                                            : "Noch nicht eingecheckt"}
                                    </p>
                                    {displayedSession.checkInAt && displayedSession.checkInWhere && (
                                        <>
                                            <p>
                                                {displayedSession.checkInWhere?.longitude.toString()}° Länge |{" "}
                                                {displayedSession.checkInWhere?.latitude.toString()}° Breite
                                            </p>
                                            <p>
                                                Gemessen mit einer Genauigkeit von {displayedSession.checkInWhere?.accuracy.toFixed(1).toString()}{" "}
                                                Metern
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex py-1">
                                <p className="w-24 pr-4">Check Out:</p>
                                <div>
                                    <p>
                                        {displayedSession.checkOutAt
                                            ? dayjs(displayedSession.checkOutAt).format("HH:mm") +
                                              " Uhr, am " +
                                              dayjs(displayedSession.checkOutAt).format("DD.MM.YYYY")
                                            : "Noch nicht ausgecheckt"}
                                    </p>
                                    {displayedSession.checkOutAt && displayedSession.checkOutWhere && (
                                        <>
                                            <p>
                                                {displayedSession.checkOutWhere?.longitude.toString()}° Länge |{" "}
                                                {displayedSession.checkOutWhere?.latitude.toString()}° Breite
                                            </p>
                                            <p>
                                                Gemessen mit einer Genauigkeit von {displayedSession.checkOutWhere?.accuracy.toFixed(1).toString()}{" "}
                                                Metern
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </RenderWhenAuthorized>

                    <BaseGrowBody classProps="flex flex-col pb-16">
                        <div className="overflow-y-scroll">
                            {displayedSession.failedTasks?.map((item: any) => (
                                <TaskElementInSession
                                    taskExecutionFailed
                                    task={item}
                                    openImageModal={() => {}}
                                    linkToTask={"/aufgaben/" + item._id}
                                    checkInStatus={displayedSession.checkInStatus === "checked-in"}
                                    setTask={handleTaskNote}
                                />
                            ))}
                            {displayedSession.tasks.map((item: any) => (
                                <TaskElementInSession
                                    task={item}
                                    linkToTask={"/aufgaben/" + item._id}
                                    checkInStatus={displayedSession.checkInStatus === "checked-in"}
                                    setTask={handleTaskNote}
                                    openImageModal={() => {
                                        setselectedTask(item)
                                        modalCon?.trigger(2)
                                    }}
                                />
                            ))}
                        </div>
                    </BaseGrowBody>
                    {/* // Check In Or Out Button */}
                    <div className="absolute bottom-0 h-fit w-full left-0 px-4 pb-2">
                        {(!displayedSession.checkInAt || !displayedSession.checkOutAt) && displayedSession.status !== "completed" && (
                            <button
                                disabled={
                                    displayedSession.checkInStatus === "checked-in" &&
                                    (displayedSession.tasks.length === 0
                                        ? false
                                        : !!(displayedSession?.tasks as getSingleTaskType[]).find(
                                              (item: getSingleTaskType) => item.state !== "completed"
                                          ))
                                }
                                onClick={() => {
                                    setstartScanner(true)
                                    modalCon?.trigger(1)
                                }}
                                className="btn btn-primary w-full h-16 left-0 rounded-default">
                                {displayedSession.checkInStatus === "checked-in" &&
                                (displayedSession.tasks.length === 0
                                    ? false
                                    : !!(displayedSession?.tasks as getSingleTaskType[]).find(
                                          (item: getSingleTaskType) => item.state !== "completed"
                                      ))
                                    ? "Es kann erst ausgecheckt werden, wenn alle Aufgaben bearbeitet sind."
                                    : displayedSession.checkInStatus === "checked-in"
                                    ? "Check Out"
                                    : "Check In"}
                            </button>
                        )}
                    </div>
                    <AddNoteModal
                        taskIsOnCompletion={taskIsOnCompletion ?? false}
                        modalId={0}
                        task_id={selectedTask?._id ?? ""}
                        close={() => modalCon?.trigger(0)}
                        sendNote={handleTask}
                        session_id={id ?? ""}
                    />
                    <ScanQrCodeModal
                        modalId={1}
                        shutDownCam={shutDownCamera}
                        startScanner={startScanner}
                        setstartScanner={() => setstartScanner(!startScanner)}
                        handleScan={handleScan}
                        noPermissionMessage={noPermissionMessage}
                        setnoPermissionMessage={setnoPermissionMessage}
                    />
                    <AddImagesModal
                        modalId={2}
                        taskId={selectedTask?._id ?? ""}
                        queryKeyOfSession={queryKeySingleSession}
                        handleAddImgs={() => modalCon?.trigger(2)}
                    />
                </BasePage>
            )}
        </>
    )
}

type TaskElementInSessionType = {
    task: getSingleTaskType
    checkInStatus: boolean
    setTask: (e: getSingleTaskType, c: boolean) => void
    taskExecutionFailed?: boolean
    linkToTask: string
    openImageModal: () => void
}

function TaskElementInSession(props: TaskElementInSessionType) {
    return (
        <div
            className={`${
                props.task.state === "completed" || props.taskExecutionFailed
                    ? "border-y-2 items-center justify-between flex-col sm:flex-row"
                    : "flex-col justify-center items-start"
            } flex w-full border-y border-gray-300 p-4 overflow-hidden h-fit`}>
            <div>
                <HashLink className="lineclamp-3 text-sm sm:text-base font-semibold cursor-pointer hover:underline " to={props.linkToTask}>
                    <p className="leading-5">Aufgabe: {props.task.activity}</p>
                </HashLink>
                <p className="text-xs pb-2">Beschreibung: {props.task.description ? props.task.description : "Keine Angabe"}</p>
            </div>
            {props.task.state === "completed" && (
                <div className="rounded-full bg-green-500 border-2 border-black p-2 flex gap-1 self-end">
                    <p className="text-xs sm:text-base font-semibold">Abgeschlossen</p>
                    <CHECK className="w-4 h-4 sm:w-6 sm:h-6" />
                </div>
            )}
            {(props.task.state === "execution-failed" || props.taskExecutionFailed) && (
                <div className="rounded-full gap-1 bg-red-500 border-2 text-black border-black p-2 flex self-end">
                    <p className="text-xs sm:text-base font-semibold">Fehlgeschlagen</p>
                    <PROBLEM className="w-4 h-4 sm:w-6 sm:h-6" />
                </div>
            )}
            {props.checkInStatus && props.task.state !== "completed" && !props.taskExecutionFailed && (
                <div className="w-full gap-2 sm:gap-4 flex flex-col sm:flex-row justify-center pt-4">
                    <button onClick={props.openImageModal} className="btn btn-outline md:btn-xs sm:w-[30%]">
                        Bilder
                        <KAMERA className="w-5 h-5" />
                    </button>
                    <button onClick={() => props.setTask(props.task, false)} className="btn btn-error md:btn-xs sm:w-[30%]">
                        Problem
                        <PROBLEM className="w-5 h-5" />
                    </button>
                    <button className=" btn btn-success md:btn-xs sm:w-[30%]" onClick={() => props.setTask(props.task, true)}>
                        Fertig
                        <CHECK className="w-4 h-4" />
                    </button>
                </div>
            )}
        </div>
    )
}
