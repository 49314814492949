import { API } from "../../../api/constants"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"

// ==================================================== DASHBOARD =====================================================  //


export const getFinanceStatistics = (data: { monthStart: Date; monthEnd: Date }) =>
    authenticatedAxiosInstance.get(API + `/dashboard/finance/${data.monthStart}/${data.monthEnd}`).then((res) => res.data)

export const getEmployeeStatistics = (key: string) =>
    authenticatedAxiosInstance.get(API + `/dashboard/employee/${key}`).then((res) => res.data)

export const getObjectStatistics = (key: string) =>
    authenticatedAxiosInstance.get(API + `/dashboard/object/${key}`).then((res) => res.data)

export const getCustomerStatistics = (key: string) =>
    authenticatedAxiosInstance.get(API + `/dashboard/customer/${key}`).then((res) => res.data)


// ==================================================== BANKING =====================================================  //

export const getPaymentStatistics = (data: { monthStart: Date; monthEnd: Date }) =>
    authenticatedAxiosInstance.get(API + `/dashboard/${data.monthStart}/${data.monthEnd}`).then((res) => res.data)

export const getPaymentRequirementFromBillData = (id: string) =>
    authenticatedAxiosInstance.get(API + "/paymentrequirements/get/from/bill/" + id).then((res) => res.data)

export const updateTransactionAndBillData = (data: { pid: string; tid: string }) =>
    authenticatedAxiosInstance.patch(API + `/banking/assign/transaction/${data.tid}/payReq/${data.pid}`).then((res) => res.data)

export const updateTransactionAndBillPullData = (data: { pid: string; tid: string }) =>
    authenticatedAxiosInstance.patch(API + `/banking/unassign/transaction/${data.tid}/payReq/${data.pid}`).then((res) => res.data)

export const ignoreTransaction = (tid: string) => authenticatedAxiosInstance.patch(API + `/banking/ignore/transaction/${tid}`).then((res) => res.data)

export const setReminder = (id: String) =>
    authenticatedAxiosInstance.post(API + `/paymentrequirements/${id}/send-next-reminder`).then((res) => res.data)


