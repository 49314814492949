import { API } from "../../../api/constants"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"
import { addTaskDataType, addTaskTemplateDataType, CoordinateType, getSingleTaskTemplateType, getSingleTaskType, sessionGetDataType } from "../types"

// ADD task
export const postNewTask = (body: addTaskDataType) => authenticatedAxiosInstance.post(API + "/task", body).then((res) => res.data)

// GET Single Task
export const getSingleTaskById = (id: string) => authenticatedAxiosInstance.get(API + `/task/${id}`).then((res) => res.data)

// UPDATE Task
export const updateTask = (data: { id: string; data: getSingleTaskType }) =>
    authenticatedAxiosInstance.put(API + `/task/${data.id}`, data.data).then((res) => res.data)

// DELETE Task
export const deleteTask = (id: string) => authenticatedAxiosInstance.delete(API + `/task/${id}`).then((res) => res.data)

// TODO GET Tasks by State
export const getTasksByState = (state: string) => authenticatedAxiosInstance.get(API + `/tasks/by-state/${state}`).then((res) => res.data)

// GET Task by Filter (obj_id, cc_id, unassigned-bool, billable-bool)
export const getTasksByFilter = (filter: { objectId?: string; costCenterId?: string; unassigned?: boolean; billable?: boolean }) =>
    authenticatedAxiosInstance.get(API + `/tasks`, { params: filter }).then((res) => res.data)

// TASK Execution failed
export const taskExecutionFailed = (data: { id: string; note: { note: string; session: string } }) =>
    authenticatedAxiosInstance.post(API + `/task/${data.id}/mark-failed`, data.note).then((res) => res.data)

// COMPLETE task
export const markTaskAsComplete = (data: { id: string; note?: { note: string; session: string } }) =>
    authenticatedAxiosInstance.post(API + `/task/${data.id}/complete`, data.note).then((res) => res.data)

// COMPLETE task
export const addImgsToTaskMutation = (data: { id: string; formData: FormData }) =>
    authenticatedAxiosInstance
        .post(API + `/task/${data.id}/documents`, data.formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => res.data)

// ================ TASK TEMPLATE ================ //

// GET single task template
export const getSingleTaskTemplate = (id: string) => authenticatedAxiosInstance.get(API + `/task-template/${id}`).then((res) => res.data)

// ADD task template
export const postNewTaskTemplate = (body: addTaskTemplateDataType) =>
    authenticatedAxiosInstance.post(API + "/task-template", body).then((res) => res.data)

// UPDATE task template
export const updateTaskTemplate = (data: { id: string; body: getSingleTaskTemplateType }) =>
    authenticatedAxiosInstance.put(API + "/task-template/" + data.id, data.body).then((res) => res.data)

// TODO UPDATE task template state
export const updateTaskTemplateState = (id: string) =>
    authenticatedAxiosInstance.post(API + "/task-template/" + id + "/activate-or-pause").then((res) => res.data)

// DELETE task template
export const deleteTaskTemplate = (id: string) => authenticatedAxiosInstance.delete(API + "/task-template/" + id).then((res) => res.data)

// ================ SESSION ================ //

// ADD session
export const postNewSession = (body: sessionGetDataType) => authenticatedAxiosInstance.post(API + "/session", body).then((res) => res.data)

// GET single session by id
export const getSingleSessionById = (id: string) => authenticatedAxiosInstance.get(API + "/session/" + id).then((res) => res.data)

// GET sessions by employee-id
export const getSessionByEmployee = (id: string) => authenticatedAxiosInstance.get(API + "/sessions/by-employee/" + id).then((res) => res.data)

// UPDATE session
export const updateSession = (data: { id: string; body: sessionGetDataType }) =>
    authenticatedAxiosInstance.put(API + "/session/" + data.id, data.body).then((res) => res.data)

// UPDATE session attendees
export const updateSessionAttendees = (data: { id: string; body: sessionGetDataType }) =>
    authenticatedAxiosInstance.put(API + "/session-attendee/" + data.id, data.body).then((res) => res.data)

// DELETE session
export const deleteSession = (id: string) => authenticatedAxiosInstance.delete(API + "/session/" + id).then((res) => res.data)

// ASSIGN Task
export const assignTasksToSession = (data: { id: string; body: string[] }) =>
    authenticatedAxiosInstance.post(API + "/tasks/assign/" + data.id, data.body).then((res) => res.data)

// UNASSIGN Task
export const unAssignTasksToSession = (data: { id: string; body: string[] }) =>
    authenticatedAxiosInstance.post(API + "/tasks/unassign/" + data.id, data.body).then((res) => res.data)

// CHECKIN/OUT Session manual
export const checkInOrOutToSessionManual = (data: { id: string; indicator: "in" | "out" }) =>
    authenticatedAxiosInstance.post(API + `/session/check-${data.indicator}/${data.id}/manual`).then((res) => res.data)

// CHECKIN/OUT Session via QR
export const checkInOrOutToSessionQrCode = (data: { session: string; qrcode: string; indicator: "in" | "out"; coordinates: CoordinateType }) =>
    authenticatedAxiosInstance
        .post(API + `/session/check-${data.indicator}/${data.session}/qr-code/${data.qrcode}`, data.coordinates)
        .then((res) => res.data)
