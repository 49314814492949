import dayjs from "dayjs"
import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { BillPost, BillTemplatePost, OfferPost, PositionData } from "../../../../data/Types"
import { Customer } from "../../../Kunden/types/Types"
import { calculatePositionTotal, returnAddressString } from "../../../../services/functions"
import { ObjectType } from "../../../Objekte/types/types"
import PositionReview from "./modalComponents/PositionReview"
import { Form } from "antd"
import { useEffect } from "react"
import BaseDocElement from "../../../../components/elements/BaseDocElement"

type AddBillOrOfferModalScreen4Type = {
    newBillOrOffer: BillPost | OfferPost | BillTemplatePost
    visible: boolean
    customer: Customer | any
    documents?: any[]
}

export default function AddBillOrOfferModalScreen4(props: AddBillOrOfferModalScreen4Type) {
    const [form] = Form.useForm()
    useEffect(() => {
        props.newBillOrOffer?.positions?.forEach((item, index) => {
            form.setFieldsValue({
                ["position_id" + index]: index + 1,
                ["position_description" + index]: item.description,
                ["position_unit" + index]: item.unit,
                ["position_tax" + index]: item.tax,
                ["position_amount" + index]: item.amount,
                ["position_singlePrice" + index]: item.singlePrice,
            })
        })
    }, [form, props.newBillOrOffer?.positions])
    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: props.visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full pl-3 z-30 bg-white pb-2 overflow-y-scroll pt-4">
            {(props.newBillOrOffer as BillPost)?.billType && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Rechnungstyp"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billType}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.customer_id && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Kunde"}
                    type={"text"}
                    value={props.customer?.customer?.name ?? props.customer?.customer?.customer?.name ?? ""}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.object_id && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Objekt"}
                    type={"text"}
                    value={
                        props.customer?.objects &&
                        returnAddressString(
                            props.customer?.objects?.find(
                                (item: { _id: string | undefined }) => item._id === props.newBillOrOffer?.object_id
                            ) as ObjectType
                        )
                    }
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billCategorie && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Rechnungskategorie"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billCategorie}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billDay && (props.newBillOrOffer as BillPost)?.billType !== "Rechnung" && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Zahltag des Monats"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billDay}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.billDue && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Fällig nach"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.billDue}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.serviceDate && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Leistungsdatum"}
                    type={"text"}
                    value={dayjs((props.newBillOrOffer as BillPost)?.serviceDate).format("DD.MM.YYYY")}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.reminderStartOffsetInDays && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Nach wie vielen Tagen wird gemahnt"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.reminderStartOffsetInDays}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.reminderIntervalInDays && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"In welchem Abstand wird gemahnt"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.reminderIntervalInDays}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.maxReminders && (props.newBillOrOffer as BillPost)?.manualReminderEnabled && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Anzahl an Mahnungen"}
                    type={"text"}
                    value={(props.newBillOrOffer as BillPost)?.maxReminders}
                    onChange={() => {}}
                />
            )}
            {props.newBillOrOffer?.additionalText && (
                <BaseInputField
                    classProps="pt-2"
                    disabled
                    label={"Zusatztext"}
                    type={"text"}
                    value={props.newBillOrOffer?.additionalText}
                    onChange={() => {}}
                />
            )}
            {(props.newBillOrOffer as BillPost)?.forceFirstPayreqSameMonth && (
                <p>Es wird eine vergangene Zahlungsanforderung für diesen Monat erstellt</p>
            )}
            <p className="font-semibold pt-2">Positionen:</p>
            <div className="flex flex-col gap-2">
                <Form form={form}>
                    {props.newBillOrOffer?.positions?.map((item: PositionData, index) => (
                        <PositionReview
                            position={item}
                            index={index}
                            disablePrice
                            disableAmount
                            disableDescription
                            disableTax
                            disableUnit
                            handleInputChange={() => {}}
                            removePosition={() => {}}
                        />
                    ))}
                </Form>
            </div>
            <p className="flex justify-end font-semibold pr-4 border-t pt-1 pb-2">
                Gesamt: {calculatePositionTotal(props.newBillOrOffer?.positions)?.toString()?.replace(".", ",")} €
            </p>
            <p className="font-semibold">Hochgeladene Dokumente:</p>
            {(!props.documents || props.documents.length === 0) && (
                <p className="text-xs pl-2 text-gray-300">Keine zusätzlichen Dokumente hochgeladen</p>
            )}
            <div className="flex flex-col gap-1 pr-4 pt-2">
                {props.documents &&
                    props.documents.length > 0 &&
                    props.documents.map((item) => <BaseDocElement label={item?.name ? item.name : "Dokument"} />)}
            </div>
        </motion.div>
    )
}
