import { useContext, useEffect } from "react"
import { Roles } from "../../../../auth/RoleMapping"
import { DocumentDataType } from "../../../../components/types"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import BaseDivider from "../../../../components/elements/BaseDivider"
import BaseDocumentElement from "../../../../components/elements/BaseDocumentElement"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { MailReportType } from "../../../../data/Types"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import BaseMailPreviewDataRow from "../../../../components/elements/BaseMailPreviewDataRow"
import { Form, FormInstance, Radio } from "antd"
import BaseButton from "../../../../components/elements/BaseButton"
import { ReactComponent as PLUS } from "../../../../assets/PlusICON.svg"
import { ReactComponent as DELETE } from "../../../../assets/DeleteICON.svg"
import { ReactComponent as EDIT } from "../../../../assets/EditICON.svg"
import { Customer } from "../../types/Types"
import BasePopover from "../../../../components/elements/BasePopover"

type CustomerDetailPartLeftType = {
    form: FormInstance
    displayedCustomer: Customer
    setdisplayedCustomer: (e: any) => void
    setfileToDelete: (e: string) => void
    handleDownload: (e: string, f: string) => void
    handleUpdateCustomer: () => void
    setselectedS3Key: (e: string) => void
    mailReports: MailReportType[]
    setResendUri: (e: string) => void
    setMailPreviewContent: (e: string) => void
    openAddAccessModal: (e: { type: "customer" | "administrator"; mail: string }) => void
    removePortalAccess: (e: "customer" | "administrator") => void
    mail?: string
    setmail: (e: string | undefined) => void
}

export default function CustomerDetailPartLeft(props: CustomerDetailPartLeftType) {
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)

    // FE LOGIC
    useEffect(() => {
        props.form.setFieldsValue({
            customer: props.displayedCustomer.customer.name,
            customer_tel: props.displayedCustomer.customer.tel,
            customer_person: props.displayedCustomer.customer.person,
            customer_mail: props.displayedCustomer.customer.mail,
            customer_comment: props.displayedCustomer.customer.comment,
            customer_adress_street: props.displayedCustomer.customer.adress.street,
            customer_adress_nr: props.displayedCustomer.customer.adress.nr,
            customer_adress_plz: props.displayedCustomer.customer.adress.plz,
            customer_adress_city: props.displayedCustomer.customer.adress.city,
            administrator: props.displayedCustomer.administrator.name,
            administrator_tel: props.displayedCustomer.administrator.tel,
            administrator_person: props.displayedCustomer.administrator.person,
            administrator_mail: props.displayedCustomer.administrator.mail,
            administrator_comment: props.displayedCustomer.administrator.comment,
            administrator_adress_street: props.displayedCustomer.administrator.adress.street,
            administrator_adress_nr: props.displayedCustomer.administrator.adress.nr,
            administrator_adress_plz: props.displayedCustomer.administrator.adress.plz,
            administrator_adress_city: props.displayedCustomer.administrator.adress.city,
        })
    }, [props.displayedCustomer])

    return (
        <div className="flex flex-col w-full bg-white rounded-default shadow-lg py-4 px-2  h-[80vh] overflow-y-scroll">
            <div className="flex flex-row gap-4  ">
                {/* CUSTOMER */}
                <div className="flex flex-col w-full h-fit">
                    <Form.Item className="mb-2" name="customer" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Besitzer"}
                            type={"text"}
                            value={props.displayedCustomer.customer.name}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    customer: {
                                        ...prev.customer,
                                        name: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="customer_tel" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Telefon"}
                            type={"text"}
                            value={props.displayedCustomer.customer.tel}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    customer: {
                                        ...prev.customer,
                                        tel: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="customer_person" rules={[{ required: false, message: "" }]}>
                        <BaseInputField
                            label={"Ansprechpartner"}
                            type={"text"}
                            value={props.displayedCustomer.customer.person}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    customer: {
                                        ...prev.customer,
                                        person: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="customer_mail" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"E-Mail-Adresse *"}
                            type={"mail"}
                            value={props.displayedCustomer.customer.mail}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    customer: {
                                        ...prev.customer,
                                        mail: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="customer_comment" rules={[{ required: false, message: "" }]}>
                        <BaseInputArea
                            label={"Bemerkung"}
                            value={props.displayedCustomer.customer.comment}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    customer: {
                                        ...prev.customer,
                                        comment: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <BaseCheckbox
                        check={props.displayedCustomer.customer?.receivesMails}
                        disabled={
                            props.displayedCustomer.customer?.receivesMails !== props.displayedCustomer.administrator?.receivesMails &&
                            props.displayedCustomer.customer?.receivesMails
                        }
                        setcheck={(e) =>
                            props.setdisplayedCustomer((prev: any) => ({
                                ...prev,
                                customer: {
                                    ...prev.customer,
                                    receivesMails: e,
                                },
                            }))
                        }
                        label={"erhält Rechnungen & Angebote"}
                        bold
                        classProps="mt-8"
                    />
                    <BaseDivider classProps="mb-4 mt-4" />
                    <div className="flex flex-row gap-4 w-full">
                        <Form.Item
                            className="mb-2 w-full"
                            name="customer_adress_street"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Straße"}
                                type={"text"}
                                value={props.displayedCustomer.customer.adress?.street}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        customer: {
                                            ...prev.customer,
                                            adress: {
                                                ...prev.customer.adress,
                                                street: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-2 w-40"
                            name="customer_adress_nr"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Nummer"}
                                type={"text"}
                                value={props.displayedCustomer.customer.adress?.nr}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        customer: {
                                            ...prev.customer,
                                            adress: {
                                                ...prev.customer.adress,
                                                nr: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-row gap-4 w-full">
                        <Form.Item
                            className="mb-2 w-40"
                            name="customer_adress_plz"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"PLZ *"}
                                type={"text"}
                                value={props.displayedCustomer.customer.adress?.plz}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        customer: {
                                            ...prev.customer,
                                            adress: {
                                                ...prev.customer.adress,
                                                plz: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-2 w-full"
                            name="customer_adress_city"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Stadt"}
                                type={"text"}
                                value={props.displayedCustomer.customer.adress?.city}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        customer: {
                                            ...prev.customer,
                                            adress: {
                                                ...prev.customer.adress,
                                                city: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </div>
                </div>
                {/* ADMINISTRATOR */}
                <div className="flex flex-col w-full h-fit">
                    <Form.Item className="mb-2" name="administrator" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Verwalter"}
                            type={"text"}
                            value={props.displayedCustomer.administrator.name}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    administrator: {
                                        ...prev.administrator,
                                        name: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="administrator_tel" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"Telefon"}
                            type={"text"}
                            value={props.displayedCustomer.administrator.tel}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    administrator: {
                                        ...prev.administrator,
                                        tel: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="administrator_person" rules={[{ required: false }]}>
                        <BaseInputField
                            label={"Ansprechpartner"}
                            type={"text"}
                            value={props.displayedCustomer.administrator.person}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    administrator: {
                                        ...prev.administrator,
                                        person: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="administrator_mail" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputField
                            label={"E-Mail-Adresse"}
                            type={"text"}
                            value={props.displayedCustomer.administrator.mail}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    administrator: {
                                        ...prev.administrator,
                                        mail: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item className="mb-2" name="administrator_comment" rules={[{ required: false, message: "Muss einen Wert enthalten!" }]}>
                        <BaseInputArea
                            label={"Bemerkung"}
                            value={props.displayedCustomer.administrator.comment}
                            onChange={(e) =>
                                props.setdisplayedCustomer((prev: any) => ({
                                    ...prev,
                                    administrator: {
                                        ...prev.administrator,
                                        comment: e,
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                    <BaseCheckbox
                        label={"erhält Rechnungen & Angebote"}
                        check={props.displayedCustomer.administrator?.receivesMails}
                        setcheck={(e) =>
                            props.setdisplayedCustomer((prev: any) => ({
                                ...prev,
                                administrator: {
                                    ...prev.administrator,
                                    receivesMails: e,
                                },
                            }))
                        }
                        disabled={
                            props.displayedCustomer.administrator?.receivesMails !== props.displayedCustomer.customer?.receivesMails &&
                            props.displayedCustomer.administrator?.receivesMails
                        }
                        bold
                        classProps="mt-8"
                    />
                    <BaseDivider classProps="my-4" />
                    <div className="flex flex-row gap-4">
                        <Form.Item
                            className="mb-2 w-full"
                            name="administrator_adress_street"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Straße"}
                                type={"text"}
                                value={props.displayedCustomer.administrator.adress?.street}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        administrator: {
                                            ...prev.administrator,
                                            adress: {
                                                ...prev.administrator.adress,
                                                street: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-2 w-40"
                            name="administrator_adress_nr"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Nummer"}
                                type={"text"}
                                value={props.displayedCustomer.administrator.adress?.nr}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        administrator: {
                                            ...prev.administrator,
                                            adress: {
                                                ...prev.administrator.adress,
                                                nr: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-row gap-4">
                        <Form.Item
                            className="mb-2 w-40"
                            name="administrator_adress_plz"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"PLZ"}
                                type={"text"}
                                value={props.displayedCustomer.administrator.adress?.plz}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        administrator: {
                                            ...prev.administrator,
                                            adress: {
                                                ...prev.administrator.adress,
                                                plz: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-2 w-full"
                            name="administrator_adress_city"
                            rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                            <BaseInputField
                                label={"Stadt"}
                                type={"text"}
                                value={props.displayedCustomer.administrator.adress?.city}
                                onChange={(e) =>
                                    props.setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        administrator: {
                                            ...prev.administrator,
                                            adress: {
                                                ...prev.administrator.adress,
                                                city: e,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Radio.Group
                name="isAdministratorDocumentReceiver"
                onChange={(e) =>
                    props.setdisplayedCustomer((prev: any) => ({
                        ...prev,
                        isAdministratorDocumentReceiver: e.target.value,
                    }))
                }
                value={props.displayedCustomer.isAdministratorDocumentReceiver}
                className="flex justify-evenly pt-4">
                <Radio value={false}> Adresse des Besitzers ist im Briefkopf</Radio>
                <Radio value={true}> Adresse des Verwalters ist im Briefkopf</Radio>
            </Radio.Group>
            <BaseDivider classProps="mb-4 mt-4" />
            <h4 className="text-lg font-semibold pl-2 pb-2">Portalzugriff</h4>
            <div className="flex flex-row gap-4  ">
                <div className="flex flex-col w-full h-fit justify-center items-center">
                    {props.displayedCustomer.portalAccessCustomer ? (
                        <div className="flex flex-col gap-2 relative">
                            <BasePopover
                                label={"Zugang Wirklich entfernen?"}
                                okButtonClick={() => props.removePortalAccess("customer")}>
                                <DELETE className="w-4 h-4 absolute top-0 right-6 cursor-pointer" />
                            </BasePopover>
                            <EDIT
                                onClick={() =>
                                    props.openAddAccessModal({
                                        type: "customer",
                                        mail: props.displayedCustomer?.portalAccessCustomer?.mail ?? "",
                                    })
                                }
                                className="w-4 h-4 top-0 right-0 cursor-pointer absolute"
                            />
                            <BaseInputField
                                width="w-64"
                                disabled
                                value={props.displayedCustomer.portalAccessCustomer.mail}
                                label={"Account Mail"}
                                type={"email"}
                                onChange={() => {}}
                            />
                        </div>
                    ) : (
                        <BaseButton
                            type="button"
                            label={"Hinzufügen"}
                            func={() => props.openAddAccessModal({ type: "customer", mail: props.displayedCustomer.customer.mail })}
                            icon={<PLUS className="w-6 h-6" />}
                        />
                    )}
                </div>
                <div className="flex flex-col w-full h-fit justify-center items-center">
                    {props.displayedCustomer.portalAccessAdministrator ? (
                        <div className="flex flex-col gap-2 relative">
                            <BasePopover
                                label={"Zugang Wirklich entfernen?"}
                                okButtonClick={() => props.removePortalAccess("administrator")}>
                                <DELETE className="w-4 h-4 absolute top-0 right-6 cursor-pointer" />
                            </BasePopover>
                            <EDIT
                                onClick={() =>
                                    props.openAddAccessModal({
                                        type: "administrator",
                                        mail: props.displayedCustomer?.portalAccessAdministrator?.mail ?? "",
                                    })
                                }
                                className="w-4 h-4 top-0 right-0 cursor-pointer absolute"
                            />
                            <BaseInputField
                                width="w-64"
                                disabled
                                value={props.displayedCustomer.portalAccessAdministrator.mail}
                                label={"Account Mail"}
                                type={"email"}
                                onChange={() => {}}
                            />
                        </div>
                    ) : (
                        <BaseButton
                            type="button"
                            label={"Hinzufügen"}
                            func={() => props.openAddAccessModal({ type: "administrator", mail: props.displayedCustomer.administrator.mail })}
                            icon={<PLUS className="w-6 h-6" />}
                        />
                    )}
                </div>
            </div>

            <BaseDivider classProps="mt-8 mb-4" />
            {/* DOCUMENTS & IMAGES */}
            <div className="flex flex-col pt-2">
                <label className="text-sm pl-2 pb-1">Dokumente</label>
                {props.displayedCustomer.documents?.additionalS3?.map((item: DocumentDataType, index: number) => (
                    <BaseDocumentElement
                        key={index}
                        title={item.title}
                        timestamp={item.timestamp}
                        roles={[Roles.kunden_read_write]}
                        downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                        deleteFunc={() => {
                            props.setfileToDelete(item.s3Key)
                            modalCon?.trigger(1)
                        }}
                        showPreviewFunc={() => {
                            props.setselectedS3Key(item.s3Key)
                            sidePanelCon?.trigger(3)
                        }}
                    />
                ))}
                {props.displayedCustomer.documents?.additionalS3?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}

                <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                    <button
                        className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                        onClick={() => modalCon?.trigger(2)}>
                        Dokument hinzufügen
                    </button>
                </RenderWhenAuthorized>
            </div>
            <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                <button className="md:hidden btn btn-success mt-4" onClick={props.handleUpdateCustomer}>
                    Speichern
                </button>
            </RenderWhenAuthorized>
            <BaseDivider classProps="mt-8 mb-4" />
            <h5 className="text-sm pl-2 pb-1">An den Kunden verschickte Mails:</h5>
            {props.mailReports
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .map((item, key) => (
                    <BaseMailPreviewDataRow
                        mailType
                        sidePanelConId={4}
                        index={props.mailReports.indexOf(item) + 1}
                        item={item}
                        setResendUri={props.setResendUri}
                        setMailPreviewContent={props.setMailPreviewContent}
                    />
                ))}
        </div>
    )
}
