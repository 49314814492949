import "../../style/loading.css"

type LoadingType = {
    classProps?: string
}

export default function Loading(props: LoadingType) {
    return (
        <div className={`h-full w-full overflow-hidden ${props.classProps}`}>
            <div className="loader"></div>
        </div>
    )
}
