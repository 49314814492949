import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { InfoElementType, ObjectCardType } from "../types/types"
import { ReactComponent as RESTORE } from "../../../assets/RefreshICON.svg"
import { ReactComponent as MAPPIN } from "../../../assets/MapPinICON.svg"
import { ReactComponent as ARCHIVE } from "../../../assets/ArchiveICON.svg"
import { ReactComponent as UNARCHIVE } from "../../../assets/UnArchiveICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import BasePopover from "../../../components/elements/BasePopover"
export default function ObjectCard(props: ObjectCardType) {
    return (
        <div
            className={`w-full max-h-64 h-fit rounded-default shadow-lg flex flex-col px-4 pt-4 pb-6 bg-white relative ${
                props.archived && "text-gray-400 opacity-60"
            }`}>
            {props.deleted && (
                <p className="absolute top-20 bottom-[50%] right-20 rotate-12 font-bold text-black/30 opacity-50 scale-150">Gelöscht</p>
            )}
            <h3 title={props.adress.street} className="border-b mb-4 pb-1 text-lg 2xl:text-xl font-semibold px-2 truncate">
                {props.adress.street} {props.adress.nr}, {props.adress.city} {props.adress.plz}
            </h3>
            <InfoElement info={props.costcenter?.name} icon={<MAPPIN className="w-6 h-6" />} />
            <InfoElement
                info={props.offers + " Angebote"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                    </svg>
                }
            />
            <InfoElement
                info={props.bills + " gestellte Rechnungen"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                }
            />
            <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write, Roles.objekte_delete]}>
                <div className="flex flex-row justify-end gap-2 grow items-center z-10">
                    <p title="Archivieren">
                        <BasePopover
                            label={props.archived ? "Archivieren Rückgängig machen?" : "Wirklich archivieren?"}
                            okButtonClick={props.archiveFunc}>
                            {props.archived ? <UNARCHIVE className="w-6 h-6 cursor-pointer" /> : <ARCHIVE className="w-6 h-6 cursor-pointer" />}
                        </BasePopover>
                    </p>
                    {!props.deleted ? (
                        <p title="Löschen" onClick={props.deleteFunc}>
                            <DELETE className="w-6 h-6 cursor-pointer hover:text-error" />
                        </p>
                    ) : (
                        <p title="Wiederherstellen" onClick={props.restoreFunc}>
                            <RESTORE className="w-6 h-6 cursor-pointer" />
                        </p>
                    )}
                </div>
            </RenderWhenAuthorized>
            {/* CLICK ON CARD FOR OPEN */}
            <div onClick={props.openFunc} className="w-full h-full absolute top-0 left-0 cursor-pointer" />
        </div>
    )
}

function InfoElement({ info, icon }: InfoElementType) {
    return (
        <div className="flex flex-row pt-0.5 2xl:pt-1 pl-2">
            {icon}
            <p className="pl-2 text-sm 2xl:text-base">{info}</p>
        </div>
    )
}
