import { getSingleTaskTemplateType, getSingleTaskType } from "../types"
import BaseTable, { ColumnType } from "../../../components/elements/BaseTable"
import TaskTableRow from "./TaskTableRow"
import TaskTemplateTableRow from "./TaskTemplateTableRow"

type AllTasksTableType = {
    tasks?: getSingleTaskType[] | getSingleTaskTemplateType[]
    taskTemplates?: getSingleTaskTemplateType[]
    addOrPause?: (e: string) => void
    openSelectEmployeeModal?: () => void
    deleteTask: (e: string) => void
}

export default function AllTasksTable(props: AllTasksTableType) {
    const taskTableColumns: ColumnType[] = [
        {
            label: "Aufgabe",
        },
        {
            label: "Objekt",
        },
        {
            label: "Erstellt am",
        },
        {
            label: "Fällig am",
        },
        {
            label: "Priorität",
        },
        {
            label: "Status",
        },
        {
            label: "Frequenz",
        },
        {
            label: "",
        },
        {
            label: "",
        },
    ]

    return (
        <>
            {props.tasks && (
                <BaseTable
                    columns={taskTableColumns}
                    tbody={props.tasks?.map((item) => (
                        <TaskTableRow
                            task={item as getSingleTaskType}
                            deleteTask={props.deleteTask}
                            openSelectEmployeeModal={props.openSelectEmployeeModal}
                        />
                    ))}
                />
            )}
            {props.taskTemplates && (
                <BaseTable
                    columns={taskTableColumns.filter((item) => item.label !== "Fällig am")}
                    tbody={props.taskTemplates?.map((item) => (
                        <TaskTemplateTableRow
                            taskTemplate={item as getSingleTaskTemplateType}
                            deleteTask={props.deleteTask}
                            addOrPause={props.addOrPause}
                        />
                    ))}
                />
            )}
        </>
    )
}
