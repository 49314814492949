import { useContext, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { ModalContext } from "../../../components/contexts/ModalContext"
import AddPersonModal from "../components/AddPersonModal"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getCostCenterData } from "../api/apiCalls"
import { useArchiveDataMutation, useDeleteDataMutation } from "../../api/services"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import EmployeePart from "../components/EmployeeBody"
import { getPaginatedData } from "../../api/apiCalls"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import CostCenterModal from "../components/CostCenterModal"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as HOUSE } from "../../../assets/HouseICON.svg"
import { ReactComponent as PLUS } from "../../../assets/PlusInCircleIcon.svg"

export default function Mitarbeiter() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>(false)
    const [filterPosition, setfilterPosition] = useState<string>()

    const queryKey = "employeeData" + page + searchParams + filterArchived + filterPosition
    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({
                pageType: "employee",
                page: page,
                limit: 20,
                search: searchParams,
                archived: filterArchived,
                position: filterPosition,
            }),
    })

    // get all costcenter
    const {
        error: costcenterError,
        data: costcenterData,
        isLoading: costcenterisLoading,
    } = useQuery({
        queryKey: ["cost-center-all"],
        queryFn: getCostCenterData,
    })

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // Update & Archive & Delete Employee
    const { mutate: archiveEmployee } = useArchiveDataMutation(queryClient, () => modalCon?.trigger(3), queryKey, "Mitarbeiterstatus")
    const handleArchiveEmployee = (eid: string, archive: boolean) => {
        archiveEmployee({
            id: eid,
            body: { archived: archive },
            pageType: "employee",
        })
    }

    const { mutate: deleteEmployee } = useDeleteDataMutation(queryClient, modalCon, "Mitarbeiter", queryKey)
    const handleDeleteEmployee = (eid: string) => {
        deleteEmployee({ id: eid, pageType: "employee" })
    }

    // ============================
    // filter settings & searchbar
    const [showFilter, setShowFilter] = useState(false)

    const handleFilterRemove = () => {
        setfilterArchived(false)
        setfilterPosition("")
    }

    const displayedEmployee = useMemo(() => data?.docs, [data?.docs])

    return (
        <BasePage>
            <HeadBanner
                title="Mitarbeiter"
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_create, Roles.mitarbeiter_read_write]}>
                        <PLUS className="w-8 h-8 cursor-pointer hidden md:block z-[500]" onClick={() => modalCon?.trigger(1)} />
                    </RenderWhenAuthorized>
                }
            />
            <BaseButtonRow>
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Mitarbeiter suchen..."
                    className="w-full lg:max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <div className="flex gap-3 z-40">
                    <RenderWhenAuthorized
                        requiresAll={[
                            Roles.niederlassungen_create,
                            Roles.niederlassungen_read_write,
                            Roles.niederlassungen_read,
                            Roles.niederlassungen_delete,
                        ]}>
                        <button className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => modalCon?.trigger(9)}>
                            <HOUSE className="w-6 h-6" />
                            <span className="hidden 2xl:block">Niederlassungen</span>
                        </button>
                    </RenderWhenAuthorized>
                    {window.innerWidth > 860 && <BaseFilterButton func={() => setShowFilter(!showFilter)} />}
                </div>
            </BaseButtonRow>
            <BaseFilterModal
                height="max-h-[32rem]"
                visible={showFilter}
                searchArchived={filterArchived}
                changeArchived={setfilterArchived}
                removeFilter={handleFilterRemove}
                searchPosition={filterPosition}
                changePosition={setfilterPosition}
                setVisible={() => setShowFilter(!showFilter)}
                statusType={"employee"}
            />
                {/* Body Part */}
                <EmployeePart
                    error={error}
                    isLoading={isLoading}
                    displayedEmployee={displayedEmployee ?? []}
                    page={page}
                    totalDocs={data?.totalDocs}
                    archiveFunction={handleArchiveEmployee}
                    deleteFunction={handleDeleteEmployee}
                    handlePageChange={() => handlePageChange}
                    removeInput={() => {
                        setsearchTerm("")
                        handleFilterRemove()
                    }}
                />
            <AddPersonModal costcenter={costcenterData ?? []} queryUrl={"employeeData" + page + searchParams + filterArchived + filterPosition} />
            <CostCenterModal modalId={9} costcenter={costcenterData} error={costcenterError} isLoading={costcenterisLoading} />
        </BasePage>
    )
}
