import { useParams } from "react-router-dom"
import BaseHeadBanner from "../../../../components/elements/BaseHeadBanner"
import BasePage from "../../../../components/layout/BasePage"
import ModalCheckAction from "../../../../components/elements/ModalCheckAction"
import { useContext, useMemo, useState } from "react"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleBillTemplateData } from "../../api/apiCalls"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { useArchiveBillTemplateMutation, useCancelBillTemplateMutation } from "../../api/services"
import { useDownloadDocumentMutation } from "../../../api/services"
import BillDetailBillPart from "./../billDetail/components/BillDetailBillPart"
import { BillTemplateGet } from "../../../../data/Types"
import BasePreview from "../../../../components/elements/BasePreview"
import { API } from "../../../../api/constants"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import BillTemplateHeadBanner from "./components/BillTemplateHeadBanner"
import BillTemplateButtonRow from "./components/BillTemplateButtonRow"
import BillTemplateBillsAndFiles from "./components/BillTemplateBillsAndFiles"

export default function BillTemplateDetailPage() {
    const { id } = useParams<string>()
    const { data, error, isLoading } = useQuery({
        queryKey: ["singleBillTemplate" + id],
        queryFn: () => getSingleBillTemplateData(id || ""),
    })
    const displayedBillTemplate = useMemo<BillTemplateGet>(() => data, [data])
    const modalCon = useContext(ModalContext)

    // Archive BillTemplate Mutation
    const queryClient = useQueryClient()
    const { mutate: archiveBillTemplate } = useArchiveBillTemplateMutation(queryClient)
    const handleArchiving = () => {
        modalCon?.trigger(0)
        archiveBillTemplate({
            id: id ?? displayedBillTemplate._id,
            archived: !displayedBillTemplate.archived,
        })
    }

    // Cancel BillTemplate Mutation
    const { mutate: cancelBillTemplate } = useCancelBillTemplateMutation(queryClient)
    const handleCanceling = () => {
        modalCon?.trigger(2)
        cancelBillTemplate(id ?? displayedBillTemplate._id)
    }

    // download file - bill itself or bill attachment
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (fileData?: { s3Key: string; filename: string }) => {
        if (fileData) {
            // download bill attachement
            downloadFile({ id: data._id, s3Key: fileData.s3Key, filetype: "bill", filename: fileData.filename })
        } else {
            // download bill itself
            downloadFile({ id: data._id, s3Key: data.s3DocumentRef.s3Key, filetype: "bill", filename: "Rechnung " + data.nr + ".pdf" })
        }
    }

    // current bill

    // preview bill or attachement
    const sidePanelCon = useContext(SidePanelContext)
    const [previewElement, setpreviewElement] = useState<string>()
    const handleChangePreviewElement = (s3Key: string) => {
        setpreviewElement(API + `/bill/${data?._id}/download?s3Key=${s3Key}`)
        sidePanelCon?.trigger(3)
    }

    return (
        <BasePage>
            <BaseHeadBanner
                title={"Rechnungsvorlage " + displayedBillTemplate?.billTemplateCategorie + (displayedBillTemplate?.canceled ? " (STORNIERT)" : "")}
            />
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    <BillTemplateHeadBanner data={data} />
                    <BillDetailBillPart data={data} positions={data.positions} netto={data.totalNetto} brutto={data.totalBrutto} />
                    <BillTemplateBillsAndFiles
                        bills={displayedBillTemplate.bills ?? []}
                        data={data}
                        handleDownload={handleDownload}
                        handleChangePreviewElement={handleChangePreviewElement}
                    />
                    <BillTemplateButtonRow canceled={data.canceled} />
                </>
            )}
            <ModalCheckAction
                modalId={0}
                headline={data?.archived ? "Archivieren Rückgängig machen?" : "Wirklich Archivieren?"}
                text={
                    data?.archived
                        ? "Sind Sie sicher, dass Sie die Rechnungsvorlage nicht mehr archivieren wollen?"
                        : "Sind Sie sich sicher, dass Sie die Rechnungsvorlage archivieren wollen?"
                }
                buttonText={data?.archived ? "Rückgängig" : "Archivieren"}
                buttonColor={"bg-blue-300 hover:bg-blue-400 border-none"}
                func={handleArchiving}
            />
            <ModalCheckAction
                modalId={2}
                headline={"Wirklich Beenden?"}
                text={
                    "Sind Sie sich sicher, dass Sie die Rechnungsvorlage beenden wollen? Es werden anschließend keine Rechnungen mehr aus dieser Vorlage generiert. Das Beenden hat keinen Effekt auf bereits gestellte Rechnungen."
                }
                buttonText={"Beenden"}
                buttonColor={"btn-error"}
                func={handleCanceling}
            />
            <BasePreview
                previewType="document"
                sidePanelId={3}
                title={"Rechnungsvorschau"}
                content={previewElement}
                onClose={() => setpreviewElement(undefined)}
            />
        </BasePage>
    )
}
