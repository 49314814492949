import BaseModal from "../../../components/layout/BaseModal"
import { QrReader } from "react-qr-reader"
import { ReactComponent as RELOAD } from "../../../assets/RefreshICON.svg"
import { useEffect, useRef, useState } from "react"

type ScanQrCodeModalProps = {
    modalId: number
    startScanner: boolean
    setstartScanner: (e: boolean) => void
    shutDownCam: () => void
    handleScan: (e: any) => void
    noPermissionMessage: string | undefined
    setnoPermissionMessage: (e: string | undefined) => void
}

export default function ScanQrCodeModal(props: ScanQrCodeModalProps) {
    return (
        <BaseModal modalId={props.modalId} title={"QR Code"} size onClose={props.shutDownCam}>
            <div className="relative flex justify-center items-center w-full h-64 flex-col">
                <div className="absolute">
                    <p className="text-gray-300 text-sm text-center">Öffnet Kamera...</p>
                </div>
                {props.startScanner && (
                    <div className="w-80 h-fit">
                        <QrReader
                            scanDelay={100}
                            onResult={(result, error) => {
                                if (result) {
                                    props.handleScan(result)
                                }
                                if (error) {
                                    if (error.message === "Permission denied") {
                                        props.setstartScanner(false)
                                        props.setnoPermissionMessage(
                                            "Es scheint als hätten Sie dem Browser die Rechte verwährt, auf die Kamera zuzugreifen. Bitte erlauben Sie den Kamera-Zugriff durch die App und versuchen es erneut."
                                        )
                                    }
                                }
                            }}
                            className="w-full rounded-default"
                            constraints={{ facingMode: "environment" }}
                        />
                    </div>
                )}
                {!props.startScanner && (
                    <div className="flex w-full items-center justify-center flex-col bg-white z-10">
                        <RELOAD
                            className="w-8 h-8 text-primary"
                            onClick={() => {
                                props.setstartScanner(true)
                            }}
                        />
                        <p className="uppercase text-gray-300 font-semibold text-sm">Kamera erneut öffnen</p>
                        {props.noPermissionMessage && <p className="text-xs w-64 text-center">{props.noPermissionMessage}</p>}
                    </div>
                )}
            </div>

            <p className="w-80 font-semibold text-center leading-5 pb-2">Bitte scannen Sie den im Objekt ausgehängten QR Code.</p>
            <p className="w-80 text-gray-300 text-xs text-center">
                Kein QR-Code vorhanden? Bitte kontaktieren Sie Ihren Niederlassungsleiter telefonisch.
            </p>
        </BaseModal>
    )
}
