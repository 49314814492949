import BaseTable, { ColumnType } from "../../../components/elements/BaseTable"
import { OrderRequestType } from "../types"
import RequestTableRow from "./RequestTableRow"

type AllTasksTableType = {
    orderrequest?: OrderRequestType[]
    handleUpdateOrderRequestState: (e: string, f: string) => void
}

export default function OrderRequestTable(props: AllTasksTableType) {
    const orderRequestTableColumns: ColumnType[] = [
        {
            label: "Aufgabe",
        },
        {
            label: "Objekt",
        },
        {
            label: "Erstellt am",
        },
        {
            label: "Fällig am",
        },
        {
            label: "Preisrahmen",
        },
        {
            label: "Status",
        },
        {
            label: "",
        },
    ]

    return (
        <BaseTable
            columns={orderRequestTableColumns}
            tbody={props.orderrequest?.map((item) => (
                <RequestTableRow request={item} handleUpdateOrderRequestState={props.handleUpdateOrderRequestState} />
            ))}
        />
    )
}
