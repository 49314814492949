import { OfferTableRowType } from "../../types"
import dayjs from "dayjs"
import { ReactComponent as WARNING } from "../../../../assets/WarningICON.svg"
import BaseTableRow from "../../../../components/layout/BaseTableRow"
import { Tag } from "antd"

export default function OfferTableRow({ id, title, customer, status, date, amount, archived, object }: OfferTableRowType) {
    return (
        <BaseTableRow url={"/buchhaltung/angebote/" + id} classProps={archived ? "opacity-40 bg-gray-100" : ""}>
            <td>
                <div
                    className={`h-3 w-3 rounded-full ml-1 ${
                        status === "settled"
                            ? "bg-green-500"
                            : status === "rejected"
                            ? "bg-red-500"
                            : status === "canceled"
                            ? "bg-gray-400"
                            : "bg-yellow-400"
                    }`}></div>
            </td>
            <td className="hidden sm:table-cell">
                <p className="line-clamp-1  text-center">{dayjs(date).format("DD.MM.YYYY")}</p>
            </td>
            <td>
                <p title={title} className="line-clamp-1  text-center">
                    {title}
                </p>
            </td>
            <td>
                <p className="line-clamp-1  text-center">{amount.replace(".", ",")} €</p>
            </td>
            <td className="hidden md:table-cell">
                <p title={customer?.name} className="line-clamp-1  flex justify-center">
                    {customer?.softDeleteAfter && (
                        <span className="pl-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {customer?.name}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p
                    title={object?.adress?.street + " " + object?.adress?.nr + ", " + object?.adress?.plz + " " + object?.adress?.city}
                    className="line-clamp-1  flex justify-center">
                    {object.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {object?.adress?.street} {object?.adress?.nr}, {object?.adress?.plz} {object?.adress?.city}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-sm text-center">
                    {!archived ? (
                        status === "settled" ? (
                            <Tag color="green-inverse" className="w-24 text-center">
                                Angenommen
                            </Tag>
                        ) : status === "rejected" ? (
                            <Tag color="red" className="w-24 text-center">
                                Abgelehnt
                            </Tag>
                        ) : status === "unanswered" ? (
                            <Tag color="gold" className="w-24 text-center">
                                Ausstehend
                            </Tag>
                        ) : (
                            <Tag className="w-24 text-center">Storniert</Tag>
                        )
                    ) : (
                        <Tag color="gray" className="w-24 text-center">
                            Archiviert
                        </Tag>
                    )}
                </p>
            </td>
        </BaseTableRow>
    )
}
