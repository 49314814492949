import { ReactNode } from "react"

export type ColumnType = {
    label: string
    responsive?: string
    classProps?: string
    textDirection?: string
    width?: string
}

type BaseTableType = {
    columns: ColumnType[]
    tbody: ReactNode
}

export default function BaseTable(props: BaseTableType) {
    return (
        <table className="table table-md table-pin-rows bg-white shadow-lg">
            <thead>
                <tr className=" h-12 bg-gray-100 border-none text-sm ">
                    {props.columns.map((item) => (
                        <th
                            className={`"truncate ${item.responsive ?? "table-cell"} ${item.width ?? "w-fit"} ${
                                item.textDirection ?? "text-center"
                            }`}>
                            {item.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="overflow-y-scroll">{props.tbody}</tbody>
        </table>
    )
}
