import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useDelayStatistics } from "../../../services/functions"
import { getFinanceStatistics } from "../api/apiCalls"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import dayjs from "dayjs"
import Loading from "../../../components/common/Loading"
import ValueList from "../components/Charts/ValueLists"
import ReminderPaymentCountChart from "../components/Charts/ReminderPaymentCountChart"
import PaymentStatisticsChart from "../components/Charts/PaymentStatisticsChart"
import CostCenterSalesChart from "../components/Charts/CostCenterSalesChart"
import BillTypeChart from "../components/Charts/BillTypeChart"
import FiveObjectsOrCustomerChart from "../components/Charts/FiveObjectsOrCustomerChart"

export default function Dashboard() {
    // STATISTIC FOR NOW
    const [startDate, setstartDate] = useState<string>(dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD"))
    const [endDate, setendDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
    const delayed = useDelayStatistics(startDate, endDate)
    const { data: paymentStatistics, isLoading: paymentStatisticsLoading } = useQuery({
        queryKey: ["statistics", delayed.date1, delayed.date2],
        queryFn: () =>
            getFinanceStatistics({
                monthStart: dayjs(delayed.date1).toDate(),
                monthEnd: dayjs(delayed.date2).toDate(),
            }),
    })
    console.log(paymentStatistics)
    const key = `payreq_requested_from_to_${startDate}_${endDate}`

    return (
        <BasePage>
            <HeadBanner title="Dashboard" />
            {paymentStatisticsLoading && <Loading />}
            {!paymentStatisticsLoading && paymentStatistics && (
                <BaseGrowBody classProps="overflow-y-scroll overflow-x-hidden h-full ">
                    <div className="w-full flex gap-4 py-4 min-h-fit">
                        {paymentStatistics && <PaymentStatisticsChart paymentStatistics={paymentStatistics[key]} />}
                        <div className="w-1/3 flex flex-col gap-4 h-fit">
                            {paymentStatistics && <ReminderPaymentCountChart paymentStatistics={paymentStatistics[key]} />}
                            {paymentStatistics && <BillTypeChart paymentStatistics={paymentStatistics[key]} />}
                        </div>
                    </div>
                    <div className="w-full flex flex-row gap-4 pb-4 min-h-0 ">
                        {paymentStatistics && <CostCenterSalesChart paymentStatistics={paymentStatistics.cost_center} />}
                        {paymentStatistics && (
                            <FiveObjectsOrCustomerChart
                                statistics={paymentStatistics.customer_highest_sales_volume}
                                collection="customer"
                                type="high"
                            />
                        )}
                        {paymentStatistics && (
                            <FiveObjectsOrCustomerChart
                                statistics={paymentStatistics.customer_lowest_sales_volume.reverse()}
                                collection="customer"
                                type="low"
                            />
                        )}
                    </div>
                    <div className="w-full flex flex-row gap-4 pb-4 min-h-0 ">
                        <ValueList label={"Verhältnisse (365 Tage)"} ul={paymentStatistics.reminder_rate_last_year} />
                        {paymentStatistics && (
                            <FiveObjectsOrCustomerChart
                                statistics={paymentStatistics.objects_highest_sales_volume}
                                collection="objects"
                                type="high"
                            />
                        )}
                        {paymentStatistics && (
                            <FiveObjectsOrCustomerChart
                                statistics={paymentStatistics.objects_lowest_sales_volume.reverse()}
                                collection="objects"
                                type="low"
                            />
                        )}
                    </div>
                </BaseGrowBody>
            )}
        </BasePage>
    )
}
