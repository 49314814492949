import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import BaseSelection from "../../../../components/elements/BaseSelection"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import { Form, FormInstance } from "antd"
import { useEffect } from "react"
import { BillPost } from "../../../../data/Types"
import { Customer } from "../../../Kunden/types/Types"
import { ObjectType } from "../../../Objekte/types/types"
import { InputObject } from "../../../../components/types"

type BillModalScreen1Type = {
    form: FormInstance // TODO
    visible: boolean
    newBill: BillPost
    setnewBill: (e: any) => void // TODO
    customerArray: Customer[]
    objectArray?: ObjectType[]
    clearInputDropDown?: boolean
    searchTerm: string
    setsearchTerm: (e: string) => void
    isLoadingCustomer: boolean
    errorCustomer: Error | null
}

export default function BillModalScreen1(props: BillModalScreen1Type) {
    // FE LOGIC
    useEffect(() => {
        props.setnewBill({
            date: new Date(),
            billType: "Rechnung",
            billCategorie: "Hausmeister",
            billDay: 28,
            billDue: 28,
            manualReminderEnabled: false,
        })
        props.form.setFieldsValue({
            customer_id: props.newBill?.customer_id ?? undefined,
            object_id: props.newBill?.object_id ?? undefined,
            billType: props.newBill?.billType ?? "Rechnung",
            billCategorie: props.newBill?.billCategorie ?? "Hausmeister",
            billDue: props.newBill?.billDue ?? 28,
            billDay: props.newBill?.billDay ?? 28,
            manualReminderEnabled: props.newBill?.manualReminderEnabled ?? false,
            reminderStartOffsetInDays: props.newBill?.reminderStartOffsetInDays ?? undefined,
            reminderIntervalInDays: props.newBill?.reminderIntervalInDays ?? undefined,
            maxReminders: props.newBill?.maxReminders ?? undefined,
            additionalText: props.newBill?.additionalText ?? undefined,
            serviceDate: props.newBill?.serviceDate ?? undefined,
            forceFirstPayreqSameMonth: props.newBill?.forceFirstPayreqSameMonth ?? undefined,
        })
    }, [])

    return (
        <Form form={props.form}>
            <motion.div
                initial={{
                    x: 0,
                }}
                animate={{
                    x: props.visible ? 0 : "-100%",
                }}
                transition={{
                    type: "ease",
                }}
                className="absolute top-0 left-0 w-full h-full overflow-x-hidden overflow-y-scroll pb-4 pl-2">
                <div className="flex gap-4 w-full">
                    <Form.Item className="mt-4 mb-2 w-full" name={"billType"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Rechnungstyp *"}
                            placeholder={""}
                            data={["Rechnung", "Jahresrechnung", "Dauerrechnung"]}
                            setId={(e: string) => {
                                props.form.setFieldsValue({ billType: e })
                                props.setnewBill((prev: BillPost) => ({
                                    ...prev,
                                    billType: e,
                                }))
                            }}
                        />
                    </Form.Item>
                    <Form.Item className="mt-4 mb-2 w-64" name={"billCategorie"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Kategorie"}
                            placeholder={""}
                            data={["Hausmeister", "Schlüsseldienst", "Bau", "Vermietung", "Sonstiges"]}
                            setId={(e: string) => {
                                props.form.setFieldsValue({
                                    billCategorie: e,
                                })
                                props.setnewBill((prev: BillPost) => ({
                                    ...prev,
                                    billCategorie: e,
                                }))
                            }}
                            defaultValue="Hausmeister"
                        />
                    </Form.Item>
                </div>
                <Form.Item className="mb-2" name={"customer_id"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputSelection
                        setId={(e) => {
                            props.form.setFieldsValue({ customer_id: e })
                            props.setnewBill((prev: BillPost) => ({
                                ...prev,
                                customer_id: e,
                            }))
                        }}
                        label={"Kunde *"}
                        placeholder={"Kunde auswählen"}
                        data={props.customerArray as InputObject[]}
                        onSearch={(e: string) => props.setsearchTerm(e)}
                        customLoading={props.isLoadingCustomer}
                        customError={props.errorCustomer}
                    />
                </Form.Item>
                {props.newBill?.billCategorie !== "Sonstiges" && (
                    <Form.Item className="mb-2" name={"object_id"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseInputSelection
                            setId={(e) => {
                                props.form.setFieldsValue({ object_id: e })
                                props.setnewBill((prev: BillPost) => ({
                                    ...prev,
                                    object_id: e,
                                }))
                            }}
                            label={"Objekt *"}
                            placeholder={"Objekt auswählen"}
                            data={(props.objectArray ?? []) as InputObject[]}
                            disabled={props.objectArray?.length === 0 || !props.objectArray}
                        />
                    </Form.Item>
                )}
                {props.newBill?.customer_id !== "" && props.objectArray?.length === 0 && (
                    <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>
                )}
                <Form.Item className="mb-2" name={"billDue"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        label={"Rechnungsfrist in Tagen *"}
                        placeholder={""}
                        data={["7", "14", "28"]}
                        setId={(e: string) => {
                            props.form.setFieldsValue({ billDue: Number(e) })
                            props.setnewBill((prev: BillPost) => ({
                                ...prev,
                                billDue: Number(e),
                            }))
                        }}
                    />
                </Form.Item>
                {props.newBill?.billType !== "Rechnung" && (
                    <Form.Item className="mb-2" name={"billDay"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Zahltag des Monats *"}
                            placeholder={""}
                            data={["1", "15", "28"]}
                            setId={(e: string) => {
                                props.form.setFieldsValue({ billDay: Number(e) })
                                props.setnewBill((prev: BillPost) => ({
                                    ...prev,
                                    billDay: Number(e),
                                }))
                            }}
                        />
                    </Form.Item>
                )}
                <Form.Item className="mb-2" name={"serviceDate"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputField
                        label={"Leistungsdatum *"}
                        type={"date"}
                        onChange={(e) =>
                            props.setnewBill((prev: BillPost) => ({
                                ...prev,
                                serviceDate: e,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"additionalText"} rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputArea
                        classProps="mt-2"
                        label={"Zusätzlicher Text auf der Rechnung (optional)"}
                        onChange={(e) =>
                            props.setnewBill((prev: BillPost) => ({
                                ...prev,
                                additionalText: e,
                            }))
                        }
                    />
                </Form.Item>
                <div className={`flex gap-4 justify-evenly ${props.newBill?.billType === "Rechnung" && "justify-center"}`}>
                    {props.newBill?.billType !== "Rechnung" && (
                        <Form.Item
                            className="mt-4 mb-2"
                            name={"forceFirstPayreqSameMonth"}
                            rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                            <BaseCheckbox
                                setcheck={(e) =>
                                    props.setnewBill((prev: BillPost) => ({
                                        ...prev,
                                        forceFirstPayreqSameMonth: e,
                                    }))
                                }
                                classProps="justify-start"
                                label={"Vergangene Zahlungsanforderungen für diesen Monat nachträglich erstellen"}
                            />
                        </Form.Item>
                    )}
                    <Form.Item className="mb-2 mt-4" name="manualReminderEnabled" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseCheckbox
                            prevent={!props.newBill?.manualReminderEnabled}
                            setcheck={(e) =>
                                props.setnewBill((prev: BillPost) => ({
                                    ...prev,
                                    manualReminderEnabled: !props.newBill.manualReminderEnabled,
                                }))
                            }
                            classProps="justify-start"
                            label={"Mahnungsfristen manuell setzen"}
                        />
                    </Form.Item>
                </div>
                {props.newBill?.manualReminderEnabled && (
                    <div className="flex w-full gap-4">
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderStartOffsetInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Nach wieviel Tagen wird gemahnt?"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBill((prev: BillPost) => ({
                                        ...prev,
                                        reminderStartOffsetInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderIntervalInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"In welchem Abstand wird gemahnt? (in Tagen)"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBill((prev: BillPost) => ({
                                        ...prev,
                                        reminderIntervalInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"maxReminders"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Wieviele Mahnungen werden verschickt?"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBill((prev: BillPost) => ({
                                        ...prev,
                                        maxReminders: Number(e),
                                    }))
                                }
                                prevent
                            />
                        </Form.Item>
                    </div>
                )}
            </motion.div>
        </Form>
    )
}
