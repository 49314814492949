import dayjs from "dayjs"
import { TransactionRowType } from "../../../types"
import { formatCurrency } from "../../../../../services/functions"
import { ReactComponent as DELETE } from "../../../../../assets/DeleteICON.svg"
import { ReactComponent as INFO } from "../../../../../assets/InfoICON.svg"
import { ReactComponent as CLOSE } from "../../../../../assets/CloseICON.svg"
import { useState } from "react"

export function TransactionRow(props: TransactionRowType) {
    const [detailed, setdetailed] = useState<boolean>(false)
    return (
        <div className="flex flex-col justify-center relative">
            <div className="flex flex-row items-center gap-8 py-1">
                <p className="text-sm w-36">
                    Betrag:{" "}
                    <span className="text-base font-medium pl-1">
                        {" "}
                        {formatCurrency(props?.transaction?.paymentInformation?.transactionAmount?.amount?.valueOf() ?? 0)}
                    </span>
                </p>
                <p className="text-sm w-36">
                    Datum: <span className="text-base font-medium pl-1"> {dayjs(props?.transaction?.bookingTimestamp).format("DD.MM.YYYY")}</span>
                </p>
                {!detailed && (
                    <p className="text-sm truncate w-[28rem]">
                        Verwendungszweck:{" "}
                        <span className="text-base font-medium pl-2"> {props?.transaction?.paymentInformation?.remittanceText}</span>
                    </p>
                )}
            </div>
            {detailed && (
                <>
                    <p className="text-sm w-4/5">
                        <span className="underline">Verwendungszweck:</span>
                        <br />
                        <span className="text-sm pl-2"> {props?.transaction?.paymentInformation?.remittanceText}</span>
                    </p>
                    <p className="text-sm w-4/5">
                        <span className="underline">Beteiligte:</span> <br />
                        {props.transaction?.paymentInformation.counterpartNames.map((item: string) => (
                            <span className="text-sm pl-2">
                                {item}
                                <br />
                            </span>
                        ))}
                    </p>
                </>
            )}
            {detailed && <CLOSE className="w-6 h-6 absolute right-10 cursor-pointer" onClick={() => setdetailed(false)} />}
            {!detailed && <INFO className="w-6 h-6 absolute right-10 cursor-pointer" onClick={() => setdetailed(true)} />}
            <DELETE className="w-6 h-6 absolute right-0 cursor-pointer" onClick={props.remove} />
        </div>
    )
}
