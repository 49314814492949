import { motion } from "framer-motion"
import { useState } from "react"
import { AssignBillElementType } from "../../../types"
import { defaultTransactionStatus } from "../../../../../data/defaultValues"
import { formatCurrency } from "../../../../../services/functions"

export function AssignBillElement(props: AssignBillElementType) {
    const [check, setcheck] = useState(false)

    return (
        <div className="w-full relative h-fit">
            <motion.div
                initial={{
                    height: "2.5rem",
                    backgroundColor: "#fff",
                }}
                animate={{
                    height: check ? "9rem" : "2.5rem",
                    backgroundColor: check ? "#f2f5f8" : "#fff",
                }}
                transition={{
                    duration: 0.1,
                    type: "ease",
                }}
                className="z-10  flex flex-col w-full px-4 relative border-b cursor-pointer bg-white"
                onClick={() => setcheck(!check)}>
                <div className="flex flex-row gap-2 items-center py-2 relative hover:bg-gray-100">
                    <div className="w-14 text-xs">{props.date}</div>
                    <div
                        className={`w-32 text-right font-bold ${
                            props.information.processingStatus === defaultTransactionStatus.ASSIGNED
                                ? "text-green-600"
                                : props.information.processingStatus === defaultTransactionStatus.IGNORE
                                ? "text-gray-400"
                                : "text-black"
                        }`}>
                        {formatCurrency(props.information.paymentInformation?.transactionAmount?.amount?.valueOf() ?? 0)}
                    </div>
                    <div className={`w-full pl-2 pr-8 ${check ? "text-gray-100" : "text-gray-400"}`}>
                        <p className="line-clamp-1">{props.information.paymentInformation?.remittanceText}</p>
                    </div>
                    <div className="w-full absolute top-12 flex flex-row gap-2 text-sm">
                        <p className="w-40">
                            <span className="underline">Beteiligte:</span>
                            {props.information.paymentInformation.counterpartNames.map((item: string) => (
                                <span>
                                    <br />
                                    {item}
                                </span>
                            ))}
                        </p>
                        <p className="px-8">
                            <span className="underline">Verwendungszweck:</span> <br />
                            {props.information.paymentInformation.remittanceText}
                        </p>
                    </div>
                    {check && (
                        <>
                            <button
                                className="btn btn-primary btn-outline btn-sm absolute bottom-0 right-8"
                                disabled={props.alreadyAssignedToPayreq}
                                onClick={() => {
                                    props.ignoreTransaction()
                                    setcheck(false)
                                }}
                                title="Rechnung als Bezahlt markieren">
                                Zahlung ignorieren
                            </button>
                            {props.alreadyAssignedToPayreq ? (
                                <button
                                    className="btn btn-primary btn-sm absolute bottom-0 right-56"
                                    disabled={!props.alreadyAssignedToPayreq}
                                    onClick={() => {
                                        props.unassignTransaction()
                                        setcheck(false)
                                    }}
                                    title="Rechnung als Bezahlt markieren">
                                    Zuordnung entfernen
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm absolute bottom-0 right-56"
                                    disabled={props.alreadyAssignedToPayreq}
                                    onClick={() => {
                                        props.assignTransaction()
                                        setcheck(false)
                                    }}
                                    title="Rechnung als Bezahlt markieren">
                                    Zuordnen
                                </button>
                            )}
                        </>
                    )}
                </div>
            </motion.div>
        </div>
    )
}
