import { useContext, useEffect, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { getCustomerObjectData, getSingleCustomerData } from "../api/apiCalls"
import { useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { Customer, CustomerUpdate } from "../types/Types"
import {
    useAddOrUpdateCustomerPortalAccessMutation,
    useAddDocToCustomerMutation,
    usePatchDocCustomerMutation,
    useUpdateCustomerMutation,
    useRemoveCustomerPortalAccessMutation,
} from "../api/services"
import { formatCurrency } from "../../../services/functions"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import { useDownloadDocumentMutation } from "../../api/services"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseAddDocumentModal from "../../../components/elements/BaseAddDocumentModal"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import CustomerDetailPartLeft from "./customerDetail/CustomerDetailPartLeft"
import CustomerDetailPartRight from "./customerDetail/CustomerDetailPartRight"
import BasePreview from "../../../components/elements/BasePreview"
import { useResendMailMutation } from "../../../services/api/services"
import { API } from "../../../api/constants"
import { messageInfo } from "../../../services/messages"
import { Form } from "antd"
import AddCustomerPortalAccessModal from "../components/AddCustomerPortalAccessModal"
import { getCustomerStatistics } from "../../Dashboard/api/apiCalls"

export default function CustomerDetailPage() {
    // FE LOGIC
    const { id } = useParams()
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // GET SINGLE CUSTOMER DATA
    const queryKeySingleCustomer = "singleCustomerData" + id
    const { data, error, isLoading } = useQuery({
        queryKey: [queryKeySingleCustomer],
        queryFn: () => getSingleCustomerData(id ?? ""),
    })
    const [displayedCustomer, setdisplayedCustomer] = useState<Customer>()
    useEffect(() => {
        data && setdisplayedCustomer(data.customer)
    }, [data])

    // GET OBJECTS BY CUSTOMER
    const {
        data: dataCustomerObj,
        error: errorCustomerObj,
        isLoading: isloadingCustomerObj,
    } = useQuery({
        queryKey: ["singleCustomerObjData" + id],
        queryFn: () => getCustomerObjectData(id ?? ""),
    })

    // GET CUSTOMER STATITISTICS
    const {
        data: customerStatistic,
        error: errorCustomerStatistic,
        isLoading: isloadingCustomerStatistic,
    } = useQuery({
        queryKey: ["customerStatistic" + id],
        queryFn: () => getCustomerStatistics(id ?? ""),
    })

    // add file
    const { mutate: addDoc } = useAddDocToCustomerMutation(queryClient)

    // download file from customer
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: data.customer._id, s3Key: s3Key, filetype: "customer", filename: filename })
    }

    // delete file
    const [fileToDelete, setfileToDelete] = useState<string>("")
    const { mutate: deleteDoc } = usePatchDocCustomerMutation(queryClient, modalCon, 1)
    const handleDeleteDoc = () => {
        if (data.softDeleteAfter) {
            messageInfo(
                "1a2b3c",
                "Das klappt gerade nicht!",
                'Dieser Kunden ist als "gelöscht" markiert und kann deswegen nicht mehr bearbeitet werden. Stellen Sie den Kunden erst wieder her, bevor zu ihn bearbeiten.',
                10
            )
        } else {
            deleteDoc({
                id: id ?? "",
                s3Key: fileToDelete,
            })
        }
    }

    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const customerDocDownloadUrl = useMemo(() => {
        return error || isLoading || !selectedS3Key ? undefined : API + `/customer/${data?.customer._id}/download?s3Key=${selectedS3Key}`
    }, [data, error, isLoading, selectedS3Key])
    // copy preview data to avoid double using arrayBuffer

    // update selected customer
    const { mutate: updateCustomerQuery } = useUpdateCustomerMutation(queryClient)
    const handleUpdateCustomer = () => {
        if (data.customer.softDeleteAfter) {
            messageInfo(
                "1a2b3c",
                "Das klappt gerade nicht!",
                'Dieser Kunden ist als "gelöscht" markiert und kann deswegen nicht mehr bearbeitet werden. Stellen Sie den Kunden erst wieder her, bevor zu ihn bearbeiten.',
                10
            )
        } else {
            updateCustomerQuery({
                id: id ?? "",
                body: displayedCustomer as CustomerUpdate,
            })
        }
    }

    // add customer portal access
    const [mail, setmail] = useState<string>()
    const [type, settype] = useState<"customer" | "administrator">()
    const { mutate: addCustomerPortalAccess } = useAddOrUpdateCustomerPortalAccessMutation(queryClient, queryKeySingleCustomer)
    const handleAddCustomerPortalAccess = () => {
        if (type) {
            addCustomerPortalAccess({
                id: id ?? "",
                body: {
                    type: type,
                    mail: mail,
                },
            })
            modalCon?.trigger(5)
        }
    }

    // remove customer portal access
    const { mutate: removeCustomerPortalAccess } = useRemoveCustomerPortalAccessMutation(queryClient, queryKeySingleCustomer)
    const handleRemovePortalAccess = (type: "customer" | "administrator") => {
        removeCustomerPortalAccess({ id: id ?? "", type: type })
    }

    // mail Preview for SidePanel
    const [mailPreviewContent, setMailPreviewContent] = useState<string>()
    const [resendUri, setResendUri] = useState<string>()
    const { mutate: resendMailFunction } = useResendMailMutation()

    // FE LOGIC
    const [form] = Form.useForm()

    return (
        <BasePage>
            <BaseHeadBanner
                title={
                    "Kunde " +
                    (displayedCustomer?.customer?.name ?? data?.customer?.customer?.name) +
                    " | Nummer: " +
                    (data?.customer?.debtorIdentifier ?? "#404") +
                    " | Saldo: " +
                    (formatCurrency(data?.customer?.saldo) ?? "#404")
                }
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                        <div className="hidden md:flex flex-row w-fit items-center gap-2">
                            <input
                                type="radio"
                                name="radio-5"
                                value={1}
                                className="radio radio-success"
                                checked={(displayedCustomer?.ranking ?? data?.customer?.ranking) === 1}
                                onChange={() =>
                                    setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        ranking: 1,
                                    }))
                                }
                            />
                            <input
                                type="radio"
                                name="radio-5"
                                value={2}
                                className="radio radio-warning"
                                checked={(displayedCustomer?.ranking ?? data?.customer?.ranking) === 2}
                                onChange={() =>
                                    setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        ranking: 2,
                                    }))
                                }
                            />
                            <input
                                type="radio"
                                name="radio-5"
                                value={3}
                                className="radio radio-error"
                                checked={(displayedCustomer?.ranking ?? data?.customer?.ranking) === 3}
                                onChange={() =>
                                    setdisplayedCustomer((prev: any) => ({
                                        ...prev,
                                        ranking: 3,
                                    }))
                                }
                            />
                            <Form.Item>
                                <button
                                    disabled={data?.customer === displayedCustomer}
                                    className="btn btn-success ml-4"
                                    type="submit"
                                    onClick={() => form.submit()}>
                                    Speichern
                                </button>
                            </Form.Item>
                        </div>
                    </RenderWhenAuthorized>
                }
            />
            {(error || errorCustomerObj) && <ErrorPage />}
            {(isLoading || isloadingCustomerObj) && <Loading />}
            {data && dataCustomerObj && displayedCustomer && (
                <>
                    <section className="flex flex-col lg:flex-row w-full gap-4 py-4 overflow-y-scroll">
                        {/* LEFT */}
                        <Form form={form} className="w-full" onFinish={handleUpdateCustomer}>
                            <CustomerDetailPartLeft
                                form={form}
                                displayedCustomer={displayedCustomer}
                                setdisplayedCustomer={setdisplayedCustomer}
                                setfileToDelete={setfileToDelete}
                                handleDownload={handleDownload}
                                handleUpdateCustomer={handleUpdateCustomer}
                                setselectedS3Key={setselectedS3Key}
                                mailReports={data.customer.mailReports}
                                setResendUri={setResendUri}
                                setMailPreviewContent={setMailPreviewContent}
                                setmail={setmail}
                                mail={mail}
                                openAddAccessModal={(e) => {
                                    settype(e.type)
                                    setmail(e.mail)
                                    modalCon?.trigger(5)
                                }}
                                removePortalAccess={handleRemovePortalAccess}
                            />
                        </Form>
                        {/* RIGHT */}
                        <CustomerDetailPartRight data={dataCustomerObj} statistics={customerStatistic} />
                    </section>
                    <ModalCheckAction
                        modalId={1}
                        headline={"Dokument löschen?"}
                        text={"Sind Sie sich sicher, dass Sie dieses Dokument wollen?"}
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={handleDeleteDoc}
                    />
                    <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(2)} modalId={2} selectedId={id ?? ""} />
                    <BasePreview title={"document"} sidePanelId={3} previewType={"document"} content={customerDocDownloadUrl} />
                    <BasePreview
                        title={"Mailvorschau"}
                        sidePanelId={4}
                        previewType={"mail"}
                        content={mailPreviewContent ?? ""}
                        resendFunction={() => resendMailFunction({ path: resendUri ?? "" })}
                    />
                    <AddCustomerPortalAccessModal
                        modalId={5}
                        mail={mail ?? ""}
                        setmail={setmail}
                        submit={handleAddCustomerPortalAccess}
                        close={() => {
                            setmail(undefined)
                            settype(undefined)
                        }}
                    />
                </>
            )}
        </BasePage>
    )
}
