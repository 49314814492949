import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useEffect, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useNavigate } from "react-router-dom"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { PR } from "../../../data/Types"
import PaymentTableRow from "../components/payment/PaymentTableRow"
import RecheckPaymentModal from "../components/payment/paymentRecheckModal/RecheckPaymentModal"
import SolveEscaltionModal from "../components/payment/solveEscaltion/SolveEscaltionModal"
import { useMarkPayreqAsPaidManual, useMarkPayreqAsUnpaidManual, usePauseReminderMutation, useSolveEscalationMutation } from "../api/services"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as OFFER } from "../../../assets/OfferIcon.svg"
import { ReactComponent as BILL } from "../../../assets/BillIcon.svg"
import { ReactComponent as PAYMENT } from "../../../assets/PaymentIcon.svg"
import { ReactComponent as ARROWUP } from "../../../assets/ArrowUpIcon.svg"
import { ReactComponent as ARROWDOWN } from "../../../assets/ArrowDownIcon.svg"
import { ReactComponent as BILLTEMPLATE } from "../../../assets/BillTemplate.svg"
import BaseButton from "../../../components/elements/BaseButton"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import BaseTable, { ColumnType } from "../../../components/elements/BaseTable"
import PauseReminderModal from "../components/payment/PauseReminderModal"

export default function PaymentPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const [status, setstatus] = useState("")
    const [invoiceStartDate, setinvoiceStartDate] = useState("")
    const [invoiceEndDate, setinvoiceEndDate] = useState("")
    const [invertSort, setinvertSort] = useState(false)
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    // Get Data Query
    const queryKeyPayreqs = "paymentsData" + page + searchParams + status + invoiceStartDate + invoiceEndDate + invertSort
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKeyPayreqs],
        queryFn: () =>
            getPaginatedData({
                pageType: "paymentrequirements",
                page: page,
                limit: 15,
                search: searchParams,
                status: status,
                startDate: invoiceStartDate,
                endDate: invoiceEndDate,
                invertSort: invertSort,
            }),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [payment, setpayment] = useState<PR>()

    useEffect(() => {
        if (payment) {
            setpayment(data?.docs.find((item: { _id: string }) => item._id === payment._id))
        }
    }, [data?.docs])

    // NOTE -> used in work with Filter Modal
    const [showFilter, setshowFilter] = useState(false)

    const queryClient = useQueryClient()
    const [solvingId, setSolvingId] = useState("")
    const { mutate: solveEscalationFunction } = useSolveEscalationMutation(queryClient, modalCon, 1, queryKeyPayreqs)
    const solveEscalation = () => {
        solveEscalationFunction(solvingId)
    }

    const handleRemoveFilter = () => {
        setsearchTerm("")
        setstatus("")
        setinvoiceEndDate("")
        setinvoiceStartDate("")
    }

    const displayedPayReqs = useMemo(() => {
        if (data?.docs) {
            return data.docs
        }
    }, [data?.docs])

    const { mutate: markAsPaid } = useMarkPayreqAsPaidManual(queryClient, queryKeyPayreqs)
    const { mutate: markAsUnpaid } = useMarkPayreqAsUnpaidManual(queryClient, queryKeyPayreqs)

    // PAUSE SENDING PAYMENT REMINDER
    const { mutate: setPauseReminder } = usePauseReminderMutation(queryClient, queryKeyPayreqs)

    // FE LOGIC
    const paymentTableColumns: ColumnType[] = [
        {
            label: "Fälligkeitsdatum",
            responsive: "hidden lg:table-cell",
            width: "min-w-[10rem] w-fit",
        },
        {
            label: "Erstellungsdatum",
            responsive: "hidden lg:table-cell",
            width: "min-w-[10rem] w-fit",
        },
        {
            label: "Nächste Mahnung",
            responsive: "hidden lg:table-cell",
            width: "min-w-[10rem] w-fit",
        },
        {
            label: window.innerWidth > 640 ? "Rechnungsnummer" : "Nr.",
        },
        {
            label: "Netto",
            width: " sm:min-w-[8rem] w-fit",
        },
        {
            label: "Brutto",
            width: " sm:min-w-[8rem] w-fit",
        },
        {
            label: "Kunde",
            responsive: "hidden md:table-cell ",
        },
        {
            label: "Status",
            responsive: "hidden xl:table-cell ",
        },
        {
            label: "",
        },
        {
            label: "",
        },
    ]

    return (
        <BasePage>
            <HeadBanner title={"Zahlungsaufforderungen"} />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <BaseButtonRow>
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => setsearchTerm(e.target.value)}
                        type="text"
                        value={searchTerm ?? null}
                        placeholder="Rechnungsnummer / Kunde suchen..."
                        className="w-full sm:w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <BaseButton label={"Angebote"} func={() => navigate("/buchhaltung/angebote")} icon={<OFFER className="w-6 h-6" />} />
                    <BaseButton label={"Rechnungen"} func={() => navigate("/buchhaltung/rechnungen")} icon={<BILL className="w-6 h-6" />} />
                    <BaseButton
                        label={"Rechnungsvorlagen"}
                        func={() => navigate("/buchhaltung/rechnungsvorlagen")}
                        icon={<BILLTEMPLATE className="w-6 h-6" />}
                    />
                    <BaseButton label={"Zahlungen"} func={() => navigate("/buchhaltung/zahlungen")} icon={<PAYMENT className="w-6 h-6" />} disabled />
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setinvertSort(!invertSort)
                        }}>
                        {invertSort ? <ARROWUP className="w-4 h-4" /> : <ARROWDOWN className="w-4 h-4" />}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <BaseFilterModal
                        visible={showFilter}
                        searchdateSince={invoiceStartDate}
                        searchdateTo={invoiceEndDate}
                        changeDateSince={setinvoiceStartDate}
                        changeDateTo={setinvoiceEndDate}
                        searchStatus={status}
                        changeStatus={setstatus}
                        statusType={"payreq"}
                        removeFilter={handleRemoveFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                    />
                </div>
            </BaseButtonRow>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <BaseGrowBody classProps="overflow-x-hidden my-4">
                        <BaseTable
                            columns={paymentTableColumns}
                            tbody={displayedPayReqs?.map((item: PR) => (
                                <PaymentTableRow
                                    pausedReminder={item?.pauseNextReminderForDays}
                                    key={item?._id}
                                    id={item?.bill_id?._id}
                                    title={item?.bill_id?.nr}
                                    customer={item?.customer_id?.customer?.name}
                                    status={item?.status}
                                    invoice={item?.invoice_date}
                                    delivery={item?.due_date}
                                    nextReminder={item?.nextReminderDate as Date}
                                    netto={item?.totalNetto}
                                    brutto={item?.expected_payment}
                                    type={item?.bill_id?.billType}
                                    archived={false}
                                    escalationRequired={item?.escalationRequired}
                                    openSolveEscalationModal={() => {
                                        setSolvingId(item._id)
                                        modalCon?.trigger(1)
                                    }}
                                    modalOpen={() => {
                                        setpayment(item)
                                        modalCon?.trigger(2)
                                    }}
                                    markAsPaid={() => markAsPaid(item._id)}
                                    openPauseReminderModal={() => {
                                        setpayment(item)
                                        modalCon?.trigger(3)
                                    }}
                                />
                            ))}
                        />

                        {(!displayedPayReqs || displayedPayReqs.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erwarteten Zahlungen</p>
                        )}
                    </BaseGrowBody>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            current={page}
                            pageSize={15}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <SolveEscaltionModal modalId={1} solveEscalation={solveEscalation} />
            <RecheckPaymentModal
                modalId={2}
                payment={payment}
                refreshDataPayment={"paymentsData" + page + searchParams + status + invoiceStartDate + invoiceEndDate + invertSort}
                makePayreqUnpaid={() => markAsUnpaid(payment?._id ?? "")}
            />
            <PauseReminderModal modalId={3} id={payment?._id ?? ""} close={() => modalCon?.trigger(3)} setPauseReminder={setPauseReminder} />
        </BasePage>
    )
}
