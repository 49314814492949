import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { BillPost, BillTemplatePost, OfferPost } from "../../../../data/Types"
import { ReactComponent as PLUS } from "../../../../assets/PlusInCircleIcon.svg"
import { Form, FormInstance } from "antd"
import PositionInputs from "./modalComponents/PositionInputs"

type AddBillOrOfferModalScreen2Type = {
    visible: boolean
    newBillOrOffer?: BillPost | OfferPost | BillTemplatePost
    setnewBillOrOffer: (e: any) => void
    form: FormInstance
}

export default function AddBillOrOfferModalScreen2(props: AddBillOrOfferModalScreen2Type) {
    const [positioncount, setpositioncount] = useState<number>(1)

    const addPosition = () => {
        props.setnewBillOrOffer((prev: BillPost) => ({
            ...prev,
            positions: [
                ...prev.positions,
                {
                    position_id: (prev.positions.length + 1).toString(),
                    description: "",
                    tax: 19,
                    unit: "",
                    amount: 1,
                    singlePrice: 0,
                },
            ],
        }))
    }

    const removePosition = (indexToRemove: number) => {
        props.form.resetFields([
            "amount-" + indexToRemove,
            "unit-" + indexToRemove,
            "tax-" + indexToRemove,
            "description-" + indexToRemove,
            "singlePrice-" + indexToRemove,
        ])
        props.setnewBillOrOffer((prev: BillPost) => ({
            ...prev,
            positions: prev.positions.filter((_, index) => index !== indexToRemove),
        }))
    }

    const handleInputChange = (index: number, key: string, newValue: string | number) => {
        props.setnewBillOrOffer((prev: BillPost) => ({
            ...prev,
            positions: prev.positions.map((item, idx) => {
                if (idx === index) {
                    return { ...item, [key]: newValue }
                }
                return item
            }),
        }))
    }

    // FE LOGIC
    useEffect(() => {
        if (props.visible && !props.newBillOrOffer?.positions) {
            props.setnewBillOrOffer((prev: BillPost) => ({
                ...prev,
                positions: [
                    {
                        position_id: "1",
                        description: "",
                        tax: 19,
                        unit: "",
                        amount: 1,
                        singlePrice: 0,
                    },
                ],
            }))
        }
    }, [props.visible])

    return (
        <Form form={props.form}>
            <motion.div
                initial={{
                    x: "100%",
                }}
                animate={{
                    x: props.visible ? 0 : "100%",
                }}
                transition={{
                    type: "ease",
                }}
                className="w-full h-full z-20 absolute top-0 left-0 bg-white pt-2 overflow-y-scroll pb-12 pl-2">
                {props.newBillOrOffer?.positions?.map((item, index) => (
                    <PositionInputs
                        form={props.form}
                        position={item}
                        setposition={handleInputChange}
                        removePosition={removePosition}
                        index={index}
                        key={index}
                    />
                ))}
                <div
                    className="flex pl-4 text-sm items-center pt-2 gap-3 cursor-pointer"
                    onClick={() => {
                        addPosition()
                        setpositioncount(positioncount + 1)
                    }}>
                    <PLUS className="w-6 h-6" />
                    <p>Weitere Position hinzufügen</p>
                </div>
            </motion.div>
        </Form>
    )
}
