import "../../style/loading.css"

type LoadingType = {
    classProps?: string
}

export default function LoadingWithOverlay(props: LoadingType) {
    return (
        <section className={`h-full absolute top-0 left-0 w-full overflow-hidden bg-black/5  ${props.classProps}`}>
            <div className="loader"></div>
        </section>
    )
}
