import { Line } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js"
import { CustomChartType } from "../../types"
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)

export default function BillTypeChart(props: CustomChartType) {
    console.log(props.paymentStatistics)
    // Chart data
    const data = {
        labels: props.paymentStatistics?.map((item: any) => item.monthYear), // X-axis labels
        datasets: [
            {
                label: "Rechnung",
                data: props.paymentStatistics?.map((item: any) => item.billType.Rechnung),
                backgroundColor: "rgba(2, 163, 58, 0.6)", // Teal color
                borderColor: "rgba(2, 163, 58, 1)",
                pointRadius: 3,
                borderWidth: 1,
            },
            {
                label: "Jahresrechnung",
                data: props.paymentStatistics?.map((item: any) => item.billType.Jahresrechnung),
                backgroundColor: "rgba(5, 105, 168, 0.6)", // Teal color
                borderColor: "rgba(5, 105, 168, 1)",
                pointRadius: 3,
                borderWidth: 1,
            },
            {
                label: "Dauerrechnung",
                data: props.paymentStatistics?.map((item: any) => item.billType.Dauerrechnung),
                backgroundColor: "rgba(163, 15, 2, 0.6)", // Red color
                borderColor: "rgba(163, 15, 2, 1)",
                pointRadius: 3,
                borderWidth: 1,
            },
        ],
    }

    return (
        <div className="w-full grow bg-white rounded-default shadow-lg p-4 h-full">
            <Line
                className="grow h-full"
                data={data}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Zahlungsanforderungen nach Rechnungstyp",
                        },
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                }}
            />
        </div>
    )
}
