import { Popover } from "antd"
import { ReactNode } from "react"

type BasePopConfirmType = {
    label: string
    placement?:
        | "topLeft"
        | "top"
        | "topRight"
        | "bottomLeft"
        | "bottom"
        | "bottomRight"
        | "left"
        | "leftBottom"
        | "leftTop"
        | "right"
        | "rightBottom"
        | "rightTop"
    text?: string
    okButtonClick: () => void
    children: ReactNode
}

export default function BasePopover(props: BasePopConfirmType) {
    return (
        <Popover
            placement={props.placement}
            title={props.label}
            content={
                <div className="flex flex-col items-end max-w-xs">
                    <p>{props.text}</p>
                    <button onClick={props.okButtonClick} className="btn btn-sm btn-primary w-fit mt-0">
                        OK
                    </button>
                </div>
            }>
            {props.children}
        </Popover>
    )
}
