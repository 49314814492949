import dayjs from "dayjs"
import BaseTableRow from "../../../components/layout/BaseTableRow"
import { returnAddressString } from "../../../services/functions"
import { ObjectType } from "../../Objekte/types/types"
import { getSingleTaskTemplateType } from "../types"
import { Popover, Tag } from "antd"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as PLAY } from "../../../assets/Pause.svg"
import { ReactComponent as PAUSE } from "../../../assets/Play.svg"

type TaskTemplateTableRowType = {
    taskTemplate: getSingleTaskTemplateType
    deleteTask: (e: string) => void
    addOrPause?: (e: string) => void
}

export default function TaskTemplateTableRow(props: TaskTemplateTableRowType) {
    return (
        <BaseTableRow url={"/aufgaben-wiederkehrend/" + props.taskTemplate._id}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{props.taskTemplate.activity}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {typeof props.taskTemplate.object !== "string" && returnAddressString(props.taskTemplate.object as ObjectType)}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{dayjs(props.taskTemplate.createAt).format("DD.MM.YYYY")}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag className="w-20 text-center" color={props.taskTemplate.priority === "default" ? "blue" : "red-inverse"}>
                        {props.taskTemplate.priority === "default" ? "Standard" : "Hoch"}
                    </Tag>
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag
                        className="w-32 text-center"
                        color={props.taskTemplate.state === "active" ? "green" : props.taskTemplate.state === "paused" ? "" : "grey-inverse"}>
                        {props.taskTemplate.state === "active" ? "Aktiv" : props.taskTemplate.state === "paused" ? "Pausiert" : "Fertig"}
                    </Tag>
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag className="w-24 text-center">
                        {props.taskTemplate.recurrenceCreationPattern.interval +
                            "x pro " +
                            (props.taskTemplate.recurrenceCreationPattern.recurrenceType === "year"
                                ? "Jahr"
                                : props.taskTemplate.recurrenceCreationPattern.recurrenceType === "month"
                                ? "Monat"
                                : "Woche")}
                    </Tag>
                </p>
            </td>
            <td onClick={(event) => event.stopPropagation()}>
                {props.taskTemplate.state === "active" ? (
                    <PAUSE
                        className="w-6 h-6 cursor-pointer"
                        onClick={() => props.addOrPause !== undefined && props.addOrPause(props.taskTemplate._id)}
                    />
                ) : (
                    <PLAY
                        className="w-6 h-6 cursor-pointer"
                        onClick={() => props.addOrPause !== undefined && props.addOrPause(props.taskTemplate._id)}
                    />
                )}
            </td>
            <td onClick={(event) => event.stopPropagation()}>
                <Popover
                    title={"Wirklich Löschen?"}
                    content={
                        <div className="flex flex-col items-end">
                            <p>Wollen Sie diese Aufgabenvorlage wirklich löschen?</p>
                            <button onClick={() => props.deleteTask(props.taskTemplate._id)} className="btn btn-sm btn-primary w-fit mt-1">
                                OK
                            </button>
                        </div>
                    }>
                    <DELETE className="w-6 h-6 cursor-pointer" />
                </Popover>
            </td>
        </BaseTableRow>
    )
}
