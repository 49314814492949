import { Timeline } from "antd"
import { ReactNode, useMemo } from "react"
import { HistoryObjectType } from "../types"
import dayjs from "dayjs"
import { ReactComponent as DONE } from "../../../assets/CheckCircle.svg"
import { ReactComponent as CLOSE } from "../../../assets/CloseCircle.svg"
import { ReactComponent as MONEY } from "../../../assets/Cash.svg"
import { humanizeTaskActionCode } from "../../../services/functions"

type TaskDetailHistoryType = {
    history: HistoryObjectType[]
}

export default function TaskDetailHistory(props: TaskDetailHistoryType) {
    console.log(props.history)
    const timeSteps = useMemo<{ children: ReactNode }[]>(
        () =>
            props.history?.reverse()?.map((item) => ({
                color: item.actionCode === "complete" ? "green" : item.actionCode === "mark-as-execute-failed" ? "red" : "blue",
                dot:
                    item.actionCode === "complete" ? (
                        <DONE className="w-6 h-6" />
                    ) : item.actionCode === "mark-as-execute-failed" ? (
                        <CLOSE className="w-6 h-6" />
                    ) : item.actionCode === "billed" ? (
                        <MONEY className="w-6 h-6" />
                    ) : undefined,
                children: (
                    <div className="flex flex-col gap-1">
                        <h5 className="text-sm">
                            <span className={`font-semibold ${item.actionCode === "mark-as-execute-failed" && "text-error"}`}>
                                {humanizeTaskActionCode(item.actionCode)}
                            </span>
                            {item.employee && (
                                <>
                                    {" "}
                                    von{" "}
                                    <span className="font-semibold">
                                        {item.employee?.first_name} {item.employee?.last_name}
                                    </span>
                                </>
                            )}
                        </h5>
                        <p className="text-xs text-gray-400">
                            am {dayjs(item.timestamp).format("DD.MM.YYYY")} um {dayjs(item.timestamp).format("HH:mm")} Uhr
                        </p>
                    </div>
                ),
            })) || [],
        [props.history]
    )

    return (
        <div className="bg-white rounded-default p-4 shadow-lg grow min-h-[50%]">
            <h3 className="text-xl mb-4 font-bold border-b">History</h3>
            <Timeline items={timeSteps} />
        </div>
    )
}
