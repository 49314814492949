import { useNavigate } from "react-router-dom"
import { ObjectType } from "../../../Objekte/types/types"
import { ReactComponent as STATISTIC } from "../../../../assets/Statistics.svg"
import BaseStatisticCard from "../../../../components/elements/BaseStatisticCard"
import BaseSection from "../../../../components/elements/BaseSection"
import { returnThousanderBreakInNumber } from "../../../../services/functions"

type CustomerDetailPartRightType = {
    data: ObjectType[]
    statistics: {
        sales_volume: number
        payreq_count: number
        reminder_count: number
    } // TODO
}

export default function CustomerDetailPartRight(props: CustomerDetailPartRightType) {
    const navigate = useNavigate()
    return (
        <div className="w-full flex gap-4">
            {/* OBJECT-PART */}
            <div className="hidden lg:flex flex-col w-full bg-white p-4 rounded-default shadow-lg">
                <h3 className="text-sm">Objekte des Kunden</h3>
                <div className="h-full overflow-y-scroll w-full max-h-[42rem]">
                    {props.data?.length === 0 ? (
                        <p className="pt-2 text-sm italic">Dem Kunden sind noch keine Objekte zugeordnet</p>
                    ) : (
                        props.data?.map((item: ObjectType, index: number) => (
                            <div
                                key={index}
                                className="w-full border-b py-4 px-4 flex flex-col hover:bg-black/10 cursor-pointer rounded-default"
                                onClick={() => navigate("/objekte/" + item._id)}>
                                <p
                                    className="line-clamp-1 font-medium"
                                    title={item?.adress?.street + " " + item?.adress?.nr + ", " + item?.adress?.city + " " + item?.adress?.plz}>
                                    {item?.adress?.street + " " + item?.adress?.nr + ", " + item?.adress?.city + " " + item?.adress?.plz}
                                </p>
                                <div className="flex flex-col text-sm">
                                    <p>
                                        Offene Angebote: <span>{item.statistics?.offers.open}</span>
                                    </p>
                                    <p>
                                        Offene Zahlungsforderungen: <span>{item.statistics?.payreqs.open}</span>
                                    </p>
                                    <p>
                                        Gestellte Angebote: <span>{item.statistics?.offers.total}</span>
                                    </p>
                                    <p>
                                        Gestelle Rechnungen: <span>{item.statistics?.bills.total}</span>
                                    </p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            {/* STATISTICS */}
            <BaseSection noBackground>
                {props.statistics === null || props.statistics === undefined || !props.statistics ? (
                    <div className="flex flex-col justify-center items-center font-semibold h-full bg-white/50 rounded-default">
                        <STATISTIC className="w-8 h-8" />
                        <p className="text-center px-4">Für diesen Kunden wurden noch keine Daten erfasst.</p>
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-4">
                        <BaseStatisticCard
                            value={props.statistics.payreq_count?.toString()}
                            text={"Gesamtanzahl der Zahlungs- anforderungen der letzten 365 Tage"}
                        />
                        <BaseStatisticCard
                            value={props.statistics.reminder_count?.toString()}
                            text={"Gesamtanzahl der Mahnungen der letzten 365 Tage"}
                        />
                        <BaseStatisticCard
                            classPropsContainer="col-start-1 col-end-3"
                            value={returnThousanderBreakInNumber(props.statistics?.sales_volume) + " €"}
                            text={"Gesamtbetrag der Einnahmen der letzten 365 Tage"}
                        />
                    </div>
                )}
            </BaseSection>
        </div>
    )
}
