import BaseSection from "../../../../components/elements/BaseSection"
import { ReactComponent as STATISTIC } from "../../../../assets/Statistics.svg"
import BaseStatisticCard from "../../../../components/elements/BaseStatisticCard"
import { returnThousanderBreakInNumber } from "../../../../services/functions"

type ObjectDetailStatisticsType = {
    statistics: {
        sessionCount: number
        averageSessionTime: number
        averageSessionTimeVsEstimation: number
        sales_volume: number
        payreq_count: number
        reminder_count: number
    }
}

export default function ObjectDetailStatistics(props: ObjectDetailStatisticsType) {
    return (
        <BaseSection noBackground>
            {props.statistics === null || !props.statistics ? (
                <div className="flex flex-col justify-center items-center font-semibold h-full">
                    <STATISTIC className="w-8 h-8" />
                    <p>Für dieses Objekt wurden noch keine Daten erfasst.</p>
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-4">
                    <BaseStatisticCard
                        value={props.statistics.payreq_count?.toString()}
                        text={"Gesamtanzahl der Zahlungsanforderungen der letzten 365 Tage"}
                    />
                    <BaseStatisticCard value={props.statistics.reminder_count?.toString()} text={"Gesamtanzahl der Mahnungen der letzten 365 Tage"} />
                    <BaseStatisticCard
                        value={returnThousanderBreakInNumber(props.statistics.sales_volume) + " €"}
                        text={"Gesamtbetrag der Einnahmen der letzten 365 Tage"}
                    />
                    <BaseStatisticCard value={props.statistics.sessionCount?.toString()} text={"Anzahl der Termine der letzten 90 Tage"} />
                    <BaseStatisticCard
                        value={props.statistics.averageSessionTime?.toString() + " min"}
                        text={"Durchschnittliche Termindauer in den letzten 90 Tagen"}
                    />
                    <BaseStatisticCard
                        value={(props.statistics.averageSessionTimeVsEstimation * 100).toFixed(0).toString().replace(".", ",") + "%"}
                        text={"Durchschnittliche Abwweichung von geschätzter und tatsächlicher Termindauer in den letzten 90 Tagen"}
                    />
                </div>
            )}
        </BaseSection>
    )
}
