import { QueryClient, useMutation } from "@tanstack/react-query"
import {
    postNewOffer,
    statusOffer,
    archiveOffer,
    postNewBill,
    archiveBill,
    cancelBill,
    solvePaymentEscalation,
    refetchTransactions,
    markPayreqAsPaidManually,
    exportBillsFromTimeframe,
    markPayreqAsUnpaidManually,
    pausePaymentReminder,
    postNewBillTemplate,
    archiveBillTemplate,
    cancelBillTemplate,
} from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"
import { Dayjs } from "dayjs"

// ADD Offer
export const useAddOfferMutation = (queryClient: QueryClient, modalCon: any, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, any>({
        mutationFn: (variables) => postNewOffer(variables.body, variables.leistungsVerzeichnis),
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Angebot wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Angebot wurde erfolgreich erstellt.")
            modalCon?.trigger(0)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            modalCon?.trigger(0)
            messageError(notificationKey, "Fehler!", "Das Angebot konnte nicht erstellt werden.")
            console.log(err)
        },
    })

    return mutation
}

// UPDATE Offer Status
export const useUpdateStatusOfferMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: { status: string } }>({
        mutationFn: statusOffer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status des Angebots wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Status des Angebots wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Status des Angebots konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// UPDATE Offer Archive
export const useArchiveOfferMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveOffer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Angebot wird gerade archiviert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Das Angebot wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Angebot konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}

// ====================================================== BILL ======================================================  //

// ADD Bill
// NOTE is payreq already refreshing?
export const useAddBillMutation = (queryClient: QueryClient, refreshData: string, offer_id?: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, any>({
        mutationFn: postNewBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
            queryClient.invalidateQueries({
                queryKey: ["paymentsData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + offer_id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE Bill Archive
export const useArchiveBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade archiviert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}

// UPDATE Bill cancle
export const useCancelBillMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            cancelUnpaidPaymentRequirements: boolean
        }
    >({
        mutationFn: cancelBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade storniert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich storniert.")
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
            queryClient.invalidateQueries({
                queryKey: ["PaymentRequirementsSingleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht storniert werden.")
            console.log(err)
        },
    })

    return mutation
}

// ====================================================== PAYMENT REQUEST ======================================================  //
export const useSolveEscalationMutation = (queryClient: QueryClient, modalCon: any, modalId: number, refreshData: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, String>({
        mutationFn: solvePaymentEscalation,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Mahnungsprozess wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Mahnungsprozess wurde erfolgreich aktualisiert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Mahnungsprozess konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useRefetchTransactions = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error>({
        mutationFn: refetchTransactions,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Transaktionen werden neu abgefragt.")
        },
        onSuccess: (data: any, variables) => {
            messageSuccess(notificationKey, "Fertig!", (data.newTransactionCount ?? "Null") + " neue Transaktionen abgefragt.")
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Transaktionen konnten nicht neu abgefragt werden.")
            console.log(err)
        },
    })
    return mutation
}

// mark payment as paid manually
export const useMarkPayreqAsPaidManual = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: markPayreqAsPaidManually,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Zahlungsanforderung wird als beglichen markiert.")
        },
        onSuccess: (data: any, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Zahlungsanforderung wurde erfolgreich als beglichen markiert.")
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Zahlungsanforderung konnte nicht als beglichen markiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// mark payment as unpaid manually
export const useMarkPayreqAsUnpaidManual = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: markPayreqAsUnpaidManually,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", 'Zahlungsanforderung wird als "nicht beglichen" markiert.')
        },
        onSuccess: (data: any, variables) => {
            messageSuccess(notificationKey, "Fertig!", 'Zahlungsanforderung wurde erfolgreich als "nicht beglichen" markiert.')
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", 'Zahlungsanforderung konnte nicht als "nicht beglichen" markiert werden.')
            console.log(err)
        },
    })
    return mutation
}

// export all bills from timeframe
// NOTE currently unused
export const useExportBillsMutation = () => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { startDate: Dayjs; endDate: Dayjs }>({
        mutationFn: exportBillsFromTimeframe,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Erstellung der ZIP-Datei wird beauftragt.")
        },
        onSuccess: () => {
            messageSuccess(
                notificationKey,
                "Fertig!",
                "Die ZIP-Datei wird jetzt erstellt. Bitte laden Sie die Datei über den Link herunter, den Sie in wenigen Minuten auf Ihre E-Mail erhalten."
            )
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Erstellung der ZIP-Datei konnte nicht beauftragt werden.")
            console.log(err)
        },
    })
    return mutation
}

// PAUSE PAYMENT REQUIREMENTS REMINDER FOR X DAYS
export const usePauseReminderMutation = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { pid: string; days: number }>({
        mutationFn: pausePaymentReminder,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Mahnungen für die Zahlungsanforderungen werden pausiert...")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", `Kommende Mahnungen für die Zahlungen wurden um ${variables.days} Tage verzögert.`)
            queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Mahnungen konnten nicht verzögert werden.")
            console.log(err)
        },
    })
    return mutation
}

// ================================================================================================================== //
// ================================================= BILL TEMPLATE ================================================== //
// ================================================================================================================== //

// ADD Bill
export const useAddBillTemplateMutation = (queryClient: QueryClient, refreshData: string, offer_id?: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, FormData>({
        mutationFn: postNewBillTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnungsvorlage wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnungsvorlage wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
            queryClient.invalidateQueries({
                queryKey: ["paymentsData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + offer_id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnungsvorlage konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

// UPDATE Bill Archive
export const useArchiveBillTemplateMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, {id: string, archived: boolean}>({
        mutationFn: archiveBillTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnungsvorlage wird gerade archiviert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnungsvorlage wurde erfolgreich archiviert.")
            queryClient.invalidateQueries({
                queryKey: ["singleBillTemplate" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnungsvorlage konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}

// UPDATE Bill cancle
export const useCancelBillTemplateMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: cancelBillTemplate,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnungsvorlage wird gerade storniert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnungsvorlage wurde erfolgreich storniert.")
            queryClient.invalidateQueries({
                queryKey: ["singleBillTemplate" + variables],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnungsvorlage konnte nicht storniert werden.")
            console.log(err)
        },
    })

    return mutation
}
