import { useQuery } from "@tanstack/react-query"
import { getPaginatedData } from "../../api/apiCalls"

export const useQueryOrderRequest = (page: number, searchParams: string) => {
    return useQuery({
        queryKey: ["queryKeyOrderRequests", page, searchParams],
        queryFn: () =>
            getPaginatedData({
                pageType: "orderrequest",
                page: page,
                limit: 20,
                search: searchParams,
            }),
        enabled: !!page,
    })
}
