import BaseModal from "../../../../components/layout/BaseModal"
import { Form } from "antd"
import { useState } from "react"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { ReactComponent as CLOCK } from "../../../../assets/Clock.svg"

type PauseReminderModalType = {
    modalId: number
    id: string
    close: () => void
    setPauseReminder: (data: { pid: string; days: number }) => void
}

export default function PauseReminderModal(props: PauseReminderModalType) {
    const [form] = Form.useForm()
    const [days, setdays] = useState<string>()

    const handlePauseReminder = () => {
        props.setPauseReminder({ pid: props.id, days: Number(days) })
        setdays(undefined)
        form.setFieldsValue({
            days: undefined,
        })
        props.close()
    }

    return (
        <BaseModal size title={"Mahnung pausieren"} modalId={props.modalId}>
            <p className="w-64 text-xs py-2">
                Sie können die Mahnungen für die ausgewählte Zahlungsanforderung um eine beliebige Anzahl an Tagen pausieren.
            </p>
            <Form form={form} onFinish={handlePauseReminder}>
                <Form.Item
                    className="my-2"
                    name={"days"}
                    rules={[
                        { required: true, message: "Bitte ausfüllen!" },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value > 0) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error("Der Wert muss größer als 0 sein!"))
                            },
                        }),
                    ]}>
                    <BaseInputField label={"Verzögerung in Tagen"} type={"number"} onChange={setdays} />
                </Form.Item>
                <button className="btn btn-primary w-full mt-2" type="submit">
                    <CLOCK className="w-6 h-6" />
                    Verzögern
                </button>
            </Form>
        </BaseModal>
    )
}
