import { QueryClient, useMutation } from "@tanstack/react-query"
import { CostCenterType, Employee } from "../types/Types"
import {
    addCostCenterData,
    addDriversLicenceEntry,
    deleteCostCenterData,
    deleteDocFromEmployee,
    postNewDocToEmployee,
    resetPWFromEmployee,
    updateCostCenterData,
    updateEmployee,
    updateEmployeeKcGroup,
} from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"

// update employee data
export const useUpdateEmployeeMutation = (queryClient: QueryClient, id: string, refreshDataEmployee: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: Employee }>({
        mutationFn: updateEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Mitarbeiter wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Mitarbeiter wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: [refreshDataEmployee] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Mitarbeiter konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// update employee kc group
export const useUpdateEmployeeKcGroupMutation = (queryClient: QueryClient, id: string, refreshDataEmployee: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { kcid: string; gid: string }>({
        mutationFn: updateEmployeeKcGroup,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Position des Mitarbeiters wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Position des Mitarbeiters wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: [refreshDataEmployee] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Position des Mitarbeiters konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// reset pw from user
export const usePWResetEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: resetPWFromEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Passwort des Mitarbeits wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Passwort des Mitarbeiters wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Passwort des Mitarbeiters konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// upload document(s) to employee
export const useAddDocToEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade hinzugefügt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete doc-data from employee & from s3
export const usePatchDocEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })

    return mutation
}

// add cost center data
export const useAddCostCenterMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, CostCenterType>({
        mutationFn: addCostCenterData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Niederlassung wird gerade hinzugefügt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Niederlassung wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({ queryKey: ["cost-center-all"], exact: true })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Niederlassung konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete cost center
export const useDeleteCostCenterMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: deleteCostCenterData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Niederlassung wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Niederlassung wurde erfolgreich gelöscht.")
            queryClient.invalidateQueries({ queryKey: ["cost-center-all"], exact: true })
        },
        onError: (err: any) => {
            if (err.response.status === 400) {
                messageError(
                    notificationKey,
                    "Fehler!",
                    "Die Niederlassung konnte nicht gelöscht werden, da noch Mitarbeiter dieser Niederlassung zugewiesen sind."
                )
            } else {
                messageError(notificationKey, "Fehler!", "Die Niederlassung konnte nicht gelöscht werden.")
            }
            console.log(err)
        },
    })

    return mutation
}

// delete cost center
export const useUpdateCostCenterMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { data: any; id: string }>({
        mutationFn: updateCostCenterData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Niederlassung wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Niederlassung wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["cost-center-all"], exact: true })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Niederlassung konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete cost center
export const useAddDriversLicenceEntryMutation = (queryClient: QueryClient, queryKey: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string, note?: string, image: FormData }>({
        mutationFn: addDriversLicenceEntry,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Eintrag wird gerade hinzugefügt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der neue Eintrag zum Führerschein wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({ queryKey: [queryKey], exact: true })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der neue Eintrag zum Führerschein konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })

    return mutation
}
