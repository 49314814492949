type ValueListType = {
    label: string
    ul: any
}

export default function ValueList(props: ValueListType) {
    function calcWidth(x: number) {
        const result = ((props.ul.payReqTotal - x) / props.ul.payReqTotal) * 100 // Calculate the width percentage
        return `w-[${result}%]` // Return the Tailwind class
    }

    console.log(calcWidth(props.ul.reminderTotal))
    return (
        <div className="flex flex-col w-full shadow-lg bg-white p-8 rounded-default h-64">
            <h5 className="font-semibold text-lg">{props.label}</h5>
            <div className="w-full border-b my-2" />
            <div className="flex flex-col pt-2 justify-between h-full">
                <div className="flex flex-row justify-center w-full">
                    <div
                        style={{ width: `${100 - props.ul.reminderRate * 100}%` }}
                        className={`bg-[#02a33a99] font-semibold text-white rounded-l-default text-lg h-24 flex flex-col justify-center items-center `}>
                        {props.ul.payReqTotal - props.ul.reminderTotal}
                        <br />
                        <p className="text-center text-xs font-normal">Zahlungsanforderungen ohne Mahnung</p>
                    </div>
                    <div
                        style={{ width: `${props.ul.reminderRate * 100}%` }}
                        className={`bg-[#a30f0299] font-semibold text-white rounded-r-default h-24 text-lg flex flex-col justify-center items-center 
                        `}>
                        {props.ul.reminderTotal}
                        <br />
                        <p className="text-center text-xs font-normal">Zahlungsanforderungen, mit mind. einer Mahnung</p>
                    </div>
                </div>
                <p className="text-center text-xs pt-2">Insgesamt wurden {props.ul.payReqTotal} Zahlungsanforderungen gestellt.</p>
                <p className="text-center text-xs">
                    {(props.ul.reminderRate * 100).toFixed(2).replace(".", ",")} % der Zahlungsanforderungen wurden gemahnt.
                </p>
            </div>
        </div>
    )
}
