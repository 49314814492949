import { Key } from "react"
import BaseDocumentElement from "../../../../../components/elements/BaseDocumentElement"
import { DocumentDataType } from "../../../../../components/types"
import { BillGet } from "../../../../../data/Types"
import dayjs from "dayjs"
import { ReactComponent as EYE } from "../../../../../assets/EyeICON.svg"
import { useNavigate } from "react-router-dom"

type BillTemplateBillsAndFilesType = {
    bills: BillGet[]
    data: {
        additional_s3Documents: DocumentDataType[]
    }
    handleDownload: (item: { s3Key: string; filename: string }) => void
    handleChangePreviewElement: (e: string) => void
}

export default function BillTemplateBillsAndFiles(props: BillTemplateBillsAndFilesType) {
    const navigate = useNavigate()

    return (
        <div className="mt-4 w-full h-fit max-h-[16vh] rounded-default shadow-lg bg-white p-4 flex flex-row">
            <div className="flex flex-col w-full">
                <p className="w-full border-b pb-2 font-medium">Zugeordnete Rechnungen</p>
                <div className="overflow-y-scroll">
                    {!props.bills || props.bills.length === 0 ? (
                        <p className="text-center uppercase text-sm text-gray-300 font-medium pt-1">Keine Rechnungen zugeordnet</p>
                    ) : (
                        props.bills?.map((item: BillGet, index: Key) => (
                            <BillRow key={index} item={item} navigate={() => navigate("/buchhaltung/rechnung/" + item._id)} />
                        ))
                    )}
                </div>
            </div>
            <div className="flex flex-col w-full">
                <p className="w-full border-b pb-2 font-medium">Zugeordnete Dateien</p>
                <div className="overflow-y-scroll">
                    {!props.data.additional_s3Documents || props.data.additional_s3Documents.length === 0 ? (
                        <p className="text-center uppercase text-sm text-gray-300 font-medium pt-1">Keine Dokumente hochgeladen</p>
                    ) : (
                        props.data?.additional_s3Documents.map((item: DocumentDataType, index: Key) => (
                            <BaseDocumentElement
                                key={index}
                                title={item.title}
                                timestamp={item.timestamp}
                                showPreviewFunc={() => props.handleChangePreviewElement("")}
                                downloadFunc={() => props.handleDownload({ s3Key: item.s3Key, filename: item.title })}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

type BillRowType = {
    item: BillGet
    navigate: () => void
}

function BillRow(props: BillRowType) {
    return (
        <div className="w-full flex flex-row items-center gap-4 p-2 -z-10 cursor-pointer hover:bg-black/5">
            <p className="text-xm text-gray-400 h-fit">Erstellt am:</p>
            <p>{dayjs(props.item.date).format("DD.MM.YYYY")}</p>
            <p className="text-xm text-gray-400 h-fit">Fällig am:</p>
            <p>{dayjs(props.item.billDay).format("DD.MM.YYYY")}</p>
            <p className="text-xm text-gray-400 h-fit">Betrag:</p>
            <p>{props.item.totalBrutto.toString().replace(".", ",")} €</p>
            <div className="grow flex justify-end z-10 pr-4">
                <EYE onClick={props.navigate} className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
            </div>
        </div>
    )
}
