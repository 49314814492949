import { ReactComponent as DOCUMENT } from "../../assets/Document.svg"
import { ReactComponent as DOWNLOAD } from "../../assets/DownloadICON.svg"
import { ReactComponent as EYE } from "../../assets/EyeICON.svg"
import { ReactComponent as DELETE } from "../../assets/DeleteICON.svg"
import { RenderWhenAuthorized } from "../authentication/RenderWhenAuthorized"
import { Roles } from "../../auth/RoleMapping"
import BasePopover from "./BasePopover"

type BaseDocElementType = {
    label: string
    sublabel?: string
    obv?: boolean
    roles?: Roles[]
    onPreview?: () => void
    onDownload?: () => void
    onDelete?: () => void
}

export default function BaseDocElement(props: BaseDocElementType) {
    return (
        <div className="p-3 rounded-default flex flex-row items-center justify-between bg-white border shadow-lg">
            <div className="flex items-center gap-4">
                <DOCUMENT className="w-6 h-6" />
                <p className="font-semibold">
                    {props.label}
                    <span className="text-xs text-gray-400 pl-3">{props.sublabel}</span>
                </p>
            </div>
            <div className="flex gap-4">
                {props.onDownload && <DOWNLOAD className="w-6 h-6 cursor-pointer" onClick={props.onDownload} />}
                {props.label?.endsWith(".pdf") && props.onPreview && (
                    <EYE onClick={props.onPreview} className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
                )}
                {!props.obv && (
                    <RenderWhenAuthorized requiresAll={props.roles ?? []}>
                        {props.onDelete && (
                            <BasePopover label={"Wirklich löschen?"} placement="topLeft" okButtonClick={props.onDelete}>
                                <DELETE className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
                            </BasePopover>
                        )}
                    </RenderWhenAuthorized>
                )}
            </div>
        </div>
    )
}
