import { useContext, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import AddBillOrOfferModalScreen2 from "./AddBillOrOfferModalScreen2"
import AddBillOrOfferModalScreen3 from "./AddBillOrOfferModalScreen3"
import AddBillOrOfferModalScreen4 from "./AddBillOrOfferModalScreen4"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { BillTemplatePost } from "../../../../data/Types"
import { useAddBillTemplateMutation } from "../../api/services"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { getPaginatedData } from "../../../api/apiCalls"
import { Form, FormInstance } from "antd"
import BillTemplateModalScreen1 from "./AddBillTemplateModalScreen1"

type AddBillTemplateModalType = {
    queryKey: string
    modalId: number
}

export default function AddBillTemplateModal(props: AddBillTemplateModalType) {
    const [newBillTemplate, setnewBillTemplate] = useState<BillTemplatePost>()

    // GET all customer for search
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKeyAllCustomer = "customerDataToAddBill" + searchParams
    const {
        data: customerData,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: [queryKeyAllCustomer],
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 10, search: searchParams, archived: false, deleted: false }),
    })

    // GET single customer
    const queryKeySingleCustomer = "singleCustomerData" + newBillTemplate?.customer_id
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: [queryKeySingleCustomer],
        queryFn: () => getSingleCustomerData(newBillTemplate?.customer_id ?? ""),
        enabled: !!newBillTemplate?.customer_id,
    })

    const [documents, setdocuments] = useState<
        {
            title: ""
            fileUpload: File
            uploadedByUser: true
        }[]
    >()

    // all about creating the new bill
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill } = useAddBillTemplateMutation(queryClient, props.queryKey)
    const handleNewBill = () => {
        const formData = new FormData()
        formData.append("billTemplate", JSON.stringify(newBillTemplate))
        documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addBill(formData)
        clearFormAndModal()
    }

    // FE LOGIC
    const [screen, setscreen] = useState<number>(1)
    const [formScreen1] = Form.useForm()
    const [formScreen2] = Form.useForm()
    const clearFormAndModal = () => {
        formScreen1.resetFields()
        formScreen2.resetFields()
        setscreen(1)
        modalCon?.trigger(0)
        setsearchTerm("")
        setnewBillTemplate({
            date: new Date(),
            billTemplateCategorie: "Hausmeister",
            billDue: 28,
            billDay: 1,
            intervalMonths: [1],
            manualReminderEnabled: false,
        } as BillTemplatePost)
        formScreen1.setFieldsValue({
            billTemplateCategorie: "Hausmeister",
            billDue: 28,
            billDay: 1,
            manualReminderEnabled: false,
            forceFirstBillInstantly: undefined,
            intervalMonths: ["Januar"],
        })
    }
    const handleNextModalScreen = async (screen: number, form?: FormInstance) => {
        try {
            if (form) await form.validateFields()
            setscreen(screen)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neue Rechnung erstellen"}
                modalId={props.modalId}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            disabled={screen === 1}
                            className={`underline text-sm ${screen === 1 && "cursor-not-allowed text-gray-300"}`}
                            onClick={() => setscreen(screen - 1)}>
                            zurück
                        </button>
                        {screen === 4 ? (
                            <button className={`btn btn-primary`} onClick={handleNewBill}>
                                Abschicken
                            </button>
                        ) : screen === 3 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(4)}>
                                Weiter
                            </button>
                        ) : screen === 2 ? (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(3, formScreen2)}>
                                Weiter
                            </button>
                        ) : (
                            <button className="btn btn-primary btn-outline" onClick={() => handleNextModalScreen(2, formScreen1)}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {isLoadingSingleCustomer && <Loading />}
                    {errorSingleCustomer && <ErrorPage />}
                    {!errorSingleCustomer && (
                        <>
                            <BillTemplateModalScreen1
                                visible={screen >= 1}
                                form={formScreen1}
                                newBillTemplate={newBillTemplate as BillTemplatePost}
                                setnewBillTemplate={setnewBillTemplate}
                                objectArray={singleCustomer?.objects}
                                customerArray={customerData?.docs}
                                searchTerm={searchTerm}
                                setsearchTerm={setsearchTerm}
                                isLoadingCustomer={isLoadingCustomer}
                                errorCustomer={errorCustomer}
                            />
                            <AddBillOrOfferModalScreen2
                                form={formScreen2}
                                visible={screen >= 2}
                                newBillOrOffer={newBillTemplate as BillTemplatePost}
                                setnewBillOrOffer={setnewBillTemplate}
                            />
                            <AddBillOrOfferModalScreen3 visible={screen >= 3} documents={documents ?? []} setdocuments={setdocuments} />
                            <AddBillOrOfferModalScreen4
                                visible={screen >= 4}
                                newBillOrOffer={newBillTemplate as BillTemplatePost}
                                customer={singleCustomer}
                                documents={documents}
                            />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
