import { Form } from "antd"
import BaseInputField from "../../../../../components/elements/BaseInputField"
import { PositionData } from "../../../../../data/Types"
import { ReactComponent as MINUS } from "../../../../../assets/MinusCircle.svg"

type PositionReviewType = {
    index: number
    disablePrice: boolean
    disableDescription?: boolean
    disableUnit?: boolean
    disableTax?: boolean
    disableAmount?: boolean
    position?: PositionData
    handleInputChange: (e: number, f: string, g: string | number) => void
    removePosition?: (e: number) => void
}

export default function PositionReview(props: PositionReviewType) {
    return (
        <div className="flex w-full gap-2 pb-2">
            <BaseInputField label={"Nr."} type={""} onChange={() => {}} width="w-16" disabled value={props.position?.position_id} />
            <Form.Item
                initialValue={props.position?.description}
                className="m-0 grow"
                name={"position_description" + props.index}
                rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                <BaseInputField
                    label={"Beschreibung"}
                    type={""}
                    onChange={(e) => props.handleInputChange(props.index, "description", e)}
                    width="grow"
                    disabled={props.index === 0 || props.disableDescription}
                    value={props.position?.description}
                />
            </Form.Item>
            <Form.Item className="m-0" name={"position_unit" + props.index} rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                <BaseInputField
                    label={"Einheit"}
                    type={"text"}
                    onChange={(e) => props.handleInputChange(props.index, "unit", e)}
                    width="w-40"
                    disabled={props.disableUnit}
                    value={props.position?.unit}
                />
            </Form.Item>
            {/* TODO BaseInputSelection */}
            <BaseInputField
                label={"Steuersatz"}
                disabled={props.disableTax}
                type={""}
                onChange={(e) => props.handleInputChange(props.index, "tax", e)}
                width="w-20"
                value={props.position?.tax}
            />
            <Form.Item
                className="m-0 w-20"
                name={"position_amount" + props.index}
                rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                <BaseInputField
                    label={"Menge"}
                    type={"number"}
                    onChange={(e) => props.handleInputChange(props.index, "amount", e)}
                    width="w-20"
                    disabled={props.index === 0 || props.disableAmount}
                    value={props.position?.amount}
                />
            </Form.Item>
            <Form.Item
                className="m-0"
                name={"position_singlePrice" + props.index}
                rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                <BaseInputField
                    label={"Einzelpreis"}
                    type={"number"}
                    onChange={(e) => props.handleInputChange(props.index, "singlePrice", e)}
                    width="w-40"
                    disabled={props.disablePrice}
                />
            </Form.Item>
            {props.index !== 0 && props.removePosition !== undefined && (
                <MINUS className="w-6 h-6 my-auto -ml-1 cursor-pointer" onClick={() => props.removePosition && props.removePosition(props.index)} />
            )}
        </div>
    )
}
