import { useNavigate } from "react-router-dom"
import { PaymentTableRowType } from "../../types"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../auth/RoleMapping"
import { ReactComponent as EscalationIcon } from "./../../../../assets/EscalationRequiredICON.svg"
import { ReactComponent as INFO } from "./../../../../assets/InfoICON.svg"
import { ReactComponent as CHECK } from "./../../../../assets/ApprovaICON.svg"
import { Popover, Tag } from "antd"
import BaseTableRow from "../../../../components/layout/BaseTableRow"
dayjs.extend(isBetween)

export default function PaymentTableRow(props: PaymentTableRowType) {
    const navigate = useNavigate()
    const target = dayjs(props.delivery)
    const today = dayjs()

    return (
        <BaseTableRow url={"/buchhaltung/rechnung/" + props.id} classProps={props.archived ? "opacity-40 bg-gray-100" : ""}>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden lg:table-cell">
                <p className="line-clamp-1  text-center">{dayjs(props.delivery).format("DD.MM.YYYY")}</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden lg:table-cell">
                <p className="line-clamp-1  text-center">{dayjs(props.invoice).format("DD.MM.YYYY")}</p>
            </td>
            <td
                onClick={(event) => {
                    event.stopPropagation()
                    props.openPauseReminderModal()
                }}
                className={`hidden lg:table-cell z-10 hover:underline  ${
                    dayjs(props.nextReminder).isBetween(today, dayjs().add(5, "day"), "day", "[]") && "font-semibold text-error"
                }`}>
                <p className={`line-clamp-1 text-center`}>{dayjs(props.nextReminder).format("DD.MM.YYYY")} {props.pausedReminder && props.pausedReminder > 0 ? `(+${props.pausedReminder}d)` : ""}</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p title={props.title} className="line-clamp-1  text-center">
                    Rechnung {props.title}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p className="line-clamp-1  text-center">{props.netto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)}>
                <p className="line-clamp-1  text-center">{props.brutto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden md:table-cell">
                <p title={props.customer} className="line-clamp-1  text-center">
                    {props.customer}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + props.id)} className="hidden xl:table-cell">
                <p className={`line-clamp-1 text-center`}>
                    {props.escalationRequired ? (
                        <Tag color="red-inverse" className="w-36 text-center">
                            Mahnungen Überfällig
                        </Tag>
                    ) : props.status === "settled" ? (
                        <Tag color="green-inverse" className="w-24 text-center">
                            Beglichen
                        </Tag>
                    ) : props.status === "canceled" ? (
                        <Tag className="w-24 text-center">Storniert</Tag>
                    ) : props.status === "unpaid" && target < today ? (
                        <Tag color="red" className="w-32 text-center">
                            {`${today.diff(target, "day") + 1} Tage überfällig`}
                        </Tag>
                    ) : (
                        <Tag color="gold" className="w-24 text-center">
                            Ausstehend
                        </Tag>
                    )}
                </p>
            </td>
            <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
                {!props.escalationRequired ? (
                    <td
                        onClick={(event) => {
                            event.stopPropagation()
                            props.modalOpen()
                        }}>
                        <INFO className="w-6 h-6 z-10 " />
                    </td>
                ) : (
                    <td
                        onClick={(event) => {
                            event.stopPropagation()
                            props.openSolveEscalationModal()
                        }}>
                        <EscalationIcon className="w-6 h-6 z-10 " />
                    </td>
                )}
            </RenderWhenAuthorized>
            <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
                <td onClick={(event) => event.stopPropagation()}>
                    {props.status !== "settled" && props.status !== "canceled" && (
                        <p title="Als bezahlt markieren">
                            <Popover
                                placement="bottomLeft"
                                title="Die Zahlungsaufforderung als beglichen markieren."
                                content={
                                    <div className="flex flex-col items-end">
                                        <p>Wollen Sie diese Aufgabe wirklich löschen?</p>
                                        <button onClick={props.markAsPaid} className="btn btn-sm btn-primary w-fit mt-1">
                                            OK
                                        </button>
                                    </div>
                                }>
                                <CHECK className="w-6 h-6 z-10 " />
                            </Popover>
                        </p>
                    )}
                </td>
            </RenderWhenAuthorized>
        </BaseTableRow>
    )
}
