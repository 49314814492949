import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import BaseSelection from "../../../../components/elements/BaseSelection"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import { Form, FormInstance } from "antd"
import { useEffect } from "react"
import { BillTemplatePost } from "../../../../data/Types"
import { Customer } from "../../../Kunden/types/Types"
import { ObjectType } from "../../../Objekte/types/types"
import { InputObject } from "../../../../components/types"

type BillTemplateModalScreen1Type = {
    form: FormInstance // TODO
    visible: boolean
    newBillTemplate: BillTemplatePost
    setnewBillTemplate: (e: any) => void // TODO
    customerArray: Customer[]
    objectArray?: ObjectType[]
    clearInputDropDown?: boolean
    searchTerm: string
    setsearchTerm: (e: string) => void
    isLoadingCustomer: boolean
    errorCustomer: Error | null
}

export const monthDataArray = [
    { key: "Januar", value: 1 },
    { key: "Februar", value: 2 },
    { key: "März", value: 3 },
    { key: "April", value: 4 },
    { key: "Mai", value: 5 },
    { key: "Juni", value: 6 },
    { key: "Juli", value: 7 },
    { key: "August", value: 8 },
    { key: "September", value: 9 },
    { key: "Oktober", value: 10 },
    { key: "November", value: 11 },
    { key: "Dezember", value: 12 },
]

export default function BillTemplateModalScreen1(props: BillTemplateModalScreen1Type) {
    // FE LOGIC
    useEffect(() => {
        props.setnewBillTemplate({
            date: new Date(),
            billTemplateCategorie: "Hausmeister",
            billDue: 28,
            billDay: 1,
            intervalMonths: [1],
            manualReminderEnabled: false,
        })
        props.form.setFieldsValue({
            customer_id: props.newBillTemplate?.customer_id ?? undefined,
            object_id: props.newBillTemplate?.object_id ?? undefined,
            billTemplateCategorie: props.newBillTemplate?.billTemplateCategorie ?? "Hausmeister",
            billDue: props.newBillTemplate?.billDue ?? 28,
            billDay: props.newBillTemplate?.billDay ?? 3,
            manualReminderEnabled: props.newBillTemplate?.manualReminderEnabled ?? false,
            reminderStartOffsetInDays: props.newBillTemplate?.reminderStartOffsetInDays ?? undefined,
            reminderIntervalInDays: props.newBillTemplate?.reminderIntervalInDays ?? undefined,
            maxReminders: props.newBillTemplate?.maxReminders ?? undefined,
            additionalText: props.newBillTemplate?.additionalText ?? undefined,
            forceFirstBillInstantly: props.newBillTemplate?.forceFirstBillInstantly ?? undefined,
            intervalMonths: props.newBillTemplate?.intervalMonths ?? ["Januar"],
        })
    }, [])

    return (
        <Form form={props.form}>
            <motion.div
                initial={{
                    x: 0,
                }}
                animate={{
                    x: props.visible ? 0 : "-100%",
                }}
                transition={{
                    type: "ease",
                }}
                className="absolute top-0 left-0 w-full h-full overflow-x-hidden overflow-y-scroll pb-4 pl-2">
                <div className="flex gap-4 w-full">
                    <Form.Item className="mt-4 mb-2 w-full" name={"billTemplateCategorie"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Kategorie"}
                            placeholder={""}
                            data={["Hausmeister", "Schlüsseldienst", "Bau", "Vermietung"]}
                            setId={(e: string) => {
                                props.form.setFieldsValue({
                                    billTemplateCategorie: e,
                                })
                                props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                    ...prev,
                                    billTemplateCategorie: e,
                                }))
                            }}
                            defaultValue="Hausmeister"
                        />
                    </Form.Item>
                </div>
                <Form.Item className="mb-2" name={"customer_id"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputSelection
                        setId={(e) => {
                            props.form.setFieldsValue({ customer_id: e })
                            props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                ...prev,
                                customer_id: e,
                            }))
                        }}
                        label={"Kunde *"}
                        placeholder={"Kunde auswählen"}
                        data={props.customerArray as InputObject[]}
                        onSearch={(e: string) => props.setsearchTerm(e)}
                        customLoading={props.isLoadingCustomer}
                        customError={props.errorCustomer}
                    />
                </Form.Item>
                {props.newBillTemplate?.billTemplateCategorie !== "Sonstiges" && (
                    <Form.Item className="mb-2" name={"object_id"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseInputSelection
                            setId={(e) => {
                                props.form.setFieldsValue({ object_id: e })
                                props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                    ...prev,
                                    object_id: e,
                                }))
                            }}
                            label={"Objekt *"}
                            placeholder={"Objekt auswählen"}
                            data={(props.objectArray ?? []) as InputObject[]}
                            disabled={props.objectArray?.length === 0 || !props.objectArray}
                        />
                    </Form.Item>
                )}
                {props.newBillTemplate?.customer_id !== "" && props.objectArray?.length === 0 && (
                    <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>
                )}
                <Form.Item className="mb-2" name={"intervalMonths"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        mode="multiple"
                        // disabled={disableIfUserIsSelfAndNotAdmin}
                        label={"In welchen Monaten wird eine Rechnung erstellt?"}
                        setId={(newItemArray: any) => {
                            props.form.setFieldsValue({ intervalMonths: newItemArray })
                            let ids = monthDataArray
                                ?.filter((item: { key: string; value: number }) => newItemArray.includes(item.key))
                                .map((item) => item.value)
                            props.setnewBillTemplate((prevState: any) => ({
                                ...prevState,
                                intervalMonths: ids,
                            }))
                        }}
                        placeholder={""}
                        data={monthDataArray.map((item: { key: string; value: number }) => item.key)}
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"billDay"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        label={"An welchem Tag des Monats wird die Rechnung ausgestellt?"}
                        placeholder={""}
                        data={["1", "15", "28"]}
                        setId={(e: string) => {
                            props.form.setFieldsValue({ billDay: Number(e) })
                            props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                ...prev,
                                billDay: Number(e),
                            }))
                        }}
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"billDue"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        label={"Rechnungsfrist in Tagen *"}
                        placeholder={""}
                        data={["7", "14", "28"]}
                        setId={(e: string) => {
                            props.form.setFieldsValue({ billDue: Number(e) })
                            props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                ...prev,
                                billDue: Number(e),
                            }))
                        }}
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"additionalText"} rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputArea
                        classProps="mt-2"
                        label={"Zusätzlicher Text auf der Rechnung (optional)"}
                        onChange={(e) =>
                            props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                ...prev,
                                additionalText: e,
                            }))
                        }
                    />
                </Form.Item>
                <div className={`flex gap-4 justify-evenly`}>
                    <Form.Item
                        className="mt-4 mb-2"
                        name={"forceFirstBillInstantly"}
                        rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                        <BaseCheckbox
                            setcheck={(e) =>
                                props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                    ...prev,
                                    forceFirstBillInstantly: e,
                                }))
                            }
                            classProps="justify-start"
                            label={"Direkt erste Rechnung erstellen und versenden"}
                        />
                    </Form.Item>

                    <Form.Item className="mb-2 mt-4" name="manualReminderEnabled" rules={[{ required: true, message: "Muss einen Wert enthalten!" }]}>
                        <BaseCheckbox
                            prevent={!props.newBillTemplate?.manualReminderEnabled}
                            setcheck={(e) =>
                                props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                    ...prev,
                                    manualReminderEnabled: !props.newBillTemplate.manualReminderEnabled,
                                }))
                            }
                            classProps="justify-start"
                            label={"Mahnungsfristen manuell setzen"}
                        />
                    </Form.Item>
                </div>
                {props.newBillTemplate?.manualReminderEnabled && (
                    <div className="flex w-full gap-4">
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderStartOffsetInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Nach wieviel Tagen wird gemahnt?"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                        ...prev,
                                        reminderStartOffsetInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderIntervalInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"In welchem Abstand wird gemahnt? (in Tagen)"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                        ...prev,
                                        reminderIntervalInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"maxReminders"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Wieviele Mahnungen werden verschickt?"}
                                type={"number"}
                                onChange={(e) =>
                                    props.setnewBillTemplate((prev: BillTemplatePost) => ({
                                        ...prev,
                                        maxReminders: Number(e),
                                    }))
                                }
                                prevent
                            />
                        </Form.Item>
                    </div>
                )}
            </motion.div>
        </Form>
    )
}
