import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Calendar from "@toast-ui/react-calendar"
import "@toast-ui/calendar/dist/toastui-calendar.min.css"
import BasePage from "../../../components/layout/BasePage"
import "../../../style/calendar.css"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BaseButton from "../../../components/elements/BaseButton"
import { ReactComponent as PLUSCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as ARROWRIGHT } from "../../../assets/ChevronRight.svg"
import { ReactComponent as ARROWLEFT } from "../../../assets/ChevronLeft.svg"
import { ReactComponent as CALENDAR } from "../../../assets/CalendarICON.svg"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import { getPaginatedData } from "../../api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import {
    rearrangeSessionDataForCalendar,
    rearrangeSessionDataForDB,
    rearrangeSessionDataForDBUpdate,
    useDelayWhenSearchWithPagination,
} from "../../../services/functions"
import { useNavigate, useParams } from "react-router-dom"
import { getTasksByFilter } from "../api/apiCalls"
import {
    useAddSessionMutation,
    useAssignTaskToSessionMutation,
    useCheckInOrOutToSessionMutation,
    useDeleteSessionMutation,
    useUnAssignTaskToSessionMutation,
    useUpdateSessionAttendeesMutation,
    useUpdateSessionMutation,
} from "../api/services"
import { getSingleTaskType, sessionCalendarDataType, sessionGetDataType } from "../types"
import dayjs from "dayjs"
import ErrorPage from "../../404/ErrorPage"
import SessionDetailSidebar from "../components/SessionDetailSidebar"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import { singleEmployeeQueryKey } from "../../api/queryKeys"
import { getEmployeeByIdData } from "../../Mitarbeiter/api/apiCalls"
import EditAttendeesOfSessionModal from "../components/EditAttendeesOfSessionModal"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import isoWeek from "dayjs/plugin/isoWeek"
import { useKeycloak } from "@react-keycloak/web"
import { NAVIGATION_ROUTES } from "../../../App"
import Loading from "../../../components/common/Loading"

type ViewType = "week" | "day"

export default function CalendarPage() {
    // General settings
    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const { keycloak } = useKeycloak()
    const calendarRef = useRef<any>()
    const { id } = useParams()

    // GET employee by id
    const queryKeySingleEmployee = singleEmployeeQueryKey + (id ?? "")
    const {
        isLoading: employeeisLoading,
        error: empployeeError,
        data: singleEmployeeData,
    } = useQuery({
        queryKey: [queryKeySingleEmployee],
        queryFn: () => getEmployeeByIdData(id ?? ""),
        enabled: !!id,
    })

    // GET Employees session
    dayjs.extend(isoWeek)
    const [currentCalendarView, setcurrentCalendarView] = useState<ViewType>(window.innerWidth < 768 ? "day" : "week")
    const [startInterval, setstartInterval] = useState(
        currentCalendarView === "day" ? dayjs().hour(0).minute(0).second(0).millisecond(0) : dayjs().startOf("isoWeek").toDate()
    )
    const queryKeySingleEmployeeSession = "employeeCalender" + (id ?? "") + startInterval
    const {
        isLoading: eventisLoading,
        error: eventError,
        data: eventData,
    } = useQuery({
        queryKey: [queryKeySingleEmployeeSession],
        queryFn: () =>
            getPaginatedData({
                filter: true,
                page: 1,
                pageType: "session",
                limit: 1000,
                startDate: startInterval.toISOString(),
                endDate: dayjs(startInterval)
                    .add(currentCalendarView === "day" ? 1 : 6, "days")
                    .toDate()
                    .toISOString(),
                employeeId: id,
            }),
        enabled: !!(id && startInterval && currentCalendarView),
    })
    const [allFetchedSessions, setallFetchedSessions] = useState<sessionCalendarDataType[]>([])
    useEffect(() => {
        if (eventData) {
            console.log("Sessions: ", eventData)
            setallFetchedSessions(rearrangeSessionDataForCalendar(eventData, id ?? ""))
        }
    }, [eventData])
    const [showDetailEvent, setshowDetailEvent] = useState<sessionCalendarDataType | "new">()

    // set calendar view to current date range
    useEffect(() => {
        if (currentCalendarView === "week") {
            if (calendarRef.current) calendarRef.current.getInstance().setDate(startInterval)
        } else if (currentCalendarView === "day") {
            if (calendarRef.current) calendarRef.current.getInstance().setDate(startInterval)
        }
    }, [startInterval])

    // Change to next week or day
    const goToNextWeek = () => {
        if (currentCalendarView === "week") {
            setstartInterval(dayjs(startInterval).add(7, "days").toDate())
        } else if (currentCalendarView === "day") {
            setstartInterval(dayjs(startInterval).add(1, "day").toDate())
        }
    }

    // Change to previous week or day
    const goToPrevWeek = () => {
        if (currentCalendarView === "week") {
            setstartInterval(dayjs(startInterval).subtract(7, "days").toDate())
        } else if (currentCalendarView === "day") {
            setstartInterval(dayjs(startInterval).subtract(1, "day").toDate())
        }
    }

    // Kalender-Styling
    const calendars = [
        {
            id: "cal1",
            name: "Work",
            backgroundColor: "#D1A6AF",
            borderColor: "#800024",
        },
        {
            id: "cal2",
            name: "Personal",
            backgroundColor: "#ffc107",
            borderColor: "#ffc107",
        },
    ]

    // UPDATE Sessions (time)
    const queryClient = useQueryClient()
    const { mutate: updateSession } = useUpdateSessionMutation(queryClient, queryKeySingleEmployeeSession)
    const handleBeforeUpdateSchedule = (eventUpdate: any) => {
        const { event, changes } = eventUpdate
        setallFetchedSessions((prevSessions: sessionCalendarDataType[]) =>
            prevSessions.map((prevEvent) => {
                return prevEvent.id === event.id ? { ...prevEvent, ...changes } : prevEvent
            })
        )
        updateSession({
            id: event.id ?? "",
            body: {
                startAt: dayjs(changes.hasOwnProperty("start") ? changes.start.d : event.start.d).toDate(),
                endAt: dayjs(changes.end.d).toDate(),
            } as sessionGetDataType,
        })
        setshowDetailEvent(undefined)
    }

    // UPDATE session (dataset)
    const [updateSessionCopy, setupdateSessionCopy] = useState<sessionCalendarDataType>()
    const handleUpdateSession = () => {
        handleAssignOrUnassignTasks(typeof showDetailEvent !== "string" ? showDetailEvent?.id ?? "" : "")
        console.log("Gets Called but Shouldnt")
        updateSession({
            id: typeof showDetailEvent !== "string" ? showDetailEvent?.id ?? "" : "",
            body: rearrangeSessionDataForDBUpdate(updateSessionCopy as sessionCalendarDataType, showDetailEvent as sessionCalendarDataType),
        })
        setshowDetailEvent(undefined)
    }

    // UPDATE session (attendees/employees)
    const { mutate: updateSessionAttendees } = useUpdateSessionAttendeesMutation(queryClient, queryKeySingleEmployeeSession)
    const handleUpdateAttendees = () => {
        if (typeof showDetailEvent !== "string" && showDetailEvent !== undefined) {
            updateSessionAttendees({
                id: showDetailEvent.id,
                body: {
                    attendees: showDetailEvent.attendees.map((item) => item._id),
                } as sessionGetDataType,
            })
            modalCon?.trigger(1)
        }
    }

    // Function to only assign tasks
    const handleAssignTasks = (id: string) => {
        if (
            typeof showDetailEvent !== "string" &&
            showDetailEvent?.tasks !== undefined &&
            !assignedTasks.every((item) => showDetailEvent.tasks.includes(item))
        ) {
            assignTasks({
                id: id,
                body: assignedTasks
                    ?.filter(
                        (assignedItem) => !showDetailEvent?.tasks?.some((originalTask: getSingleTaskType) => assignedItem._id === originalTask._id)
                    )
                    .map((item) => item._id),
            })
        }
    }

    // Function to only unassign tasks
    const handleUnassignTasks = (id: string, areBothAssigningsExecuted: boolean) => {
        if (!unassignedTasks?.every((item) => tasksByObjectData.includes(item))) {
            unassignTasks({
                id: id,
                areBothAssigningsExecuted: areBothAssigningsExecuted,
                body:
                    unassignedTasks
                        ?.filter(
                            (unassignedItem) => !tasksByObjectData.some((originalTask: getSingleTaskType) => unassignedItem._id === originalTask._id)
                        )
                        .map((item) => item._id) ?? [],
            })
        }
    }

    // PUT tasks (-> assign to session)
    const [assignedTasks, setassginedTasks] = useState<getSingleTaskType[]>([])
    useEffect(() => {
        if (typeof showDetailEvent !== "string") {
            setassginedTasks(showDetailEvent?.tasks ?? [])
        }
    }, [showDetailEvent])
    const { mutate: assignTasks, isPending: assignTaskToSessionIsPending } = useAssignTaskToSessionMutation(
        queryClient,
        queryKeySingleEmployeeSession,
        () => setshowDetailEvent(undefined)
    )
    const { mutate: unassignTasks, isPending: unassignTaskToSessionIsPending } = useUnAssignTaskToSessionMutation(
        queryClient,
        queryKeySingleEmployeeSession,
        handleAssignTasks
    )
    const handleAssignOrUnassignTasks = (id: string) => {
        // Assign Tasks after adding a session
        if (typeof showDetailEvent === "string") {
            assignTasks({
                id: id,
                body: assignedTasks.map((item) => item._id),
                dontDisplaySuccessMessage: true,
            })
        } else {
            // check if we need to assign and unassign tasks
            let areBothAssigningsExecuted =
                !unassignedTasks?.every((item) => tasksByObjectData.includes(item)) &&
                typeof showDetailEvent !== "string" &&
                showDetailEvent?.tasks !== undefined &&
                !assignedTasks.every((item) => showDetailEvent.tasks.includes(item))
            // only assign tasks
            if (!areBothAssigningsExecuted) {
                handleAssignTasks(id)
            }
            handleUnassignTasks(id, areBothAssigningsExecuted)
        }
    }

    // ADD session
    const { mutate: addNewSession, isPending: addNewSessionIsPending } = useAddSessionMutation(
        queryClient,
        queryKeySingleEmployeeSession,
        handleAssignOrUnassignTasks
    )
    const handleAddNewSession = async () => {
        if (updateSessionCopy) {
            if (assignedTasks.length !== 0) {
                addNewSession(
                    rearrangeSessionDataForDB(
                        updateSessionCopy,
                        tasksByObjectData?.find((task: { _id: string }) => task._id === assignedTasks[0]._id).object
                    )
                )
            }
        }
    }

    // DELETE session
    const { mutate: deleteSession } = useDeleteSessionMutation(queryClient, queryKeySingleEmployeeSession)
    const handleDeleteSession = () => {
        if (showDetailEvent && showDetailEvent !== "new") {
            deleteSession(showDetailEvent.id)
            setshowDetailEvent(undefined)
        }
    }

    // handle Click on event
    const handleClickEvent = (e: any) => {
        if (keycloak.hasRealmRole(Roles.termine_management) && window.innerWidth > 768) {
            setshowDetailEvent(allFetchedSessions.find((session) => session.id === e.event.id))
        } else if (keycloak.hasRealmRole(Roles.termine_execute)) {
            navigate(NAVIGATION_ROUTES.SESSION + e.event.id)
        }
    }

    // Get All Employees for search
    const [nameOfCurrentEmployee, setNameOfCurrentEmployee] = useState<string>()
    const [searchTermEmployee, setsearchTermEmployee] = useState<string>()
    const searchParamsEmployee = useDelayWhenSearchWithPagination(searchTermEmployee ?? "")
    const queryKeyAllEmployees = "employee" + searchParamsEmployee
    const {
        error: allEmployeeError,
        isLoading: allEmployeeIsLoading,
        data: allEmployeeData,
    } = useQuery({
        queryKey: [queryKeyAllEmployees],
        queryFn: () =>
            getPaginatedData({
                pageType: "employee",
                page: 1,
                limit: 20,
                archived: false,
                search: searchParamsEmployee,
            }),
    })
    const displayedAllEmployees = useMemo(() => allEmployeeData?.docs, [allEmployeeData?.docs])

    // Get All Objects for search
    const [searchTermObject, setsearchTermObject] = useState("")
    const searchParamsObject = useDelayWhenSearchWithPagination(searchTermObject)
    const queryKeyAllObjects = "object" + searchParamsObject + singleEmployeeData?.kostenstelle.map((item: string) => item)
    const {
        error: allObjectError,
        isLoading: allObjectIsLoading,
        data: allObjectData,
    } = useQuery({
        queryKey: [queryKeyAllObjects],
        queryFn: () =>
            getPaginatedData({
                pageType: "object",
                page: 1,
                limit: 20,
                search: searchParamsObject,
                costCenterId: singleEmployeeData?.kostenstelle,
            }),
        enabled: !!showDetailEvent && !!singleEmployeeData?.kostenstelle,
    })
    const displayedAllObjects = useMemo(() => allObjectData?.docs, [allObjectData?.docs])

    // GET tasks by object
    const [queryKeyTasksByFilter, setqueryKeyTasksByFilter] = useState<{ obj_id: string; queryKey: string } | undefined>()
    useEffect(() => {
        if (typeof showDetailEvent !== "string" && showDetailEvent?.object_id) {
            console.log(showDetailEvent.object_id)
            setqueryKeyTasksByFilter({ obj_id: showDetailEvent.object_id, queryKey: "tasksByObjKey" + showDetailEvent.object_id })
        }

        if (updateSessionCopy?.object_id) {
            setqueryKeyTasksByFilter({ obj_id: updateSessionCopy.object_id, queryKey: "tasksByObjKey" + updateSessionCopy.object_id })
        }
        if (updateSessionCopy?.object_id === undefined && showDetailEvent === undefined) {
            setqueryKeyTasksByFilter(undefined)
        }
    }, [showDetailEvent, updateSessionCopy?.object_id])
    const {
        data: tasksByObjectData,
        isLoading: tasksByObjectIsLoading,
        // error: tasksByObjectError,
    } = useQuery({
        queryKey: [queryKeyTasksByFilter?.queryKey],
        queryFn: () =>
            getTasksByFilter({
                unassigned: true,
                billable: undefined,
                objectId: queryKeyTasksByFilter?.obj_id,
                costCenterId: undefined,
            }),
        enabled: !!queryKeyTasksByFilter?.queryKey,
    })
    const [unassignedTasks, setunassignedTasks] = useState<getSingleTaskType[]>()
    useEffect(() => setunassignedTasks(tasksByObjectData), [tasksByObjectData])

    // UPDATE manual checkin or checkout
    const { mutate: checkInOrOutManual } = useCheckInOrOutToSessionMutation(queryClient, queryKeySingleEmployeeSession)
    const handleManualCheckInOrOut = (session?: sessionCalendarDataType) => {
        if (session) {
            checkInOrOutManual({ id: session.id, indicator: session.checkInStatus === "checked-in" ? "out" : "in" })
            setshowDetailEvent(undefined)
        }
    }

    // FRONTEND LOGIC
    useEffect(() => {
        if (singleEmployeeData) {
            setNameOfCurrentEmployee(singleEmployeeData.first_name + " " + singleEmployeeData.last_name)
        }
    }, [singleEmployeeData])

    return (
        <>
            <BasePage>
                <BaseHeadBanner
                    title={"Kalender & Termine"}
                    button={
                        <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                            <PLUSCIRCLE
                                className="w-8 h-8 cursor-pointer hidden sm:block"
                                onClick={() => {
                                    setunassignedTasks(undefined)
                                    setqueryKeyTasksByFilter(undefined)
                                    setassginedTasks([])
                                    setshowDetailEvent("new")
                                    setupdateSessionCopy({
                                        attendees: [singleEmployeeData as { first_name: string; last_name: string; _id: string }],
                                    } as sessionCalendarDataType)
                                }}
                            />
                        </RenderWhenAuthorized>
                    }
                />
                <BaseButtonRow>
                    <div className="flex gap-2 items-center pl-4 md:pl-0">
                        {id && !allEmployeeError && (
                            <RenderWhenAuthorized requiresAll={[Roles.termine_management]}>
                                <BaseInputSelection
                                    label={""}
                                    placeholder={"Auswählen"}
                                    value={
                                        !searchTermEmployee || searchTermEmployee === ""
                                            ? nameOfCurrentEmployee
                                            : singleEmployeeData?.first_name !== undefined
                                            ? singleEmployeeData?.first_name + " " + singleEmployeeData?.last_name
                                            : "Lädt..."
                                    }
                                    classProps="!w-80 pt-2 pb-3 hidden sm:flex"
                                    onSearch={setsearchTermEmployee}
                                    customLoading={allEmployeeIsLoading}
                                    data={displayedAllEmployees}
                                    setId={(e: string) => {
                                        setNameOfCurrentEmployee(e)
                                        setshowDetailEvent(undefined)
                                        navigate("/kalender/" + e)
                                    }}
                                />
                            </RenderWhenAuthorized>
                        )}
                    </div>
                    <div className="flex gap-2 items-center ">
                        <BaseButton
                            classProps="hidden md:btn md:bg-white"
                            label={currentCalendarView === "day" ? "Wochenansicht" : "Tagesansicht"}
                            icon={<CALENDAR className="w-6 h-6" />}
                            func={() => (currentCalendarView === "week" ? setcurrentCalendarView("day") : setcurrentCalendarView("week"))}
                        />
                        <ARROWLEFT onClick={goToPrevWeek} className="p-2 bg-white shadow-md rounded-default w-10 h-10 cursor-pointer" />
                        <ARROWRIGHT onClick={goToNextWeek} className="p-2 bg-white shadow-md rounded-default w-10 h-10 cursor-pointer" />
                    </div>
                </BaseButtonRow>
                <BaseGrowBody classProps="grow flex">
                    {eventError && <ErrorPage />}
                    {eventisLoading && <Loading classProps="absolute top-0 z-[1000000]" />}
                    <Calendar
                        ref={calendarRef}
                        view={currentCalendarView} // Use ViewType here
                        calendars={calendars}
                        height="100%"
                        events={allFetchedSessions}
                        useDetailPopup={false}
                        onClickEvent={(event: any) => {
                            handleClickEvent(event)
                        }}
                        onBeforeUpdateEvent={handleBeforeUpdateSchedule}
                        template={{
                            allday(event) {
                                return `<span className="overflow-hidden">${event.title}</span>`
                            },
                            time: function (schedule) {
                                const start = new Date(schedule.start).toLocaleTimeString("de-DE", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })
                                return `<span>${start} Uhr - ${schedule.title}</span>`
                            },
                            timegridDisplayPrimaryTime: (hour) => {
                                const hours = hour.time.getHours()
                                return `${hours}:00 Uhr`
                            },
                        }}
                        isReadOnly={!keycloak.hasRealmRole(Roles.termine_management)}
                        week={{
                            hourStart: 0,
                            hourEnd: 24,
                            workweek: true,
                            dayNames: ["Sonntag", `Montag`, "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                            timezonesCollapsed: true,
                            taskView: false, // Hide the "Tasks" section
                            eventView: ["time"], // Show only time events, hiding "All-day events"
                        }}
                    />
                    <SessionDetailSidebar
                        addAttendee={() => {
                            modalCon?.trigger(1)
                            setsearchTermEmployee("")
                        }}
                        setsideBarLoading={addNewSessionIsPending || assignTaskToSessionIsPending || unassignTaskToSessionIsPending}
                        objectIsLoading={allObjectIsLoading}
                        searchForObject={(e) => setsearchTermObject(e)}
                        objects={displayedAllObjects}
                        close={() => setshowDetailEvent(undefined)}
                        session={showDetailEvent !== "new" && showDetailEvent ? showDetailEvent : undefined}
                        showDetailEvent={showDetailEvent ? true : false}
                        setUpdatedData={setupdateSessionCopy}
                        updatedData={updateSessionCopy}
                        handleAddNewSession={handleAddNewSession}
                        handleUpdateSession={handleUpdateSession}
                        handleDeleteSession={handleDeleteSession}
                        assginedTasks={assignedTasks ?? []}
                        setassginedTasks={setassginedTasks}
                        unassignedTasks={unassignedTasks}
                        setunassignedTasks={setunassignedTasks}
                        tasksByObjectIsLoading={tasksByObjectIsLoading}
                        manualCheckInOrOut={handleManualCheckInOrOut}
                    />
                </BaseGrowBody>
                {allEmployeeData && (
                    <EditAttendeesOfSessionModal
                        modalId={1}
                        selectedEmployeeCalendar={id}
                        sessionCompleted={(showDetailEvent as sessionCalendarDataType)?.status === "completed"}
                        setEmployee={showDetailEvent !== "new" ? setshowDetailEvent : setupdateSessionCopy}
                        employees={displayedAllEmployees}
                        onSave={showDetailEvent !== "new" ? () => handleUpdateAttendees() : () => modalCon?.trigger(0)}
                        currentAttendees={showDetailEvent !== "new" ? showDetailEvent?.attendees ?? [] : updateSessionCopy?.attendees ?? []}
                    />
                )}
            </BasePage>
        </>
    )
}
