import { useContext, useEffect, useMemo, useState } from "react"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import AddTaskModal from "../components/AddTaskModal"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { getPaginatedData } from "../../api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import BaseButton from "../../../components/elements/BaseButton"
import AllTasksTable from "../components/AllTasksTable"
import BaseInputSearch from "../../../components/elements/BaseInputSearch"
import { addTaskDataType } from "../types"
import { useAddTaskMutation, useDeleteTaskMutation } from "../api/services"
import { useNavigate } from "react-router-dom"
import { ReactComponent as PLUSCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as TASKS } from "../../../assets/Task.svg"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import SelectEmployeeModal from "../components/SelectEmployeeModal"
import { getCostCenterData } from "../../Mitarbeiter/api/apiCalls"
import BaseSelection from "../../../components/elements/BaseSelection"
import { CostCenterType } from "../../Mitarbeiter/types/Types"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { Pagination } from "antd"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"

export default function TaskOverviewPage() {
    // GET all costcenter
    const {
        error: costcenterError,
        data: costcenterData,
        isLoading: costcenterisLoading,
    } = useQuery({
        queryKey: ["cost-center-all"],
        queryFn: getCostCenterData,
    })
    const [selectedCostCenter, setselectedCostCenter] = useState<CostCenterType>()
    useEffect(() => costcenterData && setselectedCostCenter(costcenterData[0]), [costcenterData])

    // TASK get all
    const [page, setpage] = useState<number>(1)
    const handlePageChange = (page: number) => setpage(page)
    const [searchTermTasks, setsearchTermTasks] = useState("")
    const searchParamsTasks = useDelayWhenSearchWithPagination(searchTermTasks)
    const queryKeyTasks = "tasks" + searchParamsTasks + selectedCostCenter?._id
    const {
        error: allTasksError,
        isLoading: allTasksIsLoading,
        data: allTasksData,
    } = useQuery({
        queryKey: [queryKeyTasks],
        queryFn: () =>
            getPaginatedData({
                pageType: "tasks",
                page: page,
                limit: 20,
                unassigned: true,
                search: searchParamsTasks,
                costCenterId: selectedCostCenter?._id ?? "",
            }),
        enabled: !!selectedCostCenter,
    })
    const displayedTasks = useMemo(() => allTasksData?.docs, [allTasksData?.docs])
    useEffect(() => {
        console.log(selectedCostCenter?._id)
    }, [selectedCostCenter?._id])

    // EMPLOYEE and all stuff according to this
    const [searchTermEmployee, setsearchTermEmployee] = useState("")
    const searchParamsEmployee = useDelayWhenSearchWithPagination(searchTermEmployee)
    const queryKeyEmployee = "employee" + searchParamsEmployee + selectedCostCenter?._id
    const {
        error: allEmployeeError,
        isLoading: allEmployeeIsLoading,
        data: allEmployeeData,
    } = useQuery({
        queryKey: [queryKeyEmployee],
        queryFn: () =>
            getPaginatedData({
                pageType: "employee",
                page: 1,
                limit: 20,
                search: searchParamsEmployee,
                costCenterId: selectedCostCenter?._id ?? "",
            }),
    })
    const displayedAllEmployees = useMemo(() => allEmployeeData?.docs, [allEmployeeData?.docs])

    // OBJECTS and all stuff according to this
    const [searchTermObject, setsearchTermObject] = useState<string>("")
    const searchParamsObject = useDelayWhenSearchWithPagination(searchTermObject)
    const queryKeyObject = "object" + searchParamsObject
    const {
        error: allObjectsError,
        isLoading: allObjectsIsLoading,
        data: allObjectsData,
    } = useQuery({
        queryKey: [queryKeyObject],
        queryFn: () =>
            getPaginatedData({
                pageType: "object",
                page: 1,
                limit: 20,
                search: searchParamsObject,
            }),
    })
    const displayedAllObjects = useMemo(() => allObjectsData?.docs, [allObjectsData?.docs])

    // ADD TASK
    const [addTaskDataTypeSet, setaddTaskDataTypeSet] = useState<addTaskDataType | undefined>()
    const queryClient = useQueryClient()
    const { mutate: addTask } = useAddTaskMutation(queryClient, queryKeyTasks)
    const handleAddTask = () => {
        if (addTaskDataTypeSet) addTask(addTaskDataTypeSet)
        modalCon?.trigger(1)
        setaddTaskDataTypeSet(undefined)
    }

    // DELETE TASK
    const { mutate: deleteTask } = useDeleteTaskMutation(queryClient, queryKeyTasks)
    const handleDeleteTask = (id: string) => {
        deleteTask(id)
    }

    // ADD SESSION
    const [selectedEmployee, setselectedEmployee] = useState<string>()
    const navigate = useNavigate()

    // FRONTEND LOGIC
    const modalCon = useContext(ModalContext)

    return (
        <>
            {(allTasksError || costcenterError) && <ErrorPage />}
            {(allTasksIsLoading || costcenterisLoading) && <Loading />}
            {costcenterData && allTasksData && (
                <BasePage>
                    <BaseHeadBanner
                        title={"Aufgabenübersicht"}
                        button={<PLUSCIRCLE className="w-8 cursor-pointer h-8 hidden sm:block" onClick={() => modalCon?.trigger(1)} />}
                    />
                    <BaseButtonRow>
                        <div className="flex gap-2 items-center">
                            <BaseInputSearch
                                placeholder={"Aufgabe oder Objekt suchen..."}
                                searchTerm={searchTermTasks}
                                setsearchTerm={setsearchTermTasks}
                                hidden="hidden lg:flex"
                            />
                            <RenderWhenAuthorized requiresAll={[Roles.niederlassungen_read]}>
                                <BaseSelection
                                    bgwhite
                                    label={""}
                                    disabled={costcenterData.length === 1}
                                    classProps="!w-64 hidden sm:block"
                                    value={selectedCostCenter?.name}
                                    data={costcenterData?.map((item: { name: any }) => item.name)}
                                    setId={(e: string) => setselectedCostCenter(costcenterData?.find((item: { name: string }) => item.name === e))}
                                    placeholder={""}
                                />
                            </RenderWhenAuthorized>
                        </div>
                        {!allEmployeeError && !allObjectsError && allEmployeeData && allObjectsData && (
                            <div className="flex gap-2">
                                <BaseButton
                                    label={"Aufgabenvorlagen"}
                                    func={() => navigate("/aufgaben-wiederkehrend")}
                                    icon={<TASKS className="w-6 h-6" />}
                                />
                            </div>
                        )}
                    </BaseButtonRow>
                    <BaseGrowBody classProps=" overflow-x-hidden my-4">
                        <AllTasksTable tasks={displayedTasks} deleteTask={handleDeleteTask} openSelectEmployeeModal={() => modalCon?.trigger(0)} />
                    </BaseGrowBody>
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={20}
                            defaultCurrent={1}
                            current={page}
                            onChange={handlePageChange}
                            total={allTasksData?.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    {/* PAGINATION */}
                    <SelectEmployeeModal
                        modalId={0}
                        employees={displayedAllEmployees}
                        selectData={setselectedEmployee}
                        setSearch={setsearchTermEmployee}
                        customLoading={allEmployeeIsLoading}
                        onClick={() => {
                            modalCon?.trigger(0)
                            navigate("/kalender/" + selectedEmployee)
                        }}
                    />
                    <AddTaskModal
                        modalId={1}
                        customLoading={allEmployeeIsLoading || allObjectsIsLoading}
                        objects={searchTermObject !== "" ? displayedAllObjects : []}
                        selectObject={(e) => {
                            setaddTaskDataTypeSet((prev: any) => {
                                return {
                                    ...prev,
                                    object: e,
                                }
                            })
                        }}
                        setTaskData={setaddTaskDataTypeSet}
                        taskData={addTaskDataTypeSet}
                        setSearch={setsearchTermObject}
                        buttonLabel={"Aufgabe erstellen"}
                        onClick={handleAddTask}
                    />
                </BasePage>
            )}
        </>
    )
}
