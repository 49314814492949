import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"
import { returnAddressString } from "../../../../services/functions"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type FiveObjectsOrCustomerChartType = {
    statistics: any[]
    collection: "customer" | "objects"
    type: "high" | "low"
}

export default function FiveObjectsOrCustomerChart(props: FiveObjectsOrCustomerChartType) {
    const data = {
        labels: props.statistics.map((item) => (props.collection === "customer" ? item.name : returnAddressString(item.name))),
        datasets: [
            {
                data: props.statistics.map((item) => item.salesVolume),
                backgroundColor: props.type === "high" ? ["rgba(2, 163, 58, 0.3)"] : ["rgba(163, 15, 2, 0.3)"],
                borderColor: props.type === "high" ? ["rgba(2, 163, 58, 1)", "rgba(2, 133, 18, 1)"] : ["rgba(163, 15, 2, 1)"],
                borderWidth: 1,
            },
        ],
    }
    return (
        <div className="w-full bg-white rounded-default shadow-lg p-4 max-h-64 flex justify-center">
            <Bar
                data={data}
                options={{
                    indexAxis: "y" as const,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: `5 ${props.type === "high" ? "stärkste" : "schwächste"} ${
                                props.collection === "customer" ? "Kunden" : "Objekte"
                            } nach Umsatz`,
                        },
                    },
                }}
            />
        </div>
    )
}
