import dayjs from "dayjs"
import BaseTableRow from "../../../components/layout/BaseTableRow"
import { returnAddressString, translateStateOrActionCode } from "../../../services/functions"
import { OrderRequestState, OrderRequestType } from "../types"
import { Popover, Tag } from "antd"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as CLOCK } from "../../../assets/Clock.svg"

type RequestTableRowType = {
    request: OrderRequestType
    handleUpdateOrderRequestState: (e: string, f: string) => void
}

export default function RequestTableRow(props: RequestTableRowType) {
    return (
        <BaseTableRow url={"/anfragen/" + props.request._id}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{props.request.requestTask}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {props.request.object ? returnAddressString(props.request.object) : props.request.objectFromCustomer}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{dayjs(props.request.createdAt).format("DD.MM.YYYY")}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{dayjs(props.request.dueDate).format("DD.MM.YYYY")}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {props.request.priceForRelease
                        ? props.request.priceForRelease?.toFixed(2)?.replace(".", ",")
                        : props.request.priceForReleaseFromCustomer?.toFixed(2)?.replace(".", ",")}{" "}
                    €
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag
                        className="w-32 text-center"
                        color={
                            props.request.state === "created"
                                ? "blue"
                                : props.request.state === "declined"
                                ? "red-inverse"
                                : props.request.state === "completed"
                                ? "green"
                                : props.request.state === "completed-and-billed"
                                ? "green-inverse"
                                : "gold"
                        }>
                        {translateStateOrActionCode(props.request.state, OrderRequestState)}
                    </Tag>
                </p>
            </td>
            <td className="hidden lg:table-cell" onClick={(event) => event.stopPropagation()}>
                {props.request.state === "created" && (
                    <Popover
                        placement="bottomLeft"
                        title="Ablehnen?"
                        content={
                            <div className="flex flex-col items-end">
                                <p>Sie lehnen die Anfrage ab. Der Kunde wird automatisch informiert.</p>
                                <button
                                    onClick={() => props.handleUpdateOrderRequestState("declined", props.request._id)}
                                    className="btn btn-sm btn-primary w-fit mt-1">
                                    OK
                                </button>
                            </div>
                        }>
                        <DELETE className="w-6 h-6 cursor-pointer" />
                    </Popover>
                )}
                {props.request.state === "accepted" && (
                    <CLOCK
                        onClick={() => props.handleUpdateOrderRequestState("waiting-for-customer-approval", props.request._id)}
                        className="w-6 h-6 cursor-pointer"
                    />
                )}
            </td>
        </BaseTableRow>
    )
}
