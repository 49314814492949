import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import { ReactComponent as PLUSCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import AllTasksTable from "../components/AllTasksTable"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getPaginatedData } from "../../api/apiCalls"
import { useContext, useEffect, useMemo, useState } from "react"
import AddTaskModal from "../components/AddTaskModal"
import { addTaskTemplateDataType } from "../types"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import { useAddTaskTemplateMutation, useDeleteTaskTemplateMutation, useUpdateTaskTemplateStateMutation } from "../api/services"
import BaseInputSearch from "../../../components/elements/BaseInputSearch"
import { getCostCenterData } from "../../Mitarbeiter/api/apiCalls"
import { CostCenterType } from "../../Mitarbeiter/types/Types"
import BaseSelection from "../../../components/elements/BaseSelection"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { Pagination } from "antd"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"

const defaultValues: addTaskTemplateDataType = {
    activity: "",
    description: "",
    object: "",
    state: "active",
    priority: "default",
    estimatedDurationMinutes: 0,
    recurrenceCreationPattern: {
        showDaysBeforeDueDate: 7,
        interval: "1",
        recurrenceType: "week",
        endByDate: undefined,
    },
    costCenter: "677bb47978eea6ab373a5371",
}

export default function TaskTemplateOverviewPage() {
    // GET all costcenter
    const {
        error: costcenterError,
        data: costcenterData,
        isLoading: costcenterisLoading,
    } = useQuery({
        queryKey: ["cost-center-all"],
        queryFn: getCostCenterData,
    })
    const [selectedCostCenter, setselectedCostCenter] = useState<CostCenterType>()
    useEffect(() => costcenterData && setselectedCostCenter(costcenterData[0]), [costcenterData])

    // GET Task Templates
    const [page, setpage] = useState<number>(1)
    const [searchTermTaskTemplates, setsearchTermTaskTemplates] = useState("")
    const searchParamsTaskTemplates = useDelayWhenSearchWithPagination(searchTermTaskTemplates)
    const queryKeyTaskTemplates = "taskTemplates" + searchParamsTaskTemplates + selectedCostCenter?._id
    const {
        error: allTaskTemplatesError,
        isLoading: allTaskTemplatesIsLoading,
        data: allTaskTemplatesData,
    } = useQuery({
        queryKey: [queryKeyTaskTemplates],
        queryFn: () =>
            getPaginatedData({
                pageType: "task-template",
                page: page,
                limit: 20,
                search: searchParamsTaskTemplates,
                costCenterId: selectedCostCenter?._id ?? "",
            }),
    })
    const displayedTaskTemplates = useMemo(() => allTaskTemplatesData?.docs, [allTaskTemplatesData?.docs])

    // OBJECTS and all stuff according to this
    const [searchTermObject, setsearchTermObject] = useState<string>("")
    const searchParamsObject = useDelayWhenSearchWithPagination(searchTermObject)
    const queryKeyObject = "object" + searchParamsObject
    const {
        error: allObjectsError,
        isLoading: allObjectsIsLoading,
        data: allObjectsData,
    } = useQuery({
        queryKey: [queryKeyObject],
        queryFn: () =>
            getPaginatedData({
                pageType: "object",
                page: 1,
                limit: 20,
                search: searchParamsObject,
            }),
    })
    const displayedAllObjects = useMemo(() => allObjectsData?.docs, [allObjectsData?.docs])

    // ADD Task Template
    const [addTaskTemplateDataTypeSet, setaddTaskTemplateDataTypeSet] = useState<addTaskTemplateDataType>(defaultValues)
    const queryClient = useQueryClient()
    const { mutate: addTaskTemplate } = useAddTaskTemplateMutation(queryClient, queryKeyTaskTemplates)
    const handleAddTaskTemplate = () => {
        console.log(addTaskTemplateDataTypeSet)
        addTaskTemplate(addTaskTemplateDataTypeSet)
        modalCon?.trigger(1)
        setaddTaskTemplateDataTypeSet(defaultValues)
    }

    const modalCon = useContext(ModalContext)

    // DELETE Task template
    const { mutate: deleteTaskTemplate } = useDeleteTaskTemplateMutation(queryClient, queryKeyTaskTemplates)

    // UPDATE Task template state
    const { mutate: updateTaskTemplateState } = useUpdateTaskTemplateStateMutation(queryClient, queryKeyTaskTemplates)

    return (
        <>
            {(costcenterError || allTaskTemplatesError) && <ErrorPage />}
            {(costcenterisLoading || allTaskTemplatesIsLoading) && <Loading />}
            {costcenterData && allTaskTemplatesData && (
                <BasePage>
                    <BaseHeadBanner
                        title={"Wiederkehrende Aufgaben"}
                        button={
                            !allObjectsError && <PLUSCIRCLE onClick={() => modalCon?.trigger(0)} className="cursor-pointer w-8 h-8 hidden md:block" />
                        }
                    />
                    <BaseButtonRow>
                        <div className="flex gap-2 items-center">
                            <BaseInputSearch
                                placeholder={"Objekt suchen..."}
                                hidden="hidden md:flex"
                                searchTerm={searchTermTaskTemplates}
                                setsearchTerm={setsearchTermTaskTemplates}
                            />
                            <RenderWhenAuthorized requiresAll={[Roles.niederlassungen_read]}>
                                <BaseSelection
                                    bgwhite
                                    label={""}
                                    classProps="!w-64 hidden sm:block"
                                    disabled={costcenterData.length === 1}
                                    value={selectedCostCenter?.name}
                                    data={costcenterData?.map((item: { name: any }) => item.name)}
                                    setId={(e: string) => setselectedCostCenter(costcenterData?.find((item: { name: string }) => item.name === e))}
                                    placeholder={""}
                                />
                            </RenderWhenAuthorized>
                        </div>
                    </BaseButtonRow>
                    <BaseGrowBody classProps=" overflow-x-hidden my-4">
                        <AllTasksTable taskTemplates={displayedTaskTemplates} addOrPause={updateTaskTemplateState} deleteTask={deleteTaskTemplate} />
                    </BaseGrowBody>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={20}
                            defaultCurrent={1}
                            current={page}
                            onChange={setpage}
                            total={allTaskTemplatesData.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    <AddTaskModal
                        modalId={0}
                        customLoading={allObjectsIsLoading}
                        objects={searchTermObject !== "" ? displayedAllObjects : []}
                        selectObject={(e) =>
                            setaddTaskTemplateDataTypeSet((prev: addTaskTemplateDataType) => ({
                                ...prev,
                                object: e,
                            }))
                        }
                        setTaskTemplateData={setaddTaskTemplateDataTypeSet}
                        buttonLabel={"Wiederkehrende Aufgabe erstellen"}
                        setSearch={setsearchTermObject}
                        onClick={handleAddTaskTemplate}
                        setTaskData={undefined}
                        taskTemplateData={addTaskTemplateDataTypeSet}
                    />
                </BasePage>
            )}
        </>
    )
}
