import { ReactNode, useState } from "react"
import BaseSidePanel from "../layout/BaseSidePanel"
import parse from "html-react-parser"
import { Document, Page } from "react-pdf"
import { ReactComponent as CloseICON } from "../../assets/CloseICON.svg"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { authenticatedAxiosInstance } from "../../auth/AuthenticatedAxios"

type BaseMailPreviewType = {
    title: string
    sidePanelId: number
    onClose?: () => void
    previewType: "mail" | "document" | "image"
    content?: string | ArrayBuffer
    customSendSection?: ReactNode
    resendFunction?: () => void
}

export default function BasePreview(props: BaseMailPreviewType) {
    const [buttonCheck, setbuttonCheck] = useState<boolean>(false)
    const [numPages, setNumPages] = useState<number | null>(null)

    const submitResend = () => {
        if (props.resendFunction) {
            props.resendFunction()
            setbuttonCheck(false)
        }
    }
    return (
        <BaseSidePanel
            title={props.title}
            sidePanelId={props.sidePanelId}
            onClose={props.onClose}
            bottomRow={
                props.resendFunction &&
                (!buttonCheck ? (
                    <button onClick={() => setbuttonCheck(true)} className="btn btn-primary btn-sm">
                        Erneut Senden
                    </button>
                ) : (
                    <div className="flex gap-2 flex-col">
                        {props.customSendSection}

                        <div className="flex gap-2 items-center justify-end">
                            <button className="btn btn-square btn-ghost" onClick={() => setbuttonCheck(false)}>
                                <CloseICON className="w-7 h-7" />
                            </button>
                            <p className="text-xs max-w-xs text-end">
                                Achtung! Der Kunde erhält die entsprechende Mail inkl. aller angehangenen Dokumente erneut!
                            </p>
                            <button onClick={submitResend} className="btn btn-success btn-sm">
                                Bestätigen
                            </button>
                        </div>
                    </div>
                ))
            }>
            {props.content && props.previewType === "mail" && typeof props.content === "string" && parse(props.content)}
            {props.content && props.previewType === "document" && (
                <Document
                    file={props.content}
                    options={{
                        httpHeaders: {
                            Authorization: authenticatedAxiosInstance.defaults.headers.common["Authorization"],
                        },
                    }}
                    onLoadError={(error) => {
                        console.error("Error Loading Document!:", error)
                    }}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {numPages &&
                        Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
                            <div className="flex justify-center w-full flex-col">
                                <Page className={"shadow-md shadow-slate-400 m-6"} width={600} pageNumber={page} />
                                <div className="flex justify-center w-full text-sm ">
                                    <p>
                                        Seite {page} von {numPages}
                                    </p>
                                </div>
                            </div>
                        ))}
                </Document>
            )}
        </BaseSidePanel>
    )
}
