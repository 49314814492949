import BaseSection from "../../../components/elements/BaseSection"
import { ReactComponent as STATISTIC } from "../../../assets/Statistics.svg"
import BaseStatisticCard from "../../../components/elements/BaseStatisticCard"

type EmployeeStatisticsType = {
    statistics: {
        averageSessionTime: number
        averageSessionTimeVsEstimation: number
        sessionDone: number
        sessionFailed: number
        tasksDone: number
        tasksFailed: number
    }
}

export default function EmployeeStatistics(props: EmployeeStatisticsType) {
    return (
        <BaseSection noBackground>
            {props.statistics === null || !props.statistics ? (
                <div className="flex flex-col justify-center items-center font-semibold h-full">
                    <STATISTIC className="w-8 h-8" />
                    <p>Für diesen Mitarbeiter wurden noch keine Daten erfasst.</p>
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-4">
                    <BaseStatisticCard
                        value={props.statistics.sessionDone?.toString()}
                        text={"Anzahl der abgeschlossenen Termine der letzten 90 Tage"}
                    />
                    <BaseStatisticCard
                        value={props.statistics.sessionFailed?.toString()}
                        text={"Anzahl der fehlgeschlagenen Termine der letzten 90 Tage"}
                    />
                    <BaseStatisticCard
                        value={props.statistics.tasksDone?.toString()}
                        text={"Anzahl der abgeschlossenen Aufgaben der letzten 90 Tage"}
                    />
                    <BaseStatisticCard
                        value={props.statistics.tasksFailed?.toString()}
                        text={"Anzahl der fehlgeschlagenen Aufgaben der letzten 90 Tage"}
                    />
                    <BaseStatisticCard
                        value={props.statistics.averageSessionTime?.toString() + " min"}
                        text={"Durchschnittliche Termindauer in den letzten 90 Tagen"}
                    />
                    <BaseStatisticCard
                        value={(props.statistics.averageSessionTimeVsEstimation * 100).toFixed(0).toString().replace(".", ",") + "%"}
                        text={"Durchschnittliche Abwweichung von geschätzter und tatsächlicher Termindauer in den letzten 90 Tagen"}
                    />
                </div>
            )}
        </BaseSection>
    )
}
