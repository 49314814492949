import dayjs from "dayjs"
import BaseTableRow from "../../../components/layout/BaseTableRow"
import { returnAddressString } from "../../../services/functions"
import { ObjectType } from "../../Objekte/types/types"
import { getSingleTaskType } from "../types"
import { Popover, Tag } from "antd"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as CALENDAR } from "../../../assets/CalendarICON.svg"

type TaskTableRowType = {
    task: getSingleTaskType
    deleteTask: (e: string) => void
    openSelectEmployeeModal?: () => void
}

export default function TaskTableRow(props: TaskTableRowType) {
    return (
        <BaseTableRow url={"/aufgaben/" + props.task._id}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{props.task.activity}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {typeof props.task.object !== "string" && returnAddressString(props.task.object as ObjectType)}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{dayjs(props.task.createAt).format("DD.MM.YYYY")}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{dayjs(props.task.dueAt).format("DD.MM.YYYY")}</p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag className="w-20 text-center" color={props.task.priority === "default" ? "blue" : "red-inverse"}>
                        {props.task.priority === "default" ? "Standard" : "Hoch"}
                    </Tag>
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {" "}
                    <Tag
                        className="w-32 text-center"
                        color={props.task.state === "created" ? "blue" : props.task.state === "execution-failed" ? "red-inverse" : "gold"}>
                        {props.task.state === "created"
                            ? "Erstellt"
                            : props.task.state === "execution-failed"
                            ? "Zurückgewiesen"
                            : props.task.state === "unassigned"
                            ? "Zuweisung entfernt"
                            : "Wartet auf Kunde"}
                    </Tag>
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    <Tag className="w-24 text-center" color={props.task.taskFrequency === "once" ? "" : "grey"}>
                        {props.task.taskFrequency === "once" ? "Einmalig" : "Wiederkehrend"}
                    </Tag>
                </p>
            </td>
            <td className="z-10" onClick={(event) => event.stopPropagation()}>
                <CALENDAR
                    className="w-6 h-6 cursor-pointer z-10"
                    onClick={(event) => {
                        event.stopPropagation()
                        if (props.openSelectEmployeeModal) props.openSelectEmployeeModal()
                    }}
                />
            </td>
            <td onClick={(event) => event.stopPropagation()}>
                <Popover
                    placement="bottomLeft"
                    title="Löschen?"
                    content={
                        <div className="flex flex-col items-end">
                            <p>Wollen Sie diese Aufgabe wirklich löschen?</p>
                            <button onClick={() => props.deleteTask(props.task._id)} className="btn btn-sm btn-primary w-fit mt-1">
                                OK
                            </button>
                        </div>
                    }>
                    <DELETE className="w-6 h-6 cursor-pointer z-10" />
                </Popover>
            </td>
        </BaseTableRow>
    )
}
