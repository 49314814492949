import { ReactNode } from "react"

type BaseSectionType = {
    noBackground?: boolean
    children: ReactNode
}

export default function BaseSection(props: BaseSectionType) {
    return (
        <section
            className={`rounded-default flex flex-col w-full grow overflow-x-hidden overflow-y-scroll ${
                props.noBackground ?? "bg-white shadow-lg p-4 "
            }`}>
            {props.children}
        </section>
    )
}
