import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

type BaseTableRowType = {
    url: string
    classProps?: string
    children: ReactNode
}

export default function BaseTableRow(props: BaseTableRowType) {
    const navigate = useNavigate()

    return (
        <tr
            onClick={() => navigate(props.url)}
            className={`z-0 border-b hover:bg-slate-50 border-gray-200 cursor-pointer text-sm h-14 ${props.classProps}`}>
            {props.children}
        </tr>
    )
}
