import { useCallback, useContext, useEffect, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { motion } from "framer-motion"
import { ReiterType } from "../types"
import { useKeycloak } from "@react-keycloak/web"
import { Roles } from "../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../authentication/RenderWhenAuthorized"
import { ReactComponent as LOGOUT } from "../../assets/LogoutICON.svg"
import { ReactComponent as ARROWLEFT } from "../../assets/ChevronLeft.svg"
import { ReactComponent as BACKARROWLEFT } from "../../assets/BackArrowLeft.svg"
import { ReactComponent as ARROWRIGHT } from "../../assets/ChevronRight.svg"
import { ReactComponent as HELP } from "../../assets/Help.svg"
import { ReactComponent as HOUSE } from "../../assets/HouseICON.svg"
import { ReactComponent as BREAFCASE } from "../../assets/Breafcase.svg"
import { ReactComponent as TASKS } from "../../assets/Task.svg"
import { ReactComponent as PEOPLE } from "../../assets/People.svg"
import { ReactComponent as CASH } from "../../assets/Cash.svg"
import { ReactComponent as DASHBOARD } from "../../assets/Dashboard.svg"
import { ReactComponent as CALENDAR } from "../../assets/CalendarICON.svg"
import { ReactComponent as BELL } from "../../assets/Bell.svg"
import { useLocation, useNavigate } from "react-router-dom"
import "../../style/pseudo.css"
import { SelfUserContext } from "../contexts/SelfUserContext"

export default function Header() {
    const { keycloak } = useKeycloak()
    const userSelf = useContext(SelfUserContext)

    const [mini, setmini] = useState(false)

    const [url, seturl] = useState("")

    const location = useLocation()
    useEffect(() => {
        seturl(window.location.href)
    }, [location])

    // tracking page
    const isPageCurrent = useCallback((page: string) => location.pathname.includes(page), [location])

    // track sizing
    const [windowSize, setwindowSize] = useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setwindowSize(window.innerWidth)
            window.innerWidth <= 1024 && setmini(true)
        }

        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    // Navigation
    const navigate = useNavigate()
    const handleNavigateBack = () => {
        navigate(-1)
    }

    return (
        <>
            <motion.div
                initial={{
                    x: windowSize > 1024 ? 0 : "-16rem",
                    width: windowSize < 640 ? "0rem" : windowSize > 1024 ? "1rem" : "16rem",
                }}
                animate={{
                    x: !mini && windowSize <= 1024 ? 0 : 0,
                    width:
                        windowSize < 640 && mini
                            ? "0rem"
                            : mini && windowSize <= 1024
                            ? "1rem"
                            : !mini && windowSize <= 1024
                            ? "16rem"
                            : mini && windowSize > 1024
                            ? "4rem"
                            : "16rem",
                }}
                transition={{
                    duration: 0.5,
                    type: "ease",
                }}
                className="h-screen bg-primary flex flex-col items-center pt-16 z-10 absolute lg:relative">
                <motion.button
                    initial={{
                        bottom: windowSize >= 640 ? "8rem" : undefined,
                        top: windowSize < 640 ? "2rem" : undefined,
                    }}
                    animate={{
                        bottom: windowSize >= 640 ? (mini ? "8rem" : "5rem") : undefined,
                        // top: windowSize < 640 && mini ? "8rem" : "5rem",
                    }}
                    transition={{
                        duration: 0.5,
                        type: "ease",
                    }}
                    className={`text-base-100 absolute z-20 border ${windowSize >= 1024 ? "p-3" : "p-2"} rounded-default bg-primary ${
                        windowSize >= 640 ? "-right-5" : "-right-6"
                    } `}
                    onClick={() => setmini(!mini)}>
                    {!mini ? (
                        <ARROWLEFT className={windowSize >= 1024 ? "w-6 h-6" : "w-4 h-4"} />
                    ) : (
                        <ARROWRIGHT className={windowSize >= 1024 ? "w-6 h-6" : "w-4 h-4"} />
                    )}
                </motion.button>
                <div
                    onClick={handleNavigateBack}
                    className={`${
                        !mini && " gap-1 left-4  border-b border-base-100"
                    } flex text-base-100 absolute top-4  text-xs items-center justify-center cursor-pointer`}>
                    <BACKARROWLEFT className={`${mini ? "w-5 h-5" : "w-3.5 h-3.5"} font-semibold`} />
                    {!mini && <p className="pb-[0.07rem]">zurück</p>}
                </div>
                {!mini && (
                    <div className="w-[80%] mx-auto h-fit p-4 bg-white rounded-default">
                        <img alt="Besitzwerk Logo" className=" object-cover" src="/besitzwerk_logo-removebg.png" />
                    </div>
                )}
                <div className={`flex flex-col w-full h-fit pt-4 items-center`}>
                    <RenderWhenAuthorized requiresAll={[Roles.termine_execute]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            responsive={windowSize <= 1024}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/kalendar/" + userSelf?.user._id)
                            }}
                            text="Kalender"
                            link={"/kalender/" + userSelf?.user?._id}
                            open={isPageCurrent("/kalender") || isPageCurrent("/termin")}
                            icon={<CALENDAR className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.dashboard_read]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/dashboard")
                            }}
                            text="Dashboard"
                            link="/dashboard"
                            responsive={windowSize <= 1024}
                            open={isPageCurrent("/dashboard")}
                            icon={<DASHBOARD className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/buchhaltung")
                            }}
                            text="Buchhaltung"
                            link="/buchhaltung"
                            responsive={windowSize <= 1024}
                            open={isPageCurrent("/buchhaltung")}
                            icon={<CASH className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized
                        requiresAll={[Roles.aufgaben_read_write, Roles.aufgaben_create, Roles.aufgaben_delete, Roles.aufgaben_read]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            responsive={windowSize <= 1024}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/aufgaben")
                            }}
                            text="Aufgaben"
                            link="/aufgaben"
                            open={isPageCurrent("/aufgaben")}
                            icon={<TASKS className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            responsive={windowSize <= 1024}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/anfragen")
                            }}
                            text="Anfragen"
                            link="/anfragen"
                            open={isPageCurrent("/anfragen")}
                            icon={<BELL className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read]}>
                        <NavReiter
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            responsive={windowSize <= 1024}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/mitarbeiter")
                            }}
                            text="Mitarbeiter"
                            link="/mitarbeiter"
                            open={isPageCurrent("/mitarbeiter")}
                            icon={<PEOPLE className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.objekte_read]}>
                        <NavReiter
                            responsive={windowSize <= 1024}
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/objekte")
                            }}
                            text="Objekte"
                            link="/objekte"
                            open={isPageCurrent("/objekte")}
                            icon={<HOUSE className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_read]}>
                        <NavReiter
                            responsive={windowSize <= 1024}
                            trigger={() => windowSize <= 1024 && setmini(!mini)}
                            url={url}
                            mini={mini}
                            func={() => {
                                seturl("/kunden")
                            }}
                            text="Kunden"
                            link="/kunden"
                            open={isPageCurrent("/kunden")}
                            icon={<BREAFCASE className="w-6 h-6" />}
                        />
                    </RenderWhenAuthorized>
                </div>
                <div className="flex flex-col absolute bottom-4 w-full">
                    <NavReiter
                        responsive={windowSize <= 1024}
                        trigger={() => windowSize <= 1024 && setmini(!mini)}
                        mini={mini}
                        func={() => {}}
                        text="Hilfe"
                        link="/hilfe"
                        open={isPageCurrent("/hilfe")}
                        icon={<HELP className="w-6 h-6" />}
                    />
                    <NavReiter
                        responsive={windowSize <= 1024}
                        trigger={() => windowSize <= 1024 && setmini(!mini)}
                        mini={mini}
                        func={() => {
                            keycloak.logout()
                        }}
                        text="Logout"
                        extendText={keycloak?.idTokenParsed?.email}
                        truncate
                        link="/loading"
                        open={isPageCurrent("/logout")}
                        icon={<LOGOUT className="w-6 h-6" />}
                    />
                </div>
            </motion.div>
        </>
    )
}

function NavReiter(props: ReiterType) {
    return (
        <HashLink to={props.link} className="w-full">
            <motion.div
                initial={{
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                }}
                animate={{
                    paddingLeft: props.mini ? "1rem" : "2rem",
                    paddingRight: props.mini ? "1rem" : "2rem",
                }}
                onClick={() => {
                    props.func()
                    props.trigger(true)
                }}
                className={`w-full text-base 2xl:text-lg text-base-100 flex h-12 2xl:h-14 cursor-pointer items-center  ${
                    props.mini ? "justify-center" : "justify-start"
                }
                ${
                    !props.responsive &&
                    (props.open || props?.url?.endsWith(props.link)
                        ? "bg-base-100 text-primary ml-2 rounded-l-default relative -z-10"
                        : " hover:bg-black/10")
                }
                `}>
                {!props.responsive && (props.open || props?.url?.endsWith(props.link)) && (
                    <>
                        <div className={`bg-none w-fit h-fit absolute right-[0.48rem] ${props.mini ? "-top-[0.99rem]" : "-top-[0.98rem]"}`}>
                            <div className="upper_corner bg-primary h-full w-full" />
                        </div>
                        <div className="bg-none w-fit h-fit absolute right-[0.48rem] -bottom-[0.98rem]">
                            <div className="bottom_corner bg-primary h-full w-full" />
                        </div>
                    </>
                )}
                {props.icon}
                {!props.mini && (
                    <p className={`pl-4 font-semibold leading-4 text-base ${props.truncate && "truncate"}`}>
                        {props.text}{" "}
                        {props.extendText && (
                            <span className="text-xs font-normal 2xl:text-sm">
                                <br />
                                {props.extendText}
                            </span>
                        )}
                    </p>
                )}
            </motion.div>
        </HashLink>
    )
}
