import dayjs from "dayjs"
import { BaseDocumentElementType } from "../types"
import { RenderWhenAuthorized } from "../authentication/RenderWhenAuthorized"
import { ReactComponent as EyeICON } from "../../assets/EyeICON.svg"
import { ReactComponent as DownloadICON } from "../../assets/DownloadICON.svg"
import { ReactComponent as DeleteICON } from "../../assets/DeleteICON.svg"
import BasePopover from "./BasePopover"

export default function BaseDocumentElement(props: BaseDocumentElementType) {
    return (
        <div className={`flex flex-row w-full py-1 px-4 justify-between items-center ${!props.obv && "border-b"}`}>
            <div className="flex items-center gap-2">
                <p className="truncate text-sm max-w-[16rem]">{props.title}</p>
                <p className="text-gray-400 text-xs">{dayjs(props.timestamp).format("DD.MM.YYYY")}</p>
            </div>
            {!props.disableAllActions && (
                <div className="flex items-center">
                    <DownloadICON onClick={props.downloadFunc} className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
                    {props.title?.endsWith(".pdf") && (
                        <EyeICON onClick={props.showPreviewFunc} className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
                    )}
                    {!props.obv && (
                        <RenderWhenAuthorized requiresAll={props.roles ?? []}>
                            {props.deleteFunc && (
                                <BasePopover
                                    label={"Wirklich löschen?"}
                                    placement="topLeft"
                                    okButtonClick={props.deleteFunc}>
                                    <DeleteICON className="w-9 h-9 cursor-pointer hover:bg-gray-200 p-2 rounded-full" />
                                </BasePopover>
                            )}
                        </RenderWhenAuthorized>
                    )}
                </div>
            )}
        </div>
    )
}
