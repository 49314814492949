import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"
import { CustomChartType } from "../../types"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function CostCenterSalesChart(props: CustomChartType) {
    const data = {
        labels: Object.keys(props.paymentStatistics), // Extract labels (keys)
        datasets: [
            {
                data: Object.values(props.paymentStatistics),
                backgroundColor: "rgba(5, 105, 168, 0.6)",
                borderColor: "rgba(5, 105, 168, 1)",
                borderWidth: 1,
            },
        ],
    }
    return (
        <div className="w-full bg-white rounded-default shadow-lg p-4 max-h-64 flex justify-center">
            <Bar
                data={data}
                options={{
                    indexAxis: "y" as const,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Umsatz nach Niederlassung (365 Tage) in Euro",
                        },
                    },
                }}
            />
        </div>
    )
}
