import { useContext, useEffect, useMemo, useState } from "react"
import BasePage from "../../../../components/layout/BasePage"
import BaseHeadBanner from "../../../../components/elements/BaseHeadBanner"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleObjectData } from "../../api/apiCalls"
import { useParams } from "react-router-dom"
import ErrorPage from "../../../404/ErrorPage"
import Loading from "../../../../components/common/Loading"
import { ObjectType } from "../../types/types"
import { useAddDocToObjectMutation, useGenerateQRCodeOfObjectMutation, usePatchDocObjectMutation, useUpdateObjectMutation } from "../../api/services"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { getBillByObject, getOfferByObject } from "../../../Buchhaltung/api/apiCalls"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import BaseAddDocumentModal from "../../../../components/elements/BaseAddDocumentModal"
import ModalCheckAction from "../../../../components/elements/ModalCheckAction"
import { useDownloadDocumentMutation } from "../../../api/services"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import ObjectDetailPartRight from "./ObjectDetailPartRight"
import ObjectDetailPartLeft from "./ObjectDetailPartLeft"
import EditLvModal from "../../components/EditLvModal"
import BasePreview from "../../../../components/elements/BasePreview"
import { API } from "../../../../api/constants"
import EditOBVModal from "../../components/EditOBVModal"
import { getCostCenterData } from "../../../Mitarbeiter/api/apiCalls"
import QrCodeModal from "../../components/QrCodeModal"
import { getPaginatedData } from "../../../api/apiCalls"
import { Form } from "antd"
import { Customer } from "../../../Kunden/types/Types"
import BaseGrowBody from "../../../../components/elements/BaseGrowBody"
import ObjectDetailStatistics from "./ObjectDetailStatistics"
import { getObjectStatistics } from "../../../Dashboard/api/apiCalls"
export default function ObjektDetailPage() {
    const { id } = useParams()
    const { data, error, isLoading } = useQuery({
        queryKey: ["singleObjectData" + id],
        queryFn: () => getSingleObjectData(id ?? ""),
    })
    const [displayedObject, setdisplayedObject] = useState<ObjectType>()
    useEffect(() => setdisplayedObject(data), [data])

    // Get Customer For Change Customer - Data Query
    const [searchTerm, setsearchTerm] = useState<string>()
    const searchParams = useDelayWhenSearchWithPagination(searchTerm ?? "")
    const queryKey = "customerData" + 1 + searchParams
    const {
        error: allcustomerError,
        data: allcustomerData,
        isLoading: allcustomerIsloading,
    } = useQuery({
        queryKey: [queryKey],
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 20, search: searchParams, archived: false, deleted: false }),
    })

    // get all costcenter
    const {
        error: costcenterError,
        data: costcenterData,
        isLoading: costcenterisLoading,
    } = useQuery({
        queryKey: ["cost-center-all"],
        queryFn: getCostCenterData,
    })
    // Offers of objects
    const {
        data: objectsOffer,
        error: objErrOffer,
        isLoading: loadObjectsOffer,
    } = useQuery({
        queryKey: ["offerObjectData" + id],
        queryFn: () => getOfferByObject(id || ""),
    })

    // Bills of objects
    const {
        data: objectsBill,
        error: objErrBill,
        isLoading: loadObjectsBill,
    } = useQuery({
        queryKey: ["billObjectData" + id],
        queryFn: () => getBillByObject(id || ""),
    })

    // STATISTICS OF OBJECT
    const {
        data: objectStatistics,
        error: errorObjectStatistics,
        isLoading: isLoadingObjectStatistics,
    } = useQuery({
        queryKey: ["objectStatistics" + id],
        queryFn: () => getObjectStatistics(id || ""),
    })

    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const objectDownloadUrl = useMemo(() => {
        return error || isLoading || !selectedS3Key ? undefined : API + `/object/${id}/download?s3Key=${selectedS3Key}`
    }, [error, isLoading, selectedS3Key, id])

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // add file
    const { mutate: addDoc } = useAddDocToObjectMutation(queryClient)

    // generate QR Code
    const { mutate: generateQRcode, status: statusQRCodeGeneration } = useGenerateQRCodeOfObjectMutation(queryClient)

    // download file from object
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: data._id, s3Key: s3Key, filetype: "object", filename: filename })
    }

    // delete file
    const [fileToDelete, setfileToDelete] = useState<string>("")
    const { mutate: deleteDoc } = usePatchDocObjectMutation(queryClient, modalCon, 4)
    const handleDeleteDoc = () => {
        deleteDoc({
            id: id ?? "",
            file: fileToDelete,
        })
    }

    // update object
    const { mutate: updateObjectQuery } = useUpdateObjectMutation(queryClient)
    const handleUpdateObject = () => {
        console.log("Test")
        if (displayedObject) {
            const updatedObjectData: ObjectType = displayedObject
            updateObjectQuery({
                id: id ?? "",
                body: updatedObjectData,
            })
        }
    }

    // FRONTEND LOGIC
    const [form] = Form.useForm()
    useEffect(() => {
        if (displayedObject) {
            form.setFieldsValue({
                costcenter: costcenterData.find((item: { _id: any }) => item._id === displayedObject.costCenter)?.name ?? "",
                street: displayedObject.adress.street,
                nr: displayedObject.adress.nr,
                plz: displayedObject.adress.plz,
                city: displayedObject.adress.city,
            })
        }
    }, [displayedObject])

    return (
        <BasePage>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && displayedObject && !error && !isLoading && (
                <>
                    <Form form={form} onFinish={handleUpdateObject} className="w-full h-full overflow-hidden flex flex-col gap-4">
                        <BaseHeadBanner
                            title={
                                data.adress?.street +
                                " " +
                                data.adress?.nr +
                                ", " +
                                data.adress?.plz +
                                " " +
                                data.adress?.city +
                                " | Nummer: " +
                                (data?.nr ?? "404")
                            }
                            button={
                                <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                                    <div className="hidden md:flex flex-row w-fit items-center gap-2">
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={1}
                                            className="radio radio-success"
                                            checked={displayedObject?.ranking === 1}
                                            onChange={(e) =>
                                                setdisplayedObject((prev: any) => ({
                                                    ...prev,
                                                    ranking: Number(e.target.value),
                                                }))
                                            }
                                        />
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={2}
                                            className="radio radio-warning"
                                            checked={displayedObject?.ranking === 2}
                                            onChange={(e) =>
                                                setdisplayedObject((prev: any) => ({
                                                    ...prev,
                                                    ranking: Number(e.target.value),
                                                }))
                                            }
                                        />
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={3}
                                            className="radio radio-error"
                                            checked={displayedObject?.ranking === 3}
                                            onChange={(e) =>
                                                setdisplayedObject((prev: any) => ({
                                                    ...prev,
                                                    ranking: Number(e.target.value),
                                                }))
                                            }
                                        />

                                        <button disabled={data === displayedObject} className="btn btn-success ml-4" type="submit">
                                            Speichern
                                        </button>
                                    </div>
                                </RenderWhenAuthorized>
                            }
                        />
                        <BaseGrowBody classProps="flex gap-4 pb-4">
                            {/* LEFT */}
                            <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write, Roles.buchhaltung_read]}>
                                <ObjectDetailPartLeft
                                    s3RefIsExisting={data?.s3ImageRef?.s3Key !== undefined}
                                    id={data._id}
                                    lvdoesnotexist={!data.listOfServices}
                                    obvdoesexist={!data.serviceContract?.created}
                                    customerOfObject={(displayedObject.customer_id as Customer)?.customer?.name}
                                    customerId={(displayedObject.customer_id as Customer)?._id ?? ""}
                                    searchTermCustomer={searchTerm}
                                    customerData={allcustomerData?.docs ?? []}
                                    setsearchTermCustomer={setsearchTerm}
                                    setobjectData={setdisplayedObject}
                                    objectsOffer={objectsOffer}
                                    objectsBill={objectsBill}
                                />
                            </RenderWhenAuthorized>
                            {/* MIDDLE */}
                            <ObjectDetailPartRight
                                objectData={displayedObject as ObjectType}
                                setobjectData={setdisplayedObject}
                                costCenterData={costcenterData}
                                setselectedS3Key={setselectedS3Key}
                                handleDownload={handleDownload}
                                setfileToDelete={setfileToDelete}
                                documents={data.s3Documents}
                            />
                            {/* RIGHT */}
                            <ObjectDetailStatistics statistics={objectStatistics} />
                        </BaseGrowBody>
                    </Form>
                    <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(3)} modalId={3} selectedId={id ?? ""} />
                    <ModalCheckAction
                        modalId={4}
                        headline={"Dokument löschen?"}
                        text={"Sind Sie sich sicher, dass Sie dieses Dokument wollen?"}
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={handleDeleteDoc}
                    />
                    <EditLvModal modalId={5} objectId={data._id} data={data.listOfServices} />
                    <BasePreview title={"Dokumentvorschau"} sidePanelId={6} previewType={"document"} content={objectDownloadUrl} />
                    {!data.serviceContract?.created && (
                        <EditOBVModal
                            modalId={7}
                            objectId={data._id}
                            data={data.listOfServices}
                            object={data.adress?.street + " " + data.adress?.nr + ", " + data.adress?.plz + " " + data.adress?.city}
                            customer={data.customer_id}
                        />
                    )}
                    <QrCodeModal
                        modalId={8}
                        qrcode={data.qrCodeID}
                        adress={data.adress.street + data.adress.nr + "." + data.adress.plz + data.adress.city}
                        generateQRcode={() => generateQRcode({ id: data._id })}
                        statusQRCodeGeneration={statusQRCodeGeneration}
                    />
                </>
            )}
        </BasePage>
    )
}
