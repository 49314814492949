import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { BillTemplateGet } from "../../../../../data/Types"
import { returnAddressString, returnMonthNames } from "../../../../../services/functions"
import { ObjectType } from "../../../../Objekte/types/types"

type BillDetailHeadType = {
    data: BillTemplateGet
}

export default function BillTemplateHeadBanner(props: BillDetailHeadType) {
    return (
        <div className="flex gap-4 h-fit">
            {/* Basic Information about Bill */}
            <div className="w-full h-fit rounded-default shadow-lg mt-6 bg-white p-4">
                <InfoRow link={"/kunden/" + props.data?.customer_id?._id} info={"Kunde"} content={props.data?.customer_id?.customer?.name} />
                {props.data?.object_id && (
                    <InfoRow
                        link={"/objekte/" + props.data?.object_id?._id}
                        info={"Objekt"}
                        content={returnAddressString(props.data?.object_id as ObjectType)}
                    />
                )}
                <InfoRow info={window.innerWidth > 640 ? "erstellt am" : "erstellt"} content={dayjs(props.data.date).format("DD.MM.YYYY")} />
                <InfoRow info={"Kategorie"} content={props.data?.billTemplateCategorie} />
                <InfoRow info={"Archiviert"} content={props.data?.archived ? "Ja" : "Nein"} />
                <InfoRow
                    info={"Mahnungssystem"}
                    content={`nach ${props.data.reminderStartOffsetInDays} Tagen Zahlungsverzug, im Abstand von ${props.data.reminderIntervalInDays} Tagen, maximal ${props.data.maxReminders} Mahnungen`}
                />
                <InfoRow info={"Rechnungstag"} content={props.data.billDay + ". eines Monats"} />
                <InfoRow info={"Zahlungsfrist"} content={props.data.billDue + " Tage"} />
                <InfoRow info={"Interval"} content={returnMonthNames(props.data.intervalMonths)} />
            </div>
        </div>
    )
}

type InfoRowType = {
    info: string
    content: string | any
    link?: string
}

function InfoRow({ info, content, link }: InfoRowType) {
    const navigate = useNavigate()

    return (
        <div onClick={link ? () => navigate(link) : undefined} className="flex flex-row gap-8 text-base items-center justify-start">
            <p className="font-medium w-24 sm:w-40">{info}:</p>
            <p className={link && "cursor-pointer hover:underline truncate"}>{content}</p>
        </div>
    )
}
