import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { BillTemplateGet } from "../../../data/Types"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as PLUSINCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as OFFER } from "../../../assets/OfferIcon.svg"
import { ReactComponent as PAYMENT } from "../../../assets/PaymentIcon.svg"
import { ReactComponent as BILL } from "../../../assets/BillIcon.svg"
import { ReactComponent as ARROWUP } from "../../../assets/ArrowUpIcon.svg"
import { ReactComponent as ARROWDOWN } from "../../../assets/ArrowDownIcon.svg"
import { ReactComponent as BILLTEMPLATE } from "../../../assets/BillTemplate.svg"
import { ReactComponent as DOWNLOAD } from "../../../assets/DownloadICON.svg"
import BaseButton from "../../../components/elements/BaseButton"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import ExportBillsModal from "../components/ExportBillsModal"
import BaseTable, { ColumnType } from "../../../components/elements/BaseTable"
import AddBillTemplateModal from "../components/addBillModal/AddBillTemplateModal"
import BillTemplateTableRow from "../components/billTemplate/BillTemplateTableRow"

export default function BillTemplatePage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterCategorie, setfilterCategorie] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")
    const [invertSort, setinvertSort] = useState<boolean | undefined>()
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>()
    const [filterCanceled, setfilterCanceled] = useState<boolean | undefined>()

    // Get Data Query
    const queryKey =
        "billTemplateData" + page + searchParams + filterDateSince + filterDateTo + filterArchived + filterCategorie + invertSort + filterCanceled
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({
                pageType: "bill-template",
                page: page,
                limit: 15,
                search: searchParams,
                startDate: filterDateSince,
                endDate: filterDateTo,
                archived: filterArchived,
                billTemplateCategorie: filterCategorie,
                invertSort: invertSort,
                canceled: filterCanceled,
            }),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)

    const [showFilter, setshowFilter] = useState(false)

    const handleRemoveFilter = () => {
        setfilterCategorie("")
        setfilterDateTo("")
        setfilterDateSince("")
        setfilterArchived(false)
        setfilterCanceled(undefined)
    }

    // FRONTEND LOGIC
    const displayedBillTemplates = useMemo(() => data?.docs, [data?.docs])
    const billTemplateTableColumns: ColumnType[] = [
        {
            label: "Erstellungsdatum",
            responsive: "hidden lg:table-cell",
            width: "min-w-[10rem] w-fit",
        },
        {
            label: "Kategorie",
            responsive: "hidden lg:table-cell",
            width: " min-w-[9rem] w-fit",
        },
        {
            label: "Intervall",
            responsive: "hidden lg:table-cell",
            width: " min-w-[9rem] w-fit",
        },
        {
            label: "Netto",
            width: "sm:min-w-[8rem] w-fit",
        },
        {
            label: "Brutto",
            width: "sm:min-w-[8rem] w-fit",
        },
        {
            label: "Kunde",
            responsive: "hidden md:table-cell ",
        },
        {
            label: "Objekt",
            responsive: "hidden md:table-cell ",
        },
        {
            label: "Status",
            responsive: "hidden md:table-cell ",
        },
    ]

    return (
        <BasePage>
            <HeadBanner
                title={"Rechnungsvorlagen"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
                        <div className="flex gap-4">
                            <DOWNLOAD className="w-8 h-8 cursor-pointer hidden md:block" onClick={() => modalCon?.trigger(3)} />
                            <PLUSINCIRCLE className="w-8 h-8 cursor-pointer hidden md:block" onClick={() => modalCon?.trigger(1)} />
                        </div>
                    </RenderWhenAuthorized>
                }
            />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <BaseButtonRow>
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => setsearchTerm(e.target.value)}
                        type="text"
                        value={searchTerm ?? null}
                        placeholder="Kunden / Objekt / Rechnungsnummer suchen..."
                        className="w-96 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <BaseButton label={"Angebote"} func={() => navigate("/buchhaltung/angebote")} icon={<OFFER className="w-6 h-6" />} />
                    <BaseButton label={"Rechnungen"} func={() => navigate("/buchhaltung/rechnungen")} icon={<BILL className="w-6 h-6" />} />
                    <BaseButton
                        label={"Rechnungsvorlagen"}
                        func={() => navigate("/buchhaltung/rechnungsvorlagen")}
                        icon={<BILLTEMPLATE className="w-6 h-6" />}
                        disabled
                    />
                    <BaseButton label={"Zahlungen"} func={() => navigate("/buchhaltung/zahlungen")} icon={<PAYMENT className="w-6 h-6" />} />
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setinvertSort(!invertSort)
                        }}>
                        {invertSort ? <ARROWUP className="w-4 h-4" /> : <ARROWDOWN className="w-4 h-4" />}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                </div>
            </BaseButtonRow>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <BaseGrowBody classProps=" overflow-x-hidden my-4">
                        <BaseTable
                            columns={billTemplateTableColumns}
                            tbody={displayedBillTemplates?.map((item: BillTemplateGet) => (
                                <BillTemplateTableRow
                                    key={item._id}
                                    id={item._id}
                                    customer={item?.customer_id?.customer}
                                    date={item.date}
                                    object={item?.object_id ?? "Keine Angabe"}
                                    netto={item.totalNetto}
                                    brutto={item.totalBrutto}
                                    categorie={item.billTemplateCategorie}
                                    intervalMonths={item.intervalMonths}
                                    archived={item.archived}
                                    canceled={item.canceled}
                                    state={item.canceled ? "Storniert" : item.archived ? "Archiviert" : "Aktiv"}
                                />
                            ))}
                        />
                        {(!displayedBillTemplates || displayedBillTemplates.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erstellten Rechnungsvorlagen</p>
                        )}
                    </BaseGrowBody>
                    <BaseFilterModal
                        visible={showFilter}
                        searchdateSince={filterDateSince}
                        searchdateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        changeDateSince={setfilterDateSince}
                        removeFilter={handleRemoveFilter}
                        searchbillCategorie={filterCategorie}
                        changebillCategorie={setfilterCategorie}
                        setVisible={() => setshowFilter(!showFilter)}
                        searchArchived={filterArchived}
                        changeArchived={setfilterArchived}
                        searchCanceled={filterCanceled}
                        changeCanceled={setfilterCanceled}
                        statusType={"bill"}
                    />
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={15}
                            defaultCurrent={1}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <AddBillTemplateModal queryKey={queryKey} modalId={1} />
            <ExportBillsModal modalId={3} close={() => modalCon?.trigger(3)} />
        </BasePage>
    )
}
