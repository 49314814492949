import { useNavigate, useParams } from "react-router-dom"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BasePage from "../../../components/layout/BasePage"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleTaskById } from "../api/apiCalls"
import { useContext, useEffect, useState } from "react"
import { getSingleTaskType } from "../types"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import dayjs from "dayjs"
import { Tag } from "antd"
import TaskDetailPlainInformation from "../components/TaskDetailPlainInformation"
import { useUpdateTaskMutation } from "../api/services"
import TaskDetailHistory from "../components/TaskDetailHistory"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import TaskDetailNotes from "../components/TaskDetailNotes"
import TaskDetailImgs from "../components/TaskDetailImgs"
import { useDownloadDocumentMutation } from "../../api/services"
import BaseButton from "../../../components/elements/BaseButton"
import { ReactComponent as MONEY } from "../../../assets/Cash.svg"
import { ReactComponent as BELL } from "../../../assets/Bell.svg"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BillFromOrderRequestOrTaskModal from "../../Buchhaltung/components/billFromOrderRequest/BillFromOrderRequestOrTaskModal"
import { ObjectOnlyAddressType } from "../../Objekte/types/types"
import { NAVIGATION_ROUTES } from "../../../App"

export default function TaskDetailPage() {
    const { id } = useParams()
    // TASK get single
    const queryKeySingleTask = "task" + id
    const {
        error: singleTaskError,
        isLoading: singleTaskIsLoading,
        data: singleTaskData,
    } = useQuery({
        queryKey: [queryKeySingleTask],
        queryFn: () => getSingleTaskById(id ?? ""),
        enabled: !!id,
    })
    const [displayedTask, setdisplayedTask] = useState<getSingleTaskType>()
    useEffect(() => setdisplayedTask(singleTaskData), [singleTaskData])

    // UPDATE TASK
    const queryClient = useQueryClient()
    const { mutate: updateTask } = useUpdateTaskMutation(queryClient, queryKeySingleTask)
    const handleUpdateTask = () => {
        updateTask({ id: id ?? "", data: displayedTask as getSingleTaskType })
    }

    // RESET task template data
    const handleResetTaskData = () => setdisplayedTask(singleTaskData)

    // download file from task
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: displayedTask?._id ?? "", s3Key: s3Key, filetype: "task", filename: filename })
    }

    // FE LOGIC
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()

    return (
        <>
            {singleTaskError && <ErrorPage />}
            {singleTaskIsLoading && <Loading />}
            {!singleTaskError && !singleTaskIsLoading && singleTaskData && (
                <BasePage>
                    <BaseHeadBanner title={"Aufgabe: " + displayedTask?.activity} />
                    <BaseButtonRow>
                        <div>
                            {displayedTask?.state === "execution-failed" && (
                                <Tag className="text-center" color={"red-inverse"}>
                                    Ausführung fehlgeschlagen
                                </Tag>
                            )}
                            <Tag className="w-20 text-center" color={displayedTask?.priority === "default" ? "blue" : "red-inverse"}>
                                {displayedTask?.priority === "default" ? "Standard" : "Hoch"}
                            </Tag>
                            <Tag className="text-center" color={"blue"}>
                                am {dayjs(displayedTask?.createAt).format("DD.MM.YYYY") + " "}
                                erstellt von {displayedTask?.createdBy?.first_name + " " + displayedTask?.createdBy?.last_name}, zuletzt bearbeitet
                                von {displayedTask?.lastModifiedBy?.first_name + " " + displayedTask?.lastModifiedBy?.last_name} am{" "}
                                {dayjs(displayedTask?.lastModifiedAt).format("DD.MM.YYYY")}
                            </Tag>
                            <Tag className="w-24 text-center" color={displayedTask?.taskFrequency === "once" ? "" : "grey"}>
                                {displayedTask?.taskFrequency === "once" ? "Einmalig" : "Wiederkehrend"}
                            </Tag>
                        </div>
                        {displayedTask?.orderRequestId && (
                            <BaseButton
                                label={"Auftrag anzeigen"}
                                func={() => navigate(NAVIGATION_ROUTES.ORDERREQUESTS + displayedTask.orderRequestId)}
                                icon={<BELL className="w-6 h-6" />}
                            />
                        )}
                        {displayedTask?.state === "completed" &&
                            displayedTask?.taskFrequency === "once" &&
                            displayedTask?.orderRequestId === undefined && (
                                <BaseButton label={"Rechnung erstellen"} func={() => modalCon?.trigger(0)} icon={<MONEY className="w-4 h-4" />} />
                            )}
                    </BaseButtonRow>
                    {/* TODO BASE_GROW_BODY */}
                    <BaseGrowBody classProps="flex gap-4">
                        <div className="flex flex-col gap-4 w-1/2">
                            <TaskDetailPlainInformation
                                updateTask={handleUpdateTask}
                                resetDataToOriginal={handleResetTaskData}
                                displayedTask={displayedTask}
                                editTaskData={setdisplayedTask}
                            />
                            <TaskDetailImgs
                                session_title={displayedTask?.activity ?? ""}
                                images={displayedTask?.documents ?? []}
                                handleDownload={handleDownload}
                            />
                        </div>
                        <div className="flex flex-col gap-4 w-1/2">
                            <TaskDetailHistory history={displayedTask?.history ?? []} />
                            <TaskDetailNotes notes={displayedTask?.notes ?? []} />
                        </div>
                    </BaseGrowBody>
                    <BillFromOrderRequestOrTaskModal
                        id={displayedTask?._id ?? id ?? ""}
                        type="task"
                        modalId={0}
                        queryKey={queryKeySingleTask}
                        billData={{
                            customer_id: displayedTask?.customer?._id ?? "",
                            customer_name: displayedTask?.customer?.customer?.name ?? "",
                            object_id: displayedTask?.object as unknown as ObjectOnlyAddressType,
                            position_description: displayedTask?.activity ?? "",
                        }}
                    />
                </BasePage>
            )}
        </>
    )
}
