import { Form } from "antd"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseModal from "../../../../components/layout/BaseModal"
import { returnAddressString } from "../../../../services/functions"
import { ReactComponent as PLUS } from "../../../../assets/PlusInCircleIcon.svg"
import { ObjectOnlyAddressType, ObjectType } from "../../../Objekte/types/types"
import BaseSelection from "../../../../components/elements/BaseSelection"
import { BillPost } from "../../../../data/Types"
import { useContext, useEffect, useState } from "react"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import PositionReview from "../addBillModal/modalComponents/PositionReview"
import Dropzone from "react-dropzone"
import { useAddBillMutation } from "../../api/services"
import { useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../../components/contexts/ModalContext"

type BillFromOrderRequestModalType = {
    id: string
    queryKey: string
    type: "task" | "orderrequest"
    modalId: number
    billData: {
        customer_id: string
        customer_name: string
        object_id: ObjectOnlyAddressType
        position_description: string
        postion_price?: number
    }
}

export default function BillFromOrderRequestOrTaskModal(props: BillFromOrderRequestModalType) {
    // FE LOGIC
    const modalCon = useContext(ModalContext)
    const [newBill, setnewBill] = useState<BillPost | undefined>()
    const [documents, setdocuments] = useState<any[]>()
    const [form] = Form.useForm()
    useEffect(() => {
        if (props.billData) {
            form.setFieldsValue({
                customer_id: props.billData.customer_id,
                object_id: props.billData.object_id?._id,
                billType: "Rechnung",
                billCategorie: "Hausmeister",
                billDue: 14,
                manualReminderEnabled: false,
                position_id0: 1,
                position_description0: props.billData.position_description,
                position_unit0: "Festpreis",
                position_tax0: 19,
                position_amount0: 1,
                position_singlePrice0: props.billData.postion_price ?? 0,
            })
            setnewBill({
                date: new Date(),
                customer_id: props.billData.customer_id,
                object_id: props.billData.object_id?._id,
                billType: "Rechnung",
                billCategorie: "Hausmeister",
                billDue: 14,
                manualReminderEnabled: false,
                positions: [
                    {
                        position_id: 1,
                        description: props.billData.position_description,
                        unit: "Festpreis",
                        tax: 19,
                        amount: 1,
                        singlePrice: props.billData.postion_price ?? 0,
                    },
                ],
            } as unknown as BillPost)
        }
    }, [props.billData])

    // ADD POSITION
    const addPosition = () => {
        if (newBill) {
            setnewBill((prev: BillPost | undefined) => ({
                ...(prev as BillPost),
                positions: [
                    ...(prev as BillPost).positions,
                    {
                        position_id: ((prev as BillPost).positions.length + 1).toString(),
                        description: "",
                        tax: 19,
                        unit: "",
                        amount: 1,
                        singlePrice: 0,
                    },
                ],
            }))
        }
    }

    // REMOVE POSITION
    const removePosition = (indexToRemove: number) => {
        form.resetFields([
            "amount" + indexToRemove,
            "unit" + indexToRemove,
            "tax" + indexToRemove,
            "description" + indexToRemove,
            "singlePrice" + indexToRemove,
        ])
        setnewBill((prev: BillPost | undefined) => ({
            ...(prev as BillPost),
            positions: (prev as BillPost).positions.filter((_, index) => index !== indexToRemove),
        }))
    }

    // CHANGE INPUT OF POSITION
    const handleInputChange = (index: number, key: string, newValue: string | number) => {
        setnewBill((prev: BillPost | undefined) => ({
            ...(prev as BillPost),
            positions: (prev as BillPost).positions.map((item, idx) => {
                if (idx === index) {
                    return { ...item, [key]: newValue }
                }
                return item
            }),
        }))
    }

    // SEND BILL
    const queryClient = useQueryClient()
    const { mutate: addBill } = useAddBillMutation(queryClient, props.queryKey)
    const handleNewBill = () => {
        const formData = new FormData()
        formData.append("bill", JSON.stringify(newBill))
        formData.append(props.type, JSON.stringify({ id: props.id }))
        documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addBill(formData)
        form.resetFields()
        modalCon?.trigger(1)
    }

    return (
        <Form form={form} onFinish={handleNewBill}>
            <BaseModal
                title={`Rechnung aus ${props.type === "orderrequest" ? "Auftrag" : "Aufgabe"} erstellen`}
                modalId={props.modalId}
                bottomRow={
                    <div className="flex w-full justify-end">
                        <Form.Item>
                            <button type="submit" className="btn btn-primary btn-outline">
                                Absenden
                            </button>
                        </Form.Item>
                    </div>
                }>
                <div className="flex gap-4 w-full">
                    <BaseInputField classProps="mt-4 mb-2" disabled value={"Rechnung"} label={"Rechnungstyp"} type={"text"} onChange={() => {}} />
                    <Form.Item className="mt-4 mb-2 w-64" name={"billCategorie"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                        <BaseSelection
                            label={"Kategorie"}
                            placeholder={""}
                            data={["Hausmeister", "Schlüsseldienst", "Bau", "Vermietung"]}
                            setId={(e: string) => {
                                form.setFieldsValue({
                                    billCategorie: e,
                                })
                                setnewBill((prev) => ({
                                    ...(prev as BillPost),
                                    billCategorie: e,
                                }))
                            }}
                            defaultValue="Hausmeister"
                        />
                    </Form.Item>
                </div>
                <BaseInputField disabled value={props.billData.customer_name} label={"Kunde"} type={"text"} onChange={() => {}} />
                <BaseInputField
                    disabled
                    value={returnAddressString(props.billData.object_id as ObjectType)}
                    label={"Objekt"}
                    type={"text"}
                    onChange={() => {}}
                    classProps="my-2"
                />
                <Form.Item className="mb-2" name={"billDue"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseSelection
                        label={"Rechnungsfrist in Tagen"}
                        placeholder={""}
                        data={["7", "14", "28"]}
                        setId={(e: string) => {
                            form.setFieldsValue({ billDue: Number(e) })
                            setnewBill((prev) => ({
                                ...(prev as BillPost),
                                billDue: Number(e),
                            }))
                        }}
                        defaultValue="14"
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"serviceDate"} rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputField
                        label={"Leistungsdatum"}
                        type={"date"}
                        onChange={(e) =>
                            setnewBill((prev) => ({
                                ...(prev as BillPost),
                                serviceDate: e,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2" name={"additionalText"} rules={[{ required: false, message: "Muss einen Wert enthalten" }]}>
                    <BaseInputArea
                        label={"Zusätzlicher Text auf der Rechnung (optional)"}
                        onChange={(e) =>
                            setnewBill((prev) => ({
                                ...(prev as BillPost),
                                additionalText: e,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item className="mb-2 mt-2" name="manualReminderEnabled" rules={[{ required: false, message: "Muss einen Wert enthalten!" }]}>
                    <BaseCheckbox
                        prevent={!newBill?.manualReminderEnabled}
                        setcheck={(e) =>
                            setnewBill((prev) => ({
                                ...(prev as BillPost),
                                manualReminderEnabled: !newBill?.manualReminderEnabled,
                            }))
                        }
                        classProps="justify-start"
                        label={"Mahnungsfristen manuell setzen"}
                    />
                </Form.Item>
                {newBill?.manualReminderEnabled && (
                    <div className="flex w-full gap-4">
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderStartOffsetInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Nach wieviel Tagen wird gemahnt?"}
                                type={"number"}
                                onChange={(e) =>
                                    setnewBill((prev) => ({
                                        ...(prev as BillPost),
                                        reminderStartOffsetInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"reminderIntervalInDays"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"In welchem Abstand wird gemahnt? (in Tagen)"}
                                type={"number"}
                                onChange={(e) =>
                                    setnewBill((prev) => ({
                                        ...(prev as BillPost),
                                        reminderIntervalInDays: Number(e),
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className="mt-4 mb-2 w-full"
                            name={"maxReminders"}
                            rules={[{ required: true, message: "Muss einen Wert enthalten" }]}>
                            <BaseInputField
                                label={"Wieviele Mahnungen werden verschickt?"}
                                type={"number"}
                                onChange={(e) =>
                                    setnewBill((prev) => ({
                                        ...(prev as BillPost),
                                        maxReminders: Number(e),
                                    }))
                                }
                                prevent
                            />
                        </Form.Item>
                    </div>
                )}
                <div className="w-full border-black pt-2 mb-3 border-b-2" />
                <p className="text-lg font-semibold pb-2">Position</p>
                {newBill?.positions.map((item, index) => (
                    <PositionReview
                        index={index}
                        position={newBill.positions[index]}
                        disablePrice={props.type === "orderrequest"}
                        handleInputChange={handleInputChange}
                        removePosition={removePosition}
                    />
                ))}
                <div
                    className="flex pl-4 text-sm items-center pt-2 gap-3 cursor-pointer"
                    onClick={() => {
                        addPosition()
                    }}>
                    <PLUS className="w-6 h-6" />
                    <p>Weitere Position hinzufügen</p>
                </div>
                <div className="w-full border-black pt-4 mb-3 border-b-2" />
                <p className="text-lg font-semibold pb-2">Dokumente</p>
                <Dropzone onDrop={(acceptedFiles) => setdocuments(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={`w-full h-40 cursor-pointer flex border rounded-default mb-4 ${
                                !documents ? "items-center justify-center" : "flex-wrap p-4 gap-4"
                            }`}
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!documents && <p className="uppercase font-semibold text-gray-400">Dokumente hier ablegen oder Klicken und auswählen</p>}
                            {documents &&
                                documents?.map((item: any, index: number) => (
                                    <div className="h-32 w-32 border rounded-default flex items-center justify-center">
                                        <p className="truncate">{item.name}</p>
                                    </div>
                                ))}
                        </div>
                    )}
                </Dropzone>
            </BaseModal>
        </Form>
    )
}
