import { useContext } from "react"
import { motion } from "framer-motion"
import { ModalContext } from "../contexts/ModalContext"
import { BaseModalType } from "../types"

export default function BaseModal({ title, children, bottomRow, modalId, size, big, onClose }: BaseModalType) {
    const modalCon = useContext(ModalContext)
    return (
        <div
            className={`absolute top-0 left-0 w-screen h-screen overflow-hidden z-50 ${
                modalCon?.modalId === modalId && modalCon?.open ? "" : "hidden"
            }`}>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: modalCon?.animation ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                    type: "ease",
                }}
                className="w-full bg-black/30 h-full z-50"
                onClick={() => {
                    if (onClose) {
                        onClose()
                    }
                    modalCon?.trigger(0)
                }}
            />
            <motion.div
                initial={{
                    scale: 0,
                }}
                animate={{
                    scale: modalCon?.animation ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                    type: "ease",
                }}
                className={`${
                    size && !big
                        ? "w-fit h-fit pt-12 overflow-hidden max-h-[90%]"
                        : !size && !big
                        ? "w-full max-w-screen-lg grow max-h-[48rem]"
                        : "w-[90%] h-[90%] min-h-fit min-w-fit"
                } z-50 rounded-default bg-white shadow-lg absolute top-0 bottom-0 left-0 right-0 m-auto py-8 pl-8 pr-7 flex flex-col`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 absolute top-4 right-4 cursor-pointer"
                    onClick={() => {
                        if (onClose) {
                            onClose()
                        }
                        modalCon?.trigger(0)
                    }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <h1 className="w-full border-b pl-2 pt-2 text-xl pb-2 font-semibold uppercase">{title}</h1>
                <div className="grow w-full overflow-x-hidden overflow-y-scroll">{children}</div>
                {bottomRow && <div className="w-full border-t pt-2">{bottomRow}</div>}
            </motion.div>
        </div>
    )
}
