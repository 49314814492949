import { useRef, useState } from "react"
import { ReactComponent as NO } from "../../assets/No.svg"
import { ReactComponent as CAM } from "../../assets/Camera.svg"
import { ReactComponent as CLOSE } from "../../assets/CloseICON.svg"

type BaseCameraComponentType = {
    upload: (e: string[]) => void
}

export default function BaseCameraComponent(props: BaseCameraComponentType) {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [images, setImages] = useState<string[]>([])
    const [camOn, setCamOn] = useState<boolean>(false)

    // Start the camera
    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: { ideal: "environment" }, width: { ideal: 480 }, height: { ideal: 640 } },
            })
            if (videoRef.current) {
                videoRef.current.srcObject = stream
                videoRef.current.play()
            }
        } catch (error) {
            console.error("Error accessing the camera: ", error)
        }
    }

    // Stop the camera
    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject as MediaStream
            stream.getTracks().forEach((track) => track.stop())
            videoRef.current.srcObject = null
        }
    }

    // Capture an image from the video
    const capture = () => {
        if (videoRef.current) {
            const canvas = document.createElement("canvas")
            canvas.width = videoRef.current.videoWidth
            canvas.height = videoRef.current.videoHeight

            const ctx = canvas.getContext("2d")
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
                const imageData = canvas.toDataURL("image/png")
                setImages((prev) => [...prev, imageData]) // Store the captured image as a Base64 string
            }
        }
    }

    // handle upload of images
    const handleUpload = () => {
        stopCamera()
        props.upload(images)
        setImages([])
    }

    // remove element of imgs array
    const removeImgFromArray = (e: string) => {
        setImages(images.filter((item) => item !== e))
    }

    return (
        <div className="flex flex-col items-center py-4 h-full relative">
            {/* Video stream */}
            {camOn ? (
                <video ref={videoRef} autoPlay playsInline className="grow max-w-xs bg-black rounded-default" />
            ) : (
                <div className="grow w-80 flex items-center justify-center bg-gray-300 rounded-default">
                    <NO className="w-20 h-20 text-base-100" />
                    <CAM className="w-12 h-12 text-base-100 absolute" />
                </div>
            )}
            <div className={`flex w-full  py-2 h-32 ${camOn ? "justify-between" : "justify-center"}`}>
                <button
                    className="btn btn-outline"
                    onClick={() => {
                        if (camOn) {
                            stopCamera()
                            setCamOn(false)
                        } else {
                            startCamera()
                            setCamOn(true)
                        }
                    }}>
                    {camOn ? <CLOSE className="w-6 h-6" /> : "Kamera starten"}
                </button>
                {camOn && (
                    <button className="btn btn-outline" onClick={capture}>
                        <CAM className="w-6 h-6 " />
                    </button>
                )}
            </div>
            <div className="flex flex-wrap overflow-x-hidden overflow-y-scroll gap-2 max-h-64 justify-center pb-2">
                {images &&
                    images.map((item) => (
                        <div className="relative flex w-fit ">
                            <img src={item} alt="Captured" className="h-32 w-fit" />
                            <CLOSE
                                onClick={() => removeImgFromArray(item)}
                                className="w-8 h-8 bg-primary rounded-bl-default p-1 text-base-100 absolute right-0 "
                            />
                        </div>
                    ))}
            </div>
            <button onClick={handleUpload} className="btn sm:btn-xs btn-primary absolute bottom-0">
                Bilder hochladen
            </button>
        </div>
    )
}
