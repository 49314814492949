type BaseStatisticCardType = {
    value: string
    text: string
    classPropsContainer?: string
}

export default function BaseStatisticCard(props: BaseStatisticCardType) {
    return (
        <div className={`w-full p-8 rounded-default shadow-lg bg-white flex flex-col ${props.classPropsContainer}`}>
            <p className="text-3xl leading-6 ">{props.value}</p>
            <p className="text-sm font-semibold">{props.text}</p>
        </div>
    )
}
