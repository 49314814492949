import { BillTableRowType } from "../../types"
import dayjs from "dayjs"
import { ReactComponent as WARNING } from "../../../../assets/WarningICON.svg"
import { returnAddressString } from "../../../../services/functions"
import BaseTableRow from "../../../../components/layout/BaseTableRow"

export default function BillTableRow(props: BillTableRowType) {

    return (
        <BaseTableRow
            url={"/buchhaltung/rechnung/" + props.id}
            classProps={`${props.archived && "opacity-40 bg-gray-100"} ${props.canceled && "text-black/30"}`}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">
                    {props.canceled ? <p className="uppercase font-semibold">Storniert</p> : dayjs(props.date).format("DD.MM.YYYY")}
                </p>
            </td>
            <td>
                <p title={props.title} className="line-clamp-1 text-center">
                    Rechnung {props.title}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-center">{props.type}</p>
            </td>
            <td>
                <p className="line-clamp-1  text-center">{props.netto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td>
                <p className="line-clamp-1  text-center">{props.brutto?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td className="hidden md:table-cell">
                <p title={props.customer.name} className="line-clamp-1 flex justify-center">
                    {props.customer.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {props.customer.name}
                </p>
            </td>
            <td className="hidden md:table-cell">
                <p title={returnAddressString(props.object)} className="line-clamp-1 flex justify-center">
                    {props.object.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {props.object?.adress ? returnAddressString(props.object) : "keine Angabe"}
                </p>
            </td>
        </BaseTableRow>
    )
}
