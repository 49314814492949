import { useContext } from "react"
import ObjectDetailPartLeftCustomer from "./ObjectDetailPartLeftCustomer"
import ObjectDetailPartLeftList from "./ObjectDetailPartLeftList"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseImageUpload from "../../components/ImageUpload"
import { ReactComponent as QRCODE } from "../../../../assets/QrCode.svg"
import { Customer } from "../../../Kunden/types/Types"

type ObjectDetailPartLeftType = {
    id?: string
    customerData: Customer[]
    customerId: string
    customerOfObject: string
    searchTermCustomer?: string
    setobjectData: (e: any) => void
    setsearchTermCustomer: (e: any) => void
    s3RefIsExisting: boolean
    lvdoesnotexist: boolean
    obvdoesexist: boolean
    objectsOffer: {
        nr: string | number | undefined
        _id: string
    }[]
    objectsBill: {
        nr: string | number | undefined
        billType: string
        _id: string
    }[]
    employeeData?: {
        first_name: string
        last_name: string
        _id: string
    }[]
}

export default function ObjectDetailPartLeft(props: ObjectDetailPartLeftType) {
    const modalCon = useContext(ModalContext)
    return (
        <div className="hidden md:flex flex-col w-full bg-white rounded-default shadow-lg overflow-y-scrollg grow p-4">
            <BaseImageUpload s3RefIsExisting={props.s3RefIsExisting} objectId={props.id ?? ""} />
            <ObjectDetailPartLeftCustomer
                searchTerm={props.searchTermCustomer}
                customer={props.customerData}
                customerOfObject={props.customerOfObject}
                setobjectData={props.setobjectData}
                setsearchTermCustomer={props.setsearchTermCustomer}
            />
            {/* OPEN OFFERS */}
            <ObjectDetailPartLeftList
                headline="Gestellte Angebote:"
                singular="Angebot"
                newtext="Neues Angebot"
                link="/buchhaltung/angebote/"
                linknew={`/buchhaltung/angebote?oid=${props.id}&cid=${props.customerId}`}
                objectListItems={props.objectsOffer}
            />
            {/* OPEN BILLS */}
            <ObjectDetailPartLeftList
                headline="Gestellte Rechnungen:"
                newtext="Neue Rechnung"
                link="/buchhaltung/rechnung/"
                linknew={`/buchhaltung/rechnungen?oid=${props.id}&cid=${props.customerId}`}
                objectListItems={props.objectsBill}
            />
            <div className="flex flex-row justify-end w-full gap-2 p-2">
                <button className="btn btn-outline self-start " onClick={() => modalCon?.trigger(8)} type="button">
                    <QRCODE className="w-8 h-8" />
                </button>
                <button className="btn btn-outline self-start " onClick={() => modalCon?.trigger(5)} type="button">
                    Leistungsverzeichnis
                </button>
                {props.obvdoesexist && (
                    <button
                        className="btn btn-outline self-start "
                        disabled={props.lvdoesnotexist}
                        onClick={() => modalCon?.trigger(7)}
                        type="button">
                        Objektbetreuungsvertrag
                    </button>
                )}
            </div>
        </div>
    )
}
