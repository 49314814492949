import BaseModal from "../../../components/layout/BaseModal"
import { ReactComponent as DOWNLOAD } from "../../../assets/DownloadICON.svg"
import { ReactComponent as PLUSCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as PLUS } from "../../../assets/PlusICON.svg"
import { ReactComponent as CLOSE } from "../../../assets/CloseICON.svg"
import { ReactComponent as CHECK } from "../../../assets/ApprovaICON.svg"
import dayjs from "dayjs"
import BaseInputField from "../../../components/elements/BaseInputField"
import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useAddDriversLicenceEntryMutation } from "../api/services"
import { useDownloadDocumentMutation } from "../../api/services"

type EmployeeDriversLicenceModalType = {
    modalId: number
    driverslicence?: {
        createdAt: Date
        note?: string
        s3Document: any
    }[]
    id: string
    queryKey: string
}

export default function EmployeeDriversLicenceModal(props: EmployeeDriversLicenceModalType) {
    const [addNewEntry, setaddNewEntry] = useState<boolean>(false)
    const [file, setFile] = useState<any>(null)
    const [note, setNote] = useState<string>()

    const { mutate: handleDownloadImage } = useDownloadDocumentMutation()
    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0]
        setFile(selectedFile)
    }

    const queryClient = useQueryClient()
    const { mutate: addDriverEntry } = useAddDriversLicenceEntryMutation(queryClient, props.queryKey)
    const handleAddNewDriversLicenceEntry = () => {
        setaddNewEntry(false)
        const formData = new FormData()
        formData.append("image", file)
        addDriverEntry({
            id: props.id,
            note: encodeURIComponent(JSON.stringify(note)),
            image: formData,
        })
        setFile(undefined)
        setNote(undefined)
    }

    return (
        <BaseModal title="Kontrolle Führerschein" modalId={props.modalId}>
            {!addNewEntry ? (
                <div className="flex items-center pl-4 py-4 cursor-pointer" onClick={() => setaddNewEntry(true)}>
                    <PLUSCIRCLE className="w-6 h-6" />
                    <p className="font-semibold pl-3 text-sm">Eintrag hinzufügen</p>
                </div>
            ) : (
                <div className="flex gap-4 justify-end items-end py-2 px-4">
                    <button className="btn btn-primary mb-1 relative">
                        <input
                            type="file"
                            accept="image/jpeg, image/png"
                            className="opacity-0 w-full absolute h-full cursor-pointer"
                            onChange={handleFileChange}
                        />
                        {file ? (
                            file.name
                        ) : (
                            <>
                                <PLUS className="h-6 w-6" />
                                Bild Hochladen
                            </>
                        )}
                    </button>
                    <BaseInputField label={"Hinweis"} type={"text"} onChange={setNote} />
                    <div className=" h-12 w-16 mb-0.5 flex gap-4 items-center ml-2">
                        <CLOSE
                            className="w-7 h-7 cursor-pointer"
                            onClick={() => {
                                setaddNewEntry(false)
                                setFile(undefined)
                                setNote(undefined)
                            }}
                        />
                        <CHECK
                            className={`w-7 h-7 ${!file ? "text-gray-400 cursor-not-allowed" : "cursor-pointer"}`}
                            aria-disabled={!file}
                            onClick={handleAddNewDriversLicenceEntry}
                        />
                    </div>
                </div>
            )}
            <table className="table-zebra table ">
                {!props.driverslicence || props.driverslicence === undefined || props.driverslicence?.length === 0 ? (
                    <p>Keine Einträge zum Führerschein hochgeladen</p>
                ) : (
                    props.driverslicence
                        ?.slice()
                        .reverse()
                        .map((item, index) => (
                            <DriversLicenceRow
                                createdAt={dayjs(item.createdAt).format("DD.MM.YYYY")}
                                note={item.note ? item.note : "keine Hinweise hinterlegt"}
                                downloadS3Document={() =>
                                    handleDownloadImage({
                                        id: props.id,
                                        s3Key: item.s3Document.s3Key,
                                        filetype: "employee",
                                        filename: item.s3Document.filename,
                                    })
                                }
                            />
                        ))
                )}
            </table>
        </BaseModal>
    )
}

type DriversLicenceRowType = {
    createdAt: string
    note?: string
    downloadS3Document: () => void
}

function DriversLicenceRow(props: DriversLicenceRowType) {
    return (
        <tr className="w-full border-t px-8 py-2 table-row justify-between items-center">
            <td className="table-cell ">{props.createdAt}</td>
            <td className="table-cell text-sm">{props.note}</td>
            <td className="table-cell">
                <DOWNLOAD className="w-6 h-6 cursor-pointer" onClick={props.downloadS3Document} />
            </td>
        </tr>
    )
}
