import BaseModal from "../../../components/layout/BaseModal"
import { CostCenterType } from "../types/Types"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
import { ReactComponent as EDIT } from "../../../assets/EditICON.svg"
import { ReactComponent as CLOSE } from "../../../assets/CloseICON.svg"
import { ReactComponent as CHECK } from "../../../assets/ApprovaICON.svg"
import BaseInputField from "../../../components/elements/BaseInputField"
import { useState } from "react"
import { useAddCostCenterMutation, useDeleteCostCenterMutation, useUpdateCostCenterMutation } from "../api/services"
import { useQueryClient } from "@tanstack/react-query"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import BasePopover from "../../../components/elements/BasePopover"

type CostCenterModalType = {
    modalId: number
    error: Error | null
    isLoading: boolean
    costcenter: CostCenterType[]
}

export default function CostCenterModal(props: CostCenterModalType) {
    const [edit, setedit] = useState<string | undefined>()
    const handleEdit = (id?: string) => {
        if (id) {
            setedit(id)
        } else {
            setedit(undefined)
            setupdatename(undefined)
            setupdatestreet(undefined)
            setupdatenr(undefined)
            setupdateplz(undefined)
            setupdatecity(undefined)
            setname(undefined)
            setstreet(undefined)
            setnr(undefined)
            setplz(undefined)
            setcity(undefined)
            console.log(name)
        }
    }

    const [name, setname] = useState<string | undefined>()
    const [street, setstreet] = useState<string | undefined>()
    const [nr, setnr] = useState<string | undefined>()
    const [plz, setplz] = useState<string | undefined>()
    const [city, setcity] = useState<string | undefined>()
    const [updatename, setupdatename] = useState<string | undefined>()
    const [updatestreet, setupdatestreet] = useState<string | undefined>()
    const [updatenr, setupdatenr] = useState<string | undefined>()
    const [updateplz, setupdateplz] = useState<string | undefined>()
    const [updatecity, setupdatecity] = useState<string | undefined>()
    const queryClient = useQueryClient()
    const { mutate: deleteCostCenter } = useDeleteCostCenterMutation(queryClient)
    const { mutate: updateCostCenter } = useUpdateCostCenterMutation(queryClient)
    const { mutate: addCostCenter } = useAddCostCenterMutation(queryClient)
    const handleAddCostcenter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (name && street && nr && plz && city) {
            addCostCenter({
                name: name,
                address: {
                    street: street,
                    nr: nr,
                    plz: plz,
                    city: city,
                },
                status: "active",
            })
        }
        handleEdit()
    }
    const handleChangeStatus = (id: string, status: string) => {
        updateCostCenter({
            id: id,
            data: {
                status: status,
            },
        })
        setedit(undefined)
    }
    const handleUpdate = (id: string) => {
        updateCostCenter({
            id: id,
            data: {
                name: updatename,
                street: updatestreet,
                nr: updatenr,
                plz: updateplz,
                city: updatecity,
            },
        })
        handleEdit()
    }

    console.log(props.costcenter)

    return (
        <BaseModal modalId={props.modalId} title={"Niederlassungen verwalten"}>
            {props.error && <ErrorPage />}
            {props.isLoading && <Loading />}
            {props.costcenter && (
                <div className="py-8">
                    <table className="table table-md py-8 max-h-72">
                        <thead></thead>
                        <tbody className="overflow-y-scroll">
                            {props.costcenter?.map((item: CostCenterType) => (
                                <SingleCostCenterRow
                                    id={item._id ?? ""}
                                    name={item.name}
                                    editable={edit === item._id}
                                    street={item.address?.street}
                                    nr={item.address.nr}
                                    plz={item.address.plz}
                                    city={item.address.city}
                                    copy_name={updatename}
                                    copy_street={updatestreet}
                                    copy_nr={updatenr}
                                    copy_plz={updateplz}
                                    copy_city={updatecity}
                                    status={item.status}
                                    setname={setupdatename}
                                    setstreet={setupdatestreet}
                                    setnr={setupdatenr}
                                    setplz={setupdateplz}
                                    setcity={setupdatecity}
                                    editStatus={handleChangeStatus}
                                    delete={() => deleteCostCenter(item._id ?? "")}
                                    edit={handleEdit}
                                    update={() => handleUpdate(item._id ?? "")}
                                />
                            ))}
                        </tbody>
                    </table>
                    <form onSubmit={handleAddCostcenter}>
                        <p className="text-lg font-bold pt-8 pb-2">Niederlassung hinzufügen</p>
                        <div className="flex w-full items-end gap-4">
                            <BaseInputField width="w-1/3" label={"Name"} type={"text"} value={name} placeholder="Niederlassung" onChange={setname} />
                            <BaseInputField width="w-2/3" label={"Straße"} type={"text"} value={street} placeholder="Straße" onChange={setstreet} />
                        </div>
                        <div className="flex gap-4 pt-2">
                            <BaseInputField label={"Nummer"} width="w-1/4" type={"text"} placeholder="1" value={nr} onChange={setnr} />
                            <BaseInputField width="w-1/4" label={"Postleitzahl"} type={"text"} placeholder="01234" value={plz} onChange={setplz} />
                            <BaseInputField width="w-1/2" label={"Stadt"} type={"text"} value={city} placeholder="Stadt" onChange={setcity} />
                        </div>
                        <div className="flex w-full justify-end pt-2">
                            <button className="btn btn-primary" type="submit">
                                Speichern
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </BaseModal>
    )
}

type SingleCostCenterRowType = {
    id: string
    name: string
    editable: boolean
    street: string
    nr: string
    plz: string
    city: string
    copy_name?: string
    copy_street?: string
    copy_nr?: string
    copy_plz?: string
    copy_city?: string
    setname: (e: string) => void
    setstreet: (e: string) => void
    setnr: (e: string) => void
    setplz: (e: string) => void
    setcity: (e: string) => void
    status: string
    update: () => void
    edit: (e?: string) => void
    delete: () => void
    editStatus: (id: string, e: string) => void
}
function SingleCostCenterRow(props: SingleCostCenterRowType) {
    return (
        <tr className="table-row border-b border-black/10 mt-0.5">
            <td className="table-cell w-48 p-0">
                <input
                    disabled={!props.editable}
                    className={`bg-none w-48 truncate p-2 ${props.editable && "border-b-2 border-green-400"}`}
                    value={props.copy_name && props.editable ? props.copy_name : props.name}
                    onChange={(e) => props.setname(e.target.value)}
                />
            </td>
            <td className="table-cell p-0 pl-2">
                <input
                    disabled={!props.editable}
                    className={`bg-none w-full truncate p-2 ${props.editable && "border-b-2 border-green-400"}`}
                    value={props.copy_street && props.editable ? props.copy_street : props.street}
                    onChange={(e) => props.setstreet(e.target.value)}
                />
            </td>
            <td className="table-cell p-0 pl-2">
                <input
                    disabled={!props.editable}
                    className={`bg-none w-12 text-center truncate p-2 ${props.editable && "border-b-2 border-green-400"}`}
                    value={props.copy_nr && props.editable ? props.copy_nr : props.nr}
                    onChange={(e) => props.setnr(e.target.value)}
                />
            </td>
            <td className="table-cell p-0 pl-2">
                <input
                    disabled={!props.editable}
                    className={`bg-none w-16 text-center truncate p-2 ${props.editable && "border-b-2 border-green-400"}`}
                    value={props.copy_plz && props.editable ? props.copy_plz : props.plz}
                    onChange={(e) => props.setplz(e.target.value)}
                />
            </td>
            <td className="table-cell p-0 pl-2">
                <input
                    disabled={!props.editable}
                    className={`bg-none w-full truncate p-2 ${props.editable && "border-b-2 border-green-400"}`}
                    value={props.copy_city && props.editable ? props.copy_city : props.city}
                    onChange={(e) => props.setcity(e.target.value)}
                />
            </td>
            <td className="table-cell w-32 p-0 pl-2">
                <button
                    disabled={!props.editable}
                    className={`p-2 w-16 text-center ${props.editable && "btn btn-primary btn-sm"}`}
                    onClick={() => props.editStatus(props.id, props.status === "active" ? "inactive" : "active")}>
                    {props.status === "active" ? "Aktiv" : "Inaktiv"}
                </button>
            </td>
            <td className="table-cell">
                {props.editable ? (
                    <CLOSE onClick={() => props.edit()} className="w-6 h-6 cursor-pointer text-red-600" />
                ) : (
                    <EDIT onClick={() => props.edit(props.id)} className="w-6 h-6 cursor-pointer" />
                )}
            </td>
            <td className="table-cell">
                {props.editable ? (
                    <CHECK onClick={props.update} className="w-6 h-6 cursor-pointer text-success" />
                ) : (
                    <BasePopover
                        label={"Wirklich löschen?"}
                        text="Die Kostenstelle wird vollständig gelöscht."
                        okButtonClick={props.delete}
                        children={<DELETE className="w-6 h-6 cursor-pointer" />}
                    />
                )}
            </td>
        </tr>
    )
}
