import { ReactNode, useContext } from "react"
import { SidePanelContext } from "../contexts/SidePanelContext"
import { motion } from "framer-motion"

type BaseSidePanelType = {
    children: ReactNode
    title: string
    sidePanelId: number
    bottomRow?: ReactNode
    onClose?: () => void
}

export default function BaseSidePanel(props: BaseSidePanelType) {
    const sidePanelCon = useContext(SidePanelContext)
    return (
        <div
            className={`absolute top-0 left-0 w-screen h-screen overflow-hidden  ${
                sidePanelCon?.sidePanelId === props.sidePanelId && sidePanelCon.open ? "" : "hidden"
            }`}>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: sidePanelCon?.animation ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                    type: "ease",
                }}
                className="hidden lg:block w-full bg-black/30 h-full z-30"
                onClick={() => {
                    sidePanelCon?.trigger(0)
                    if (props.onClose) {
                        props.onClose()
                    }
                }}
            />
            <motion.div
                initial={{
                    x: "150%",
                }}
                animate={{
                    x: sidePanelCon?.animation ? "0%" : "150%",
                }}
                transition={{
                    duration: 0.2,
                    type: "ease",
                }}
                className={`min-w-[30%] max-w-[50%] w-fit z-40 rounded-none bg-white shadow-l-lg h-screen absolute top-0 right-0 p-8 flex flex-col`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 absolute top-4 right-4 cursor-pointer"
                    onClick={() => {
                        sidePanelCon?.trigger(0)
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <h1 className="w-full border-b pl-2 pt-2 text-xl pb-2 font-semibold uppercase">{props.title}</h1>
                <div className="grow w-full h-max overflow-hidden overflow-y-scroll">{props.children}</div>
                {props.bottomRow && <div className="w-full border-t pt-2 flex justify-end">{props.bottomRow}</div>}
            </motion.div>
        </div>
    )
}
