import { Pagination } from "antd"
import BaseButtonRow from "../../../components/elements/BaseButtonRow"
import BaseGrowBody from "../../../components/elements/BaseGrowBody"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BaseInputSearch from "../../../components/elements/BaseInputSearch"
import BasePage from "../../../components/layout/BasePage"
import OrderRequestTable from "../components/OrderRequestTable"
import { useMemo, useState } from "react"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import { useQueryClient } from "@tanstack/react-query"
import { useUpdateOrderRequest } from "../api/services"
import { OrderRequestType } from "../types"
import Loading from "../../../components/common/Loading"
import { useQueryOrderRequest } from "../api/query"

export default function OrderRequestPage() {
    // ORDERREQUESTS get all
    const [page, setpage] = useState<number>(1)
    const handlePageChange = (page: number) => setpage(page)
    const [searchTermOrderRequests, setsearchTermOrderRequests] = useState("")
    const searchParamsOrderRequests = useDelayWhenSearchWithPagination(searchTermOrderRequests)
    const queryKeyOrderRequests = "orderrequests" + searchParamsOrderRequests
    const { data, error, isLoading } = useQueryOrderRequest(page, searchParamsOrderRequests)
    const displayedOrderRequests = useMemo(() => data?.docs, [data?.docs])

    // UPDATE FUNCTIONS
    const queryclient = useQueryClient()
    const { mutate: updateOrderRequestState } = useUpdateOrderRequest(queryclient, queryKeyOrderRequests)
    const handleUpdateOrderRequestState = (state: string, id: string) => {
        updateOrderRequestState({ id: id, body: { state: state } as OrderRequestType })
    }

    return (
        <BasePage>
            <BaseHeadBanner title="Anfragen" />
            <BaseButtonRow>
                <BaseInputSearch placeholder={"Anfrage suchen"} searchTerm={searchTermOrderRequests} setsearchTerm={setsearchTermOrderRequests} />
            </BaseButtonRow>
            {isLoading && <Loading />}
            {!isLoading && !error && (
                <>
                    <BaseGrowBody classProps=" overflow-x-hidden mt-2 mb-4">
                        <OrderRequestTable orderrequest={displayedOrderRequests} handleUpdateOrderRequestState={handleUpdateOrderRequestState} />
                    </BaseGrowBody>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={20}
                            defaultCurrent={1}
                            current={page}
                            onChange={handlePageChange}
                            total={data?.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
        </BasePage>
    )
}
