import { useContext, useEffect } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import Header from "./components/common/Drawer"
import Loading from "./components/common/Loading"
import BaseLayout from "./components/layout/BaseLayout"
import Dashboard from "./pages/Dashboard/pages/Dashboard"
import ErrorPage from "./pages/404/ErrorPage"
import PaymentPage from "./pages/Buchhaltung/pages/PaymentPage"
import Mitarbeiter from "./pages/Mitarbeiter/pages/Mitarbeiter"
import Objekte from "./pages/Objekte/pages/Objekte"
import "./style/scroll.css"
import BillDetailPage from "./pages/Buchhaltung/pages/billDetail/BillDetailPage"
import Kunden from "./pages/Kunden/pages/Kunden"
import OfferPage from "./pages/Buchhaltung/pages/OfferPage"
import OfferDetailPage from "./pages/Buchhaltung/pages/offerDetail/OfferDetailPage"
import CustomerDetailPage from "./pages/Kunden/pages/CustomerDetailPage"
import ObjektDetailPage from "./pages/Objekte/pages/objectDetail/ObjektDetailPage"
import Hilfe from "./pages/Hilfe/pages/Hilfe"
import BillPage from "./pages/Buchhaltung/pages/BillPage"
import { Roles } from "./auth/RoleMapping"
import { RenderWhenAuthorized } from "./components/authentication/RenderWhenAuthorized"
import DownloadPage from "./components/common/DownloadPage"
import TaskOverviewPage from "./pages/Aufgaben/pages/TaskOverviewPage"
import CalendarPage from "./pages/Aufgaben/pages/CalendarPage"
import TaskDetailPage from "./pages/Aufgaben/pages/TaskDetailPage"
import TaskTemplateOverviewPage from "./pages/Aufgaben/pages/TaskTemplateOverviewPage"
import TaskTemplateDetailPage from "./pages/Aufgaben/pages/TaskTemplateDetailPage"
import SessionDetailPage from "./pages/Aufgaben/pages/SessionDetailPage"
import { SelfUserContext } from "./components/contexts/SelfUserContext"
import MitarbeiterDetailPage from "./pages/Mitarbeiter/pages/MitarbeiterDetailPage"
import OrderRequestPage from "./pages/Anfragen/pages/OrderRequestPage"
import OrderRequestDetailPage from "./pages/Anfragen/pages/OrderRequestDetailPage"
import BillTemplatePage from "./pages/Buchhaltung/pages/BillTemplatePage"
import BillTemplateDetailPage from "./pages/Buchhaltung/pages/billTemplateDetail/BillTemplateDetailPage"

export const NAVIGATION_ROUTES = {
    TASK: "/aufgaben/",
    SESSION: "/termin/",
    CALENDAR: "/kalender/",
    ORDERREQUESTS: "/anfragen/",
}

function App() {
    // get current user
    const userSelf = useContext(SelfUserContext)

    // navigate to current user session, if user has an ongoing session
    const navigate = useNavigate()
    useEffect(() => {
        if (userSelf?.user?.activeSession) navigate(NAVIGATION_ROUTES.SESSION + userSelf?.user?.activeSession)
    }, [userSelf?.user?.activeSession])

    // useEffect(() => {
    //     checkIfLocationOrCamIsEnabledOnDevice()
    // }, [])
    return (
        <main className="relative overflow-hidden">
            {/* NOTE cannot work with those checks, bc Wicode Admin User service@ does not have an acc */}
            {userSelf?.isLoading && <Loading />}
            {/* {userSelf?.error && <ErrorPage />} */}
            {!userSelf?.isLoading && (
                <div className={"flex flex-row w-screen min-h-screen bg-base-100 overflow-hidden"}>
                    {/* {userSelf?.user?.activeSession && (
                        <Tag color="green-inverse" className="absolute right-8 top-8 z-10">
                            Aktiver Termin
                        </Tag>
                    )} */}
                    <Header />
                    <div className="flex flex-col min-h-full grow w-full overflow-hidden">
                        <Routes>
                            {/* CalendarDetailView of Employee */}
                            <Route
                                path="/kalender/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <BaseLayout roles={[]} title="Terminkalender" children={<CalendarPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <Navigate to={NAVIGATION_ROUTES.CALENDAR + userSelf?.user?._id} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            <Route path="/loading" element={<BaseLayout title="Logout..." roles={[]} children={<Loading />} />} />
                            {/* Dashboard Routes */}
                            <Route
                                path="/dashboard"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.dashboard_read]}>
                                        <BaseLayout title="Dashboard" roles={[Roles.dashboard_read]} children={<Dashboard />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Verteiler from Payment Requirements Routes */}
                            <Route
                                path="/buchhaltung"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_zahlungen_read]}>
                                        <Navigate to="/buchhaltung/zahlungen" />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Payment Requirements Routes */}
                            <Route
                                path="/buchhaltung/zahlungen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_zahlungen_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Zahlungen" children={<PaymentPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Route */}
                            <Route
                                path="/buchhaltung/rechnungen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungen" children={<BillPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Template Route */}
                            <Route
                                path="/buchhaltung/rechnungsvorlagen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungsvorlagen" children={<BillTemplatePage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Offer Route */}
                            <Route
                                path="/buchhaltung/angebote"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Angebote" children={<OfferPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Detailed Route */}
                            <Route
                                path="/buchhaltung/rechnung/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungen" children={<BillDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Template Detailed Route */}
                            <Route
                                path="/buchhaltung/rechnungsvorlagen/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungsvorlagen" children={<BillTemplateDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Offer Detailed Routes */}
                            <Route
                                path="/buchhaltung/angebote/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Angebot" children={<OfferDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Session Detail View */}
                            <Route
                                path="/termin/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <BaseLayout roles={[]} title="Termin" children={<SessionDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Aufgaben Routes */}
                            <Route
                                path="/aufgaben"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.aufgaben_read, Roles.aufgaben_create, Roles.aufgaben_delete]}>
                                        <BaseLayout roles={[]} title="Aufträge" children={<TaskOverviewPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Single Task View */}
                            <Route
                                path="/aufgaben/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.aufgaben_read, Roles.aufgaben_read_write, Roles.aufgaben_create]}>
                                        <BaseLayout roles={[]} title="Aufgabe" children={<TaskDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* TaskTemplates  */}
                            <Route
                                path="/aufgaben-wiederkehrend"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.aufgaben_read, Roles.aufgaben_create, Roles.aufgaben_delete]}>
                                        <BaseLayout roles={[]} title="Vorlagen" children={<TaskTemplateOverviewPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* TaskTemplates Detail  */}
                            <Route
                                path="/aufgaben-wiederkehrend/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.aufgaben_read, Roles.aufgaben_create, Roles.aufgaben_delete]}>
                                        <BaseLayout roles={[]} title="Vorlage" children={<TaskTemplateDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />

                            {/* Mitarbeiter Routes */}
                            <Route
                                path="/mitarbeiter"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.mitarbeiter_read, Roles.mitarbeiter_read_ou]}>
                                        <BaseLayout roles={[Roles.mitarbeiter_read]} title="Mitarbeiter" children={<Mitarbeiter />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* EmploeeId Route */}
                            <Route
                                path="/mitarbeiter/:id"
                                element={
                                    <RenderWhenAuthorized
                                        page
                                        requiresAll={[Roles.mitarbeiter_read, Roles.mitarbeiter_read_ou, Roles.mitarbeiter_read_write]}>
                                        <BaseLayout roles={[Roles.mitarbeiter_read]} title="Mitarbeiter" children={<MitarbeiterDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Object Route */}
                            <Route
                                path="/objekte"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.objekte_read]}>
                                        <BaseLayout roles={[Roles.objekte_read]} title="Objekte" children={<Objekte />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Object Detailed Route */}
                            <Route
                                path="/objekte/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.objekte_read]}>
                                        <BaseLayout roles={[Roles.objekte_read]} title="Objekte" children={<ObjektDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Customer Route */}
                            <Route
                                path="/kunden"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.kunden_read]}>
                                        <BaseLayout roles={[Roles.kunden_read]} title="Kunden" children={<Kunden />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Customer Detailed Route */}
                            <Route
                                path="/kunden/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.kunden_read]}>
                                        <BaseLayout roles={[Roles.kunden_read]} title="Kunden" children={<CustomerDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Order Request Route */}
                            {/* TODO Protection */}
                            <Route
                                path="/anfragen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <BaseLayout roles={[]} title="Anfragen" children={<OrderRequestPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Order Request Detail Route */}
                            {/* TODO Protection */}
                            <Route
                                path="/anfragen/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <BaseLayout roles={[]} title="Anfrage" children={<OrderRequestDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Help Section */}
                            <Route
                                path="/hilfe"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.help_read_write]}>
                                        <BaseLayout roles={[Roles.help_read_write]} title="Hilfe" children={<Hilfe />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Download Route for ZIP */}
                            <Route
                                path="/export/download-zip"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.objekte_read_write]}>
                                        <BaseLayout roles={[Roles.objekte_read_write]} title="Download..." children={<DownloadPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Errorpage */}
                            <Route path="/404" element={<BaseLayout roles={[]} title="Page Not Found" children={<ErrorPage />} />} />
                            <Route path="*" element={<Navigate to="/404" />} />
                        </Routes>
                    </div>
                </div>
            )}
        </main>
    )
}

export default App
