import { ReactNode, useMemo } from "react"
import { translateStateOrActionCode } from "../../../services/functions"
import { OrderRequestActionCode, OrderRequestHistoryData } from "../types"
import dayjs from "dayjs"
import { ReactComponent as DONE } from "../../../assets/CheckCircle.svg"
import { ReactComponent as CLOSE } from "../../../assets/CloseCircle.svg"
import { Timeline } from "antd"

type OrderRequestHistoryType = {
    history?: OrderRequestHistoryData[]
}

export default function OrderRequestHistory(props: OrderRequestHistoryType) {
    const timeSteps = useMemo<{ children: ReactNode }[]>(
        () =>
            props.history?.reverse()?.map((item) => ({
                color: item.actionCode === "billed" ? "green" : item.actionCode === "declined" ? "red" : "blue",
                dot:
                    item.actionCode === "billed" ? (
                        <DONE className="w-6 h-6" />
                    ) : item.actionCode === "declined" ? (
                        <CLOSE className="w-6 h-6" />
                    ) : undefined,
                children: (
                    <div className="flex flex-col gap-1">
                        <h5 className={`text-sm ${item.message && "leading-3"}`}>
                            <span className={`font-semibold `}>{translateStateOrActionCode(item.actionCode, OrderRequestActionCode)}</span>
                            {item.person && (
                                <>
                                    {" "}
                                    von{" "}
                                    <span className="font-semibold">
                                        {item.person?.first_name} {item.person?.last_name}
                                    </span>
                                </>
                            )}
                            {item.portalAccess && (
                                <>
                                    {" "}
                                    durch Nutzeraccount <span className="font-semibold">{item.portalAccess.mail}</span>
                                </>
                            )}
                        </h5>
                        {item.message && <p className="text-xs text-black/80 pb-1">{item.message}</p>}

                        <p className="text-xs text-gray-400">
                            am {dayjs(item.timestamp).format("DD.MM.YYYY")} um {dayjs(item.timestamp).format("HH:mm")} Uhr
                        </p>
                    </div>
                ),
            })) || [],
        [props.history]
    )

    console.log(timeSteps)

    return (
        <div className="bg-white rounded-default p-4 shadow-lg grow min-h-[50%]">
            <h3 className="text-xl mb-4 font-bold border-b">History</h3>
            <Timeline items={timeSteps} />
        </div>
    )
}
